import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { environment } from 'src/environments/environment';
import { DeviceHelper } from '../core/helpers/device.helper';
import { Storage } from '../core/storage/storage';
import { LocationService } from './location.service';
import { IS_ADAPTABLE } from '../config/constants';
import { Capacitor } from '@capacitor/core';
import {SegmentAnalytics} from '../core/providers/segment-analytics';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Md5 } from 'md5-typescript';
import { Apollo } from 'apollo-angular';
import {GET_DEVICES_BY_USERID, GET_ALL_DEVICE_ACTIVE, GET_DEVICE_BY_ID, GET_NUMBER_MASTER_ACCOUNT, MUTATION_CREATE_MASTER_ACCOUNT, MUTATION_DELETE_MASTER_ACCOUNT, MUTATION_DELETE_DEVICE, MUTATION_SET_CONECTION,
  MUTATION_SET_CONECTION_DEACTIVE} from '../queries/query';
import {TextManagementService} from './text-management.service';
import { EVENT_TRACK_144 } from '../config/analytics/events-track';

@Injectable({
  providedIn: 'root'
})
export class DevicesService {
  deviceId: string;
  bodyDeviceActive: any = [];
  tvModeUser: boolean = false;

  constructor(
    private httpClient: HttpClient,
    private storage: Storage,
    private deviceHelper: DeviceHelper,
    private locationService: LocationService,
    private segmentAnalytics: SegmentAnalytics,
    private deviceDetectorService: DeviceDetectorService,
    private textManagementService:TextManagementService,
    private apollo: Apollo
  ) { }

  isAdaptable = IS_ADAPTABLE;
  actualPlatform = Capacitor.getPlatform();
  isMobilePlatform = this.actualPlatform === "ios" || this.actualPlatform === "android";
  /* Get all registered devices since APPSYNC */
  public async getDevicesByUserId(){
    let response = []
    let deviceData = []
    try {
      let deviceUuid = await this.deviceHelper.getUUID().then();
      let dataResponse: any = await this.apollo
      .query({
        query: GET_DEVICES_BY_USERID,
      }).toPromise();
      if (dataResponse.data && dataResponse.data.readAllDevice && dataResponse.data.readAllDevice.items) {
        dataResponse.data.readAllDevice.items.forEach((item) => {
          let device = {
            name: `${item.systemInfo.operatingSystem} - ${item.systemInfo.browser}`,
            type: this.defineDeviceType(item.systemInfo.operatingSystem),
            active: item.enableView,
            deviceId: item.deviceId,
            master: item.master,
            actualDevice: item.deviceId === deviceUuid,
            lastConnection: item.recentActivity,
            location: `${item.address.state}`,
          };
          deviceData.push(device);
        });
        this.storage.setDevicesLimit(dataResponse.data.readAllDevice.limitViewDevice.toString());
      }
    }
    catch (error) { }
    response = deviceData;
    return response;
  }

  /* Get Number Account master and Device since APPSYNC */
  public async getNumberAccountMaster(force: boolean=false){
    const responseData = await this.storage.getAccountMasterAppSync();
    let text: any = await this.textManagementService.getTexts();
    text = text.DEVICEDETAILPAGE ? (text.DEVICEDETAILPAGE.dateOfexpiry ?? 5) : 5;
    text*=60;

    if (!force && responseData && responseData.dateOfexpiry && Math.floor((Date.now() - responseData.dateOfexpiry) / 1000) < text) {
      return responseData;
    } else {
      let response = {};
      try {
        let dataResponse: any = await this.apollo
          .query({
            query: GET_NUMBER_MASTER_ACCOUNT,
          }).toPromise();
        if (dataResponse.data && dataResponse.data.readDeviceManager) {
          response = {
            limitAccountManager: dataResponse.data.readDeviceManager.limitAccountManager,
            limitViewDevice: dataResponse.data.readDeviceManager.limitViewDevice,
            activeAccountManager: dataResponse.data.readDeviceManager.activeAccountManager,
            deviceId: dataResponse.data.readDeviceManager.item.deviceId,
            dateOfexpiry: Date.now(),
            master: dataResponse.data.readDeviceManager.item.master
          };
          this.storage.setAccountMasterAppSync(response);
        }
      }
      catch (error) {
        return null;
      }
      return response;
    }
  }

  /* Mutation Create Master Account APPSYNC*/
  public async createMasterAccountDevice(deviceId: String){
    try {
      let response: any = await this.apollo
      .mutate({
        mutation: MUTATION_CREATE_MASTER_ACCOUNT,
        variables: {
          deviceId: deviceId
        }
      }).toPromise();

      response = response.data.createMasterAccount.item;
      response = {
        name: `${response.systemInfo.operatingSystem} - ${response.systemInfo.browser}`,
        type: this.defineDeviceType(response.systemInfo.operatingSystem),
        active: response.enableView,
        deviceId: response.deviceId,
        lastConnection: response.recentActivity,
        firstConnection: response.firstSession,
        location: `${response.address.state}`,
        systemInfo: response.systemInfo,
        master: response.master
      }
      return { error: "", data: response};
    } catch (e) {
      return { error: e, data: {} };
    }
  }

  /* Mutation Delete Master Account APPSYNC*/
  public async deleteMasterAccountDevice(deviceId: String){
    try {
      let response: any = await this.apollo
      .mutate({
        mutation: MUTATION_DELETE_MASTER_ACCOUNT,
        variables: {
          deviceId: deviceId
        }
      }).toPromise();

      response = response.data.deleteMasterAccount.item;
      response = {
        name: `${response.systemInfo.operatingSystem} - ${response.systemInfo.browser}`,
        type: this.defineDeviceType(response.systemInfo.operatingSystem),
        active: response.enableView,
        deviceId: response.deviceId,
        lastConnection: response.recentActivity,
        firstConnection: response.firstSession,
        location: `${response.address.state}`,
        systemInfo: response.systemInfo,
        master: response.master
      }
      return { error: "", data: response};
    } catch (e) {
      return { error: e, data: {} };
    }
  }

  /* Mutation Delete Master Account APPSYNC*/
  public async deleteDevice(deviceId: String){
    try {
      let response: any = await this.apollo
      .mutate({
        mutation: MUTATION_DELETE_DEVICE,
        variables: {
          deviceId: deviceId
        }
      }).toPromise();

      response = response.data.deleteDevice;
      response = {
        name: `${response.systemInfo.operatingSystem} - ${response.systemInfo.browser}`,
        type: this.defineDeviceType(response.systemInfo.operatingSystem),
        active: response.enableView,
        deviceId: response.deviceId,
        lastConnection: response.recentActivity,
        firstConnection: response.firstSession,
        location: `${response.address.state}`,
        systemInfo: response.systemInfo,
        master: response.master
      }
      return { error: null, data: response};
    } catch (e) {
      return { error: e, data: {} };
    }
  }

  getDeviceById = async (deviceId: String) => {
    try {
      let response: any = await this.apollo.query<any>({query: GET_DEVICE_BY_ID, variables: { deviceId }}).toPromise();
      let limitAccountManager = response.data.readDeviceById.limitAccountManager;
      response = response.data.readDeviceById.item;
      response = {
        name: `${response.systemInfo.operatingSystem} - ${response.systemInfo.browser}`,
        type: this.defineDeviceType(response.systemInfo.operatingSystem),
        active: response.enableView,
        deviceId: response.deviceId,
        lastConnection: response.recentActivity,
        firstConnection: response.firstSession,
        location: `${response.address.state}`,
        systemInfo: response.systemInfo,
        master: response.master,
        limitAccountManager: limitAccountManager
      }
      return { error: "", data: response};
    } catch (e) {
      return { error: e, data: [] };
    }
  }







  /* Delete actual device from user session devices list */
  public async deleteActualUserSessionDevice(): Promise<any> {
    let deviceUuid = await this.deviceHelper.getUUID().then();
    await this.deleteDevice(deviceUuid);
  }





  //method to create device information
  public async getDeviceData(): Promise<any>{
    const deviceInfo = await Device.getInfo();
    const deviceUuid = await this.deviceHelper.getUUID().then();
    let locationData = {
      ip: '',
      country: '',
      city: ''
    };
    let os = '';
    let browser = '';
    const devicePlatform = (await Device.getInfo()).platform;
    if (this.isAdaptable) {
      os = devicePlatform;
    } else {
      os = `TV ${devicePlatform}`;
    }

    if (this.isMobilePlatform) {
      browser = deviceInfo.name;
    } else {
      browser = this.getBrowserInfo();
    }
    locationData = await this.getLocationData();
    const deviceData = {
      deviceId : deviceUuid,
      country : environment.COUNTRY,
      systemInfo : {
        operatingSystem : os,
        browser : browser
      }
    };

    this.storage.setPlatformInfo(deviceData);
    return deviceData;
  }

  public async getOsInformation(){
    const deviceInfo = await Device.getInfo();
    let os = '';
    let browser = '';
    const devicePlatform = (await Device.getInfo()).platform;
    if (this.isAdaptable) {
      os = devicePlatform;
    } else {
      os = `TV ${devicePlatform}`;
    }

    if (this.isMobilePlatform) {
      browser = deviceInfo.name;
    } else {
      browser = this.getBrowserInfo();
    }
    const osInfo = {
      country : environment.COUNTRY,
      systemInfo : {
        operatingSystem : os,
        browser : browser
      }
    };
    return osInfo;
  }


  /* Define the device type */
  private defineDeviceType(os:string): string {
    let response = '';
    if (os.toLowerCase() === "android" || os.toLowerCase() === "ios") {
      response = "phone";
    } else {
      response = "pc";
    }
    return response;
  }

  /* Get browser information */
  private getBrowserInfo() {
    var ua= navigator.userAgent, tem,
    M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if(/trident/i.test(M[1])){
        tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE '+(tem[1] || '');
    }
    if(M[1]=== 'Chrome'){
        tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
        if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
    return M.join(' ');
  };

  /* Get location */
  private async getLocationData(): Promise<any> {
    let locationData = this.locationService.getLocation();
    return locationData;
  }


  setDeviceId(deviceFingerprintId: string): void {
    const deviceInfo = this.deviceDetectorService.getDeviceInfo();
    const device = {
      deviceFingerprintId,
      userAgent: (deviceInfo ? deviceInfo.userAgent : '')
    };
    this.deviceId = Md5.init(device);
  }

  getDeviceId(): string {
    return this.deviceId;
  }

 async getAllDeviceActive() {
   let response: any;
   try {
      response = await this.apollo.query({query: GET_ALL_DEVICE_ACTIVE}).toPromise();
     let items = [];
     const deviceArray = [];
     if (response.data && response.data?.readAllDeviceActive) {
       items = response.data?.readAllDeviceActive?.items;
       if (items && items.length > 0) {
         items.forEach((item) => {
           const device = {
             type: this.defineDeviceType(item.systemInfo.operatingSystem),
             browser: item.systemInfo.browser,
             state: item.address.state,
             city: item.address.city,
             deviceId: item.deviceId,
             contentView: item?.contentView ? item?.contentView : ''
           };
           deviceArray.push(device);
         });
         response = deviceArray;
       }
     }
    } catch (e) {
     response = null;
     console.log(e);
   }
   return response;
 }

 async setConnectionDevice(deviceId: string = '', contentView: string) {
    let response: any;
    let dataDevice: any = {};
    try {
      response = await this.apollo.mutate({
        mutation: MUTATION_SET_CONECTION,
        variables: {
          deviceId,
          contentView
        }
      }).toPromise();
      // validate play user limit
      if (response?.data?.putConnection?.itemEnable) {
        dataDevice = {
          deviceId: response?.data?.putConnection?.itemEnable.deviceId,
          enableView: response?.data?.putConnection?.itemEnable.enableView
        };
      } else {
        dataDevice = {
          deviceId: null,
          enableView: null
        };
      }
    } catch (e) {
      dataDevice = null;
      const objError = {
        video_title: contentView,
        journey: 'Connection Device',
        error_id: e?.status ? e?.status : '',
        error_message: e?.message ? e?.message : '',
        error_type: e?.error?.msg ? e?.error?.msg : ''
      };
      this.segmentAnalytics.eventTrack(EVENT_TRACK_144, objError).then();
      console.error(e);
    }
    return dataDevice;
 }


 async setConectionDeactive() {
    let response: any;
    let dataDevice: any = null;
    try {
      response = await this.apollo.mutate({
        mutation: MUTATION_SET_CONECTION_DEACTIVE
      }).toPromise();
      if (response?.data && response?.data?.putConnectionDeactivate) {
        dataDevice = {
          deviceId: response?.data?.putConnectionDeactivate?.deviceId,
          enableView: response?.data?.putConnectionDeactivate?.enableView
        };
      }
    } catch (e) {
      dataDevice = null;
      const objError = {
        journey: 'Connection Deactive',
        error_id: e?.status ? e?.status : '',
        error_message: e?.message ? e?.message : '',
        error_type: e?.error?.msg ? e?.error?.msg : ''
      };
      this.segmentAnalytics.eventTrack(EVENT_TRACK_144, objError).then();
      console.error(e);
    }
    return dataDevice;
 }


 getBrowser() {
    return this.getBrowserInfo();
 }

 setDeviceActive(body: any) {
    this.bodyDeviceActive = body;
 }

 getDevicesActive() {
    return this.bodyDeviceActive;
 }

 setFlagControllerTv(value) {
    this.tvModeUser = value;
 }

 getFlagControllerTv() {
   return  this.tvModeUser;
 }


}
