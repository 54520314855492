import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {GET_ALLENTITLEMENT, GET_ENTITLEMENT, VALIDATE_ENTITLEMENT} from '../config/endpoints';
import {ENTITLEMENT} from '../config/settings';
import {User} from '../core/interfaces/user';
import {Storage} from '../core/storage/storage';
import { CarrierBillingService } from './carrier-billing.service';

@Injectable({
  providedIn: 'root'
})
export class EntitlementService {

  constructor(
    private httpClient: HttpClient,
    private storage: Storage,
    //private carrierBillingService: CarrierBillingService
  ) {
  }

  /* Check if the user has entitlement */
  public async getEntitlement(): Promise<boolean> {
    let status = false;
    let username = '';
    const type = this.storage.getAuthType();
    const user = this.storage.getUser() ? this.storage.getUser() : this.storage.getUserCarrierBilling();
    if (user) {
      if (user.authentication_method === 'tigoId') {
        const url = `${GET_ENTITLEMENT}/${user.entitlement_category}`;

        if (type === 'other') {
          username = user.value_entetliment;
        } else {
          username = user.phone_number;
        }

        const headers = new HttpHeaders({username});
        try {
          const response: any = await this.httpClient.get(url, {headers}).toPromise();
          if (response) {
            if (response.status === ENTITLEMENT.SUCCESS_RESPONSE) {
              status = true;
            }
          }
        } catch (error) {
          status = false;
        }
        this.storage.setEntitlement(status, user);
      }else if(user.authentication_method === 'toolbox'){
        status = await this.validateEntitlementCarrierbilling(user.token);
        this.storage.setEntitlement(status, user);
      }

    }
    return status;
  }

    //method to get entitlemnt of the user login with personal
    public async validateEntitlementCarrierbilling(idUser: string){
      let hasEntitlement = false;
      const headers: any = {
        'Content-Type': 'application/json',
      };
      const url = `${VALIDATE_ENTITLEMENT}/${idUser}`;
      const response: any = await this.httpClient.get(url, { headers }).toPromise();
      if(response.data && response.code === 200){
         hasEntitlement = response.data.access;
      }
      return hasEntitlement;
    }
  /* check validation new entetliment home, other and movil */
  public async getEntetilmentProfile(): Promise<any> {
    let response: any = [];
    let statusresponse = false;
    const tokeid = this.storage.getAuthToken();
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const Tokenid = tokeid ? tokeid : '';
    const body = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Tokenid
    };
   // const headers = new HttpHeaders({Tokenid});
    const url = `${GET_ALLENTITLEMENT}`;
    try {
       response = await this.httpClient.post(url, body, {
         headers: {
           // eslint-disable-next-line @typescript-eslint/naming-convention
           'Content-Type': 'application/json'
         }
       }).toPromise();
      if (response && response.status && response.body?.status === ENTITLEMENT.SUCCESS_RESPONSE) {
        statusresponse = true;
      }
    } catch (error) {
      statusresponse = false;
    }
    return {statusresponse, response};
  }

}
