import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {Subscription} from 'rxjs';
import {TigoIdService} from './tigo-id.service';
import {NavController} from '@ionic/angular';
import { Storage } from '../core/storage/storage';
import { AuthService } from './auth.service';
import { LoadingService } from './loading.service';
import { EntitlementService } from './entitlement.service';
import { TvLoginService } from './tv-login.service';
import {CarrierBillingService} from './carrier-billing.service';
import {TextManagementService} from './text-management.service';
import { ActivateCodeService } from './activate-code.service';
import { SegmentAnalytics } from '../core/providers/segment-analytics';
import {EVENT_TRACK_012, EVENT_TRACK_024} from '../config/analytics/events-track';
@Injectable({
  providedIn: 'root'
})
export class QueryParamsService {
  subscription: Subscription;
  isValidateEntitlement = false;
  turnofsucription: any = {};
  constructor(private activatedRoute: ActivatedRoute,
              private tigoIdService: TigoIdService,
              private navController: NavController,
              private authService: AuthService,
              private storage: Storage,
              private router: Router,
              private loadingService: LoadingService,
              private entitlementService: EntitlementService,
              private tvLoginService: TvLoginService,
              private carrierBillingService: CarrierBillingService,
              private textManagementService: TextManagementService,
              private route: Router,
              private activatedCodeService: ActivateCodeService,
              private segmentAnalytics: SegmentAnalytics
              ) { }

  /* Get URL params */
  public async queryParamsListener(): Promise<void> {
    this.loadingService.setLoading(true,'loadingAuth0');
    this.subscription = this.activatedRoute.queryParamMap.subscribe(
      async (queryParam: any) => {
        this.turnofsucription = await this.textManagementService.getTurnoPayment();
        const { params } = queryParam;
        const { code, token, type, device, toolbox_user_token, Route, Pvv, Scp, error, error_description,  PayerID, Dvm} = params;
        if (code || token || type || device || toolbox_user_token || Route || Pvv || Scp || error || error_description || PayerID || Dvm) {
          if (token && PayerID) {
            const body: any = this.storage.getPayloadPaypal();
            const payloadPaypal: any = JSON.parse(body);
            if (payloadPaypal?.token === token) {
              if (this.storage.getIsWebView() === 'false') {
                this.navController.navigateForward('/ajustes/subscription/payment-paypal/estado');
              } else {
                const webView = '?status=webview';
                this.navController.navigateRoot('/ajustes/subscription/payment-paypal/estado'+webView);
              }
            }
          }
          if (error || error_description) {
            this.loadingService.setLoading(false,'loadingAuth0');
            this.navController.navigateForward('/-/error-login', { state: {error, errorDescription: error_description}}).then(r => {});
          }
          if(toolbox_user_token){
            this.storage.setTokenPersonal(toolbox_user_token);
          }
          this.loadingService.setLoading(true,'new key');
          if (token && type) {
            let route;
            // Is webview for payments
            await this.textManagementService.setTurnoffsuscription(Scp);
            await this.textManagementService.setTurnoffPvv(Pvv);
            if (Dvm) {
              this.storage.setDeviceNative(Dvm);
            }
            if(this.route.url.includes('loading')){
              this.storage.setIsWebView('true');
              if (Route) {
                route = `/ajustes/${ Route.replace('8$8','?')}`;
              } else {
                route = '/ajustes/MetodosPago?';
              }
              this.loadingService.setLoading(false,'loadingAuth0');
              this.storage.setRouteDetail(route);
              await this.tvLoginService.getTokenData(token, type,device,true,route);
              this.loadingService.setLoading(false,'new key');
            }
            this.loadingService.setLoading(false,'new key');
            this.loadingService.setLoading(false,'loadingAuth0');
          }
          else if (code) {
            if(this.storage.getUserCarrierBilling()){
              this.storage.removeDevicesLimit();
              await this.authService.removeAuthentication();
            }
            const version4 = this.storage.getVersionTv();
            if(this.storage.getPersonalFlow() && version4 === 'v4'){
              await this.activatedCodeService.activateCodeV4(code, 'Toolbox');
              this.storage.removePersonalFlow();
              this.loadingService.setLoading(false,'loadingAuth0');
              this.loadingService.setLoading(false,'new key');
              this.navController.navigateForward('/-/success-activate');
              this.segmentAnalytics.eventTrack(EVENT_TRACK_024, {journey: 'registration'});
              return;
            } else if (this.storage.getPersonalFlow() && !version4) {
              await this.carrierBillingService.validateSessionV3(code);
              this.storage.removePersonalFlow();
              this.loadingService.setLoading(false,'loadingAuth0');
              this.loadingService.setLoading(false,'new key');
              this.navController.navigateForward('/-/success-activate');
              this.segmentAnalytics.eventTrack(EVENT_TRACK_024, {journey: 'registration'});
              return;
            }
            let tvAuthValidation = this.storage.getTVAuthState();
            const auth = await this.tigoIdService.generateTokenTigoID(code);

            if (auth) {
              //await this.validEntitlement();
              this.tvValidations(tvAuthValidation);
            }else{
              this.loadingService.setLoading(false,'new key');
            }
            this.loadingService.setLoading(false,'loadingAuth0');
          }else if(toolbox_user_token){
            let tvAuthValidation = this.storage.getTVAuthState();
            /*const version4 = this.storage.getVersionTv();
            if(version4 === 'v4'){
              await this.activatedCodeService.activateCodeV4(toolbox_user_token, 'Toolbox');
            }*/
            this.isValidateEntitlement = await this.carrierBillingService.validateSession(toolbox_user_token);
            const user = this.storage.getUserCarrierBilling();
            if(this.isValidateEntitlement === false){
              this.segmentAnalytics.eventTrack(EVENT_TRACK_024, {journey: 'registration'});
              this.loadingService.setLoading(false,'new key');
              this.loadingService.setLoading(false,'loadingAuth0');
              if (this.turnofsucription.pvv === 'true') {
                this.navController.navigateForward('/-/PasarelaAcceso');
              } else if (this.turnofsucription.suscription === 'true') {
                this.navController.navigateForward('/-/no-suscripcion');
              } else {
                this.navController.navigateRoot('/inicio');
              }
            }
            if (user) {
              await this.segmentAnalytics.eventTrack(EVENT_TRACK_012, { journey: "login"});
              this.tvValidations(tvAuthValidation);
            } else {
              if(tvAuthValidation && !this.storage.getTVAuthState()){
                tvAuthValidation=false;
                this.loadingService.setLoading(false,'new key');
                this.loadingService.setLoading(false,'loadingAuth0');
                this.segmentAnalytics.eventTrack(EVENT_TRACK_024, {journey: 'registration'});
                this.navController.navigateForward('/-/success-activate');
              }
              this.loadingService.setLoading(false,'new key');
            }
            this.loadingService.setLoading(false,'loadingAuth0');
          }else{
            this.loadingService.setLoading(false,'new key');
          }
        }
        this.loadingService.setLoading(false,'loadingAuth0');
      }
    );
    this.loadingService.setLoading(false,'loadingAuth0');
  }
  private async validEntitlement(){
    this.isValidateEntitlement = await this.entitlementService.getEntitlement();
  }

  private tvValidations(tvAuthValidation){
    const previousRoute = this.storage.getPreviousRoute();

    this.isValidateEntitlement = this.storage.getUser() ? this.storage.getUser()?.entitlement : this.storage.getUserCarrierBilling()?.entitlement;

    if(tvAuthValidation && !this.storage.getTVAuthState()){
      tvAuthValidation=false;
      this.loadingService.setLoading(false,'new key');
      this.navController.navigateForward('/-/success-activate');
      this.segmentAnalytics.eventTrack(EVENT_TRACK_024, {journey: 'registration'});
    }
    else if(this.isValidateEntitlement === false){
      this.loadingService.setLoading(false,'new key');
      if (this.turnofsucription.pvv === 'true') {
        this.segmentAnalytics.eventTrack(EVENT_TRACK_024, {journey: 'registration'});
        this.navController.navigateForward('/-/PasarelaAcceso');
      } else if (this.turnofsucription.suscription === 'true') {
        this.segmentAnalytics.eventTrack(EVENT_TRACK_024, {journey: 'registration'});
        this.navController.navigateForward('/-/no-suscripcion');
      } else {
        this.segmentAnalytics.eventTrack(EVENT_TRACK_024, {journey: 'registration'});
        this.navController.navigateRoot('/inicio');
      }
    }
    else if (previousRoute) {
      this.storage.removePreviousRoute();
      this.loadingService.setLoading(false,'new key');
      this.router.navigateByUrl(previousRoute);
    } else {
      this.loadingService.setLoading(false,'new key');
      this.navController.navigateRoot('/inicio');
    }
  }




}
