import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-message-vecima',
  templateUrl: './message-vecima.component.html',
  styleUrls: ['./message-vecima.component.scss'],
})
export class MessageVecimaComponent  implements OnInit {
  @Input() data: any;

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
    //console.log(this.data);
  }

  close() {
    this.modalCtrl.dismiss({cancel: true}).then();
  }

}
