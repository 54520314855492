import { Component, OnInit, Input } from '@angular/core';
import { BillingControlBase } from '../base-model/billing-control-base';
import { UntypedFormGroup } from '@angular/forms';
import { BillingService } from 'src/app/services/billing-service';
import {BillingFormBuilder} from "../base-builder/billing-form-builder";

@Component({
  selector: 'app-billing-form-control',
  templateUrl: './billing-form-control.component.html',
  styleUrls: ['./billing-form-control.component.scss'],
})
export class BillingFormControlComponent implements OnInit {
  @Input() control: BillingControlBase<string>;
  @Input() form: UntypedFormGroup;
  @Input() billingNumber:boolean;
  format_billing: any;
  controls: BillingControlBase<string>[] = [];
  controlsBill: BillingControlBase<string>;
  isSelect = false;
  constructor(private billingService: BillingService,private billingFormBuilder: BillingFormBuilder) { }

  ngOnInit() {
  };

  public async selectChange(value: any){
    let itemSelected: any;
    itemSelected = this.control.options.find(i => i.key === value);
    this.controlsBill = itemSelected.optionsBilling[0];
    this.isSelect=true;
  }

}
