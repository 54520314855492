import {Injectable} from '@angular/core';
import {NavController, Platform} from '@ionic/angular';
import {BehaviorSubject} from 'rxjs';
import {DrmIosEventService} from './drm-ios-event.service';
import {Capacitor} from '@capacitor/core';
import {Broadcaster} from '@awesome-cordova-plugins/broadcaster/ngx';
import {SegmentAnalytics} from 'src/app/core/providers/segment-analytics';
import {DRM_TOKEN_V2, LOGS_DRM} from '../config/endpoints';
import {
  EVENT_TRACK_040,
  EVENT_TRACK_041,
  EVENT_TRACK_042,
  EVENT_TRACK_043,
  EVENT_TRACK_044,
  EVENT_TRACK_073,
  EVENT_TRACK_077
} from '../config/analytics/events-track';
import {Storage} from '../core/storage/storage';
import {HttpClient} from '@angular/common/http';
import {COUNTRY_CODE_KEY, IS_ADAPTABLE} from '../config/constants';
import {ExoplayerService} from './exoplayer.service';
import {TextManagementService} from './text-management.service';
import {DeviceHelper} from '../core/helpers/device.helper';
import {convertToParamMap,Router} from '@angular/router';
import {AdsService} from './ads.service';
import {DevicesService} from './devices.service';
import {LoadingService} from './loading.service';
import { TextManagementV2Service } from './text-management-v2.service';
//import { SESIONS_TOKENS_VECIMA} from '../queries/query';
import {Device} from '@capacitor/device';
import {MessageDialogService} from './message-dialog.service';


@Injectable({
  providedIn: 'root'
})
export class PlayerService {
  id: any;
  streamingType: any;
  streamingUrl: any;
  streamingUrlDash: any;
  streamingUrlHls: any;
  previousUrl: any;
  videoPlaybackSource = new BehaviorSubject(false);
  videoPlayback = this.videoPlaybackSource.asObservable();
  validatedResolution = false;
  fullScreanSource = new BehaviorSubject(false);
  fullscreen = this.fullScreanSource.asObservable();
  isDRM = false;
  transmissionData: any;
  actualPlatform = Capacitor.getPlatform();
  channelNumber: any;
  widevineSecurityLevel: any;
  token: string;
  drmEndpoints: any;
  currentRoute: string;
  onUserConcurrent: any;
  VecimaTokenDrm: string = '';
  vecimaTokenStreaming: string = '';
  vecimaSessionId: any;
  vecimaDateExpiration: any;
  vecimaIpUser: any;
  vecimaValueQ: any;
  vecimaVersion: any;
  private refreshTokenVecima: boolean = true;

  constructor(
    private navController: NavController,
    private platform: Platform,
    private drmIosEvent: DrmIosEventService,
    private broadCasterNative: Broadcaster,
    private segmentAnalytics: SegmentAnalytics,
    private storage: Storage,
    private httpClient: HttpClient,
    private exoplayerService: ExoplayerService,
    private textManagementService: TextManagementService,
    private textManagementV2Service: TextManagementV2Service,
    private device: DeviceHelper,
    private router: Router,
    private adsservice: AdsService,
    private deviceservices: DevicesService,
    private loadingService: LoadingService,
    private modalServices: MessageDialogService
  ) {
    const deviceType = Capacitor.getPlatform();
    if (deviceType !== 'web') {
      this.broadCasterNative.addEventListener('evenPlayExoplayerIsPIP').subscribe((event) => {
        this.storage.setIsPIP(event);
      });
      this.broadCasterNative.addEventListener('evenPlayExoplayer').subscribe((event) => { this.eventExoPlayer(event); });
      this.broadCasterNative.addEventListener('evenPlayAvePlayer').subscribe((event) => this.evenPlayAvePlayer(event));
      this.broadCasterNative.addEventListener('drmStatus').subscribe((event) => {
        const transmissionDataExported = this.getTransmissionData();
        if (event.satus) {
          this.setLogsDRM(transmissionDataExported.contentful_id, transmissionDataExported.video_title, 'fairplay', 'generado');
        } else {
          this.setLogsDRM(transmissionDataExported.contentful_id, transmissionDataExported.video_title, 'fairplay', 'no generado');
        }
      });
      this.broadCasterNative.addEventListener('drmStatusExoPlayer').subscribe((event) => {
        const transmissionDataExported = this.getTransmissionData();
        if (event.satus) {
          this.setLogsDRM(transmissionDataExported.contentful_id, transmissionDataExported.video_title, 'widevine', 'generado');
        } else {
          this.setLogsDRM(transmissionDataExported.contentful_id, transmissionDataExported.video_title, 'widevine', 'no generado');
        }
      });
    }
  }


  public async setLogsDRM(idEvent: string,nameEvent: string,type: string,state: string){
    const userTigo = this.storage.getUser();
    const userCB = this.storage.getUserCarrierBilling();

    if(userTigo){
      this.generateLogTigo(userTigo,idEvent,nameEvent,type,state);
      return;
    }
      this.generateLogCarrierBilling(userCB,idEvent,nameEvent,type,state);
  }



  private async generateLogTigo(user,idEvent,nameEvent:string,type:string,state:string){
    const phoneEmail = user.email ? user.email : user.phone_number;
    const url = `${LOGS_DRM}/${user.uniqueIdentifier ? user.uniqueIdentifier : user.uuid}`;
    const data =
      {
        noContract: this.generateContract(user) ,// número contrato de tigo id,
        phoneEmail: phoneEmail, //phone email de tigo id,
        nameEvent: nameEvent, //nombre del evento del player si es play siempre sera play,
        contentfulId:idEvent,
        type: type, //fairplay o widevine,
        state: state //generado o no generado licencia DRM,
      }

    await this.httpClient.post(url, data).toPromise();

  }

  private async generateLogCarrierBilling(user,idEvent,nameEvent:string,type:string,state:string){
    const url = `${LOGS_DRM}/${user.id}`;
    const data =
      {
        providerCb: user.authentication_type, //nombre del proveedor, en este caso personal
       // subscriberidCb: user.suscriber_id,
        phoneEmail: user.suscriber_id,
        nameEvent: nameEvent,
        contentfulId:idEvent,
        type: type,
        state: state
      }
    await this.httpClient.post(url, data).toPromise();
  }

  private generateContract =(user)=>{


    if(user?.user_model){
      const userModel = user.user_model;
      if(userModel.homeList.length > 0){
        return userModel.homeList[0].homeid;
    }
    return 'NoContract';
  }else if(user?.phone_contract_no){
    return user.phone_contract_no;
  }else{
    return 'NoContract';
  }
  };

  getSegmentData(currentTime, duration, quality?) {
    const transmissionDataExported = this.getTransmissionData();
    return {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      championship_id: transmissionDataExported.championship_id ? transmissionDataExported.championship_id : '',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      championship_name: transmissionDataExported.championship_id ? transmissionDataExported.championship_id : '',
      airdate: transmissionDataExported.airdate,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      sport_name: transmissionDataExported.sport_name,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      video_title: transmissionDataExported.video_title,
      journey: 'player',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      content_id: transmissionDataExported.contentful_id,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      home_team_id: transmissionDataExported.home_team_id,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      home_team: transmissionDataExported.home_team,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      away_team_id: transmissionDataExported.away_team_id,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      away_team: transmissionDataExported.away_team,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      total_length: transmissionDataExported.total_length,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      video_position: currentTime,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      session_id: transmissionDataExported.session_id,
      // video_quality: this.getQualityStream(),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      content_type: transmissionDataExported.content_type,
      video_quality: quality ? quality : '720',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      video_percentage_start: this.getPercentageStream('start', currentTime, duration),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      video_percentage_viewed: this.getPercentageStream('viewed', currentTime, duration),
    };
  }

  /* Get stream playback percentage */
  getPercentageStream(type, currentTime, duration) {
    let percentage: any;
    const transmissionDataExported = this.getTransmissionData();
    const startDate = new Date(transmissionDataExported.startDate).getTime() / 1000;
    const endDate = new Date(transmissionDataExported.endDate).getTime() / 1000;
    const nowDate = new Date().getTime() / 1000;

    if (nowDate > startDate && nowDate < endDate) {
      try {
        let current: any;

        if (type === 'start') {
          current = (nowDate - startDate);
          const auxTime = (endDate - startDate);
          percentage = ((current * 100) / auxTime).toFixed(2);
        } else if (type === 'viewed') {
          current = currentTime;
          percentage = ((current * 100) / (transmissionDataExported.total_length )).toFixed(2);
        }
      } catch (error) {
        percentage = 0;
      }
    } else {
      try {
        if (duration === Infinity) {
          percentage = 100;
        } else {
          if (duration > 0) {
            if (currentTime >= 1) {
              percentage = (currentTime / duration).toFixed(2);
              percentage = parseFloat(percentage) * 100;
            } else {
              percentage = 0;
            }
          } else {
            percentage = 0;
          }
        }
      } catch (error) {
        percentage = 0;
      }
    }
    return percentage;
  }


  async eventExoPlayer(event: any) {
    const segmentData = this.getSegmentData(event.position, event.duration,event.quality);

    switch(event.event) {
      case 'Video Started': {
        await this.segmentAnalytics.eventTrack(EVENT_TRACK_040, segmentData);
        break;
      }
      case 'Video Finished': {
        await this.segmentAnalytics.eventTrack(EVENT_TRACK_041, segmentData);
        break;
      }
      case 'Video Resumed': {
        await this.segmentAnalytics.eventTrack(EVENT_TRACK_042, segmentData);
        break;
      }
      case 'Video Paused': {
        await this.segmentAnalytics.eventTrack(EVENT_TRACK_043, segmentData);
        break;
      }
      case 'Video Failed': {
        await this.segmentAnalytics.eventTrack(EVENT_TRACK_044, segmentData);
        break;
      }
      case 'Video Closed': {
        if (this.onUserConcurrent?.status && this.onUserConcurrent?.status === true) {
          await this.connectionDeactiveAppSync();
          await this.deleteSessionIdStreaming();
        }
        await this.segmentAnalytics.eventTrack(EVENT_TRACK_073, segmentData);
        this.router.navigateByUrl(this.currentRoute);
        break;
      }
      case 'VideoValidateSession': {
        if (this.onUserConcurrent?.status && this.onUserConcurrent?.status === true) {
          const contentView = this.getTransmissionData();
          const deviceId = await this.device.getUUID().then();
          const resConnectionDevice: any = await this.deviceservices.setConnectionDevice('', contentView?.title ? contentView?.title : '');
          if (!resConnectionDevice?.enableView && resConnectionDevice?.deviceId !== deviceId) {
            this.deleteSessionIdStreaming().then();
            this.broadCasterNative.fireNativeEvent('exitPlayer', false,{})
              .then((res: any) => {
              }).catch((err) => {
              console.log(err);
            });
          }
        }
      }
    }
  }


  private async connectionDeactiveAppSync() {
   // this.loadingService.setLoading(true, 'setConnectionDeactiveAndroid');
    try {
      await this.deviceservices.setConectionDeactive();
     // this.loadingService.setLoading(false, 'setConnectionDeactiveAndroid');
    } catch (e) {
      console.error(e);
    }
  }

  async evenPlayAvePlayer(event: any) {
    const currentTime = await this.drmIosEvent.currentTime();
    const duration = await this.drmIosEvent.duration();
    const segmentData = this.getSegmentData(currentTime, duration);

    switch(event.state) {
      case 'Video Started': {
        await this.segmentAnalytics.eventTrack(EVENT_TRACK_040, segmentData);
        break;
      }
      case 'Video Finished': {
        await this.segmentAnalytics.eventTrack(EVENT_TRACK_041, segmentData);
        break;
      }
      case 'Video Resumed': {
        await this.segmentAnalytics.eventTrack(EVENT_TRACK_042, segmentData);
        break;
      }
      case 'Video Paused': {
        await this.segmentAnalytics.eventTrack(EVENT_TRACK_043, segmentData);
        break;
      }
      case 'Video Failed': {
        await this.segmentAnalytics.eventTrack(EVENT_TRACK_044, segmentData);
        break;
      }
      case 'Video Closed': {
        if (this.onUserConcurrent?.status && this.onUserConcurrent?.status === true) {
          await this.connectionDeactiveAppSync();
          await this.deleteSessionIdStreaming();
        }
        await this.segmentAnalytics.eventTrack(EVENT_TRACK_073, segmentData);
        break;
      }
      case 'VideoValidateSession': {
        if (this.onUserConcurrent?.status && this.onUserConcurrent?.status === true) {
          const contentView = this.getTransmissionData();
          const myuuidDevice = await this.device.getUUID().then();
          const resConnectionDevice: any = await this.deviceservices.setConnectionDevice('', contentView?.title ? contentView?.title : '');
          if (!resConnectionDevice?.enableView && resConnectionDevice?.deviceId !== myuuidDevice) {
            this.deleteSessionIdStreaming().then();
            this.broadCasterNative.fireNativeEvent('exitPlayer', false,{})
              .then((res: any) => {
              }).catch((err) => {
              console.log(err);
            });
          }
        }
      }
    }
  }

  /* Set video ID */
  setId(id: string) {
    this.id = id;
  }

  /* Return video ID */
  getId() {
    return this.id;
  }

  /* Set streaming type */
  setStreamingType(type: any) {
    this.streamingType = type;
  }

  /* Return streaming type */
  getStreamingType() {
    return this.streamingType;
  }

  /* Set streaming URL */
  setStreamingUrl(url: any) {
    this.streamingUrl = url;
  }

  /* Return streaming URL */
  getStreamingUrl() {
    return this.streamingUrl;
  }

  /* Set streaming URL */
  setStreamingUrlDash(url: any) {
    this.streamingUrlDash = url;
  }

  /* Return streaming URL */
  getStreamingUrlDash() {
    return this.streamingUrlDash;
  }

  /* Set streaming URL */
  setStreamingUrlHls(url: any) {
    this.streamingUrlHls = url;
  }

  /* Return streaming URL */
  getStreamingUrlHls() {
    return this.streamingUrlHls;
  }

  /* Set previous URL */
  setPreviousUrl(url: any) {
    this.previousUrl = url;
  }

  /* Return previous URL */
  getPreviousUrl() {
    return this.previousUrl;
  }

  /* Return Channel Number */
  getChannelNumber() {
    return this.channelNumber;
  }

  /* Return Security Widevine level */
  getSecurityWidevineLevel() {
    return this.widevineSecurityLevel;
  }

  /* Set Channel Number */
  setChannelNumber(no: any) {
    this.channelNumber = no;
  }

  /* Set Channel Number */
  setWidevineSecurityLevel(level: any) {
    this.widevineSecurityLevel = level;
  }

  /* Set token for transmision */
  setToken(value: string){
    this.token = value;
  }

  getToken(){
    return this.token;
  }

  /* Play video */
  async playVideo() {
    const texts = await this.textManagementService.getTexts();
    const textsV2 = await this.textManagementV2Service.getTexts();
    this.onUserConcurrent = textsV2?.USERCONCURRENTON;
    const isEnableVecima = textsV2?.VECIMAOPTIONS?.vecimaGenerateTokensOn;

    if (Capacitor.isNativePlatform() && this.platform.is('ios')) {
      await this.segmentAnalytics.eventTrack(EVENT_TRACK_077, {journey: 'user interface', player_name: 'avplayer'});
      let userData: any = this.storage.getUser();
      userData = userData ? (userData.phone_number ? userData.phone_number : userData.email): 'anon';
      let uuid = this.storage.getUser() ? this.storage.getUser() : this.storage.getUserCarrierBilling();
      uuid = uuid.id;
      if (this.isDRM) {
        if (isEnableVecima) {
         // console.log(this.getStreamingUrlHls());
          this.loadingService.setLoading(true, 'load-VecimaGenerate');
          const isValidaVecima : boolean =  await this.processVecimaStructure(textsV2);
          if (isValidaVecima) {
            let stUrlDrm = '';
            const url = `${this.getStreamingUrlHls()}?t=${encodeURIComponent(this.getTokenStreamingVecima())}&s=${encodeURIComponent(this.getSessionIdVecima())}&c=${encodeURIComponent(this.getIpUserVecima())}&e=${encodeURIComponent(this.getDateExpirationVecima())}&v=${encodeURIComponent(this.getVersionVecima())}&u=${encodeURIComponent(uuid)}`;
            stUrlDrm = this.addQueryStringVecima(url);
            this.loadingService.setLoading(false, 'load-VecimaGenerate');
            const dataStreamingVecima : any = {
              content_type: this.getTransmissionData()?.content_type,
              jwtToken: this.getTokenDrmVecima(),
              token: this.getTokenStreamingVecima()
              // token: this.getTransmissionData()?.token
            }
            await this.drmIosEvent.playDrmStreaming(dataStreamingVecima, stUrlDrm, this.getTokenStreamingVecima(), userData, uuid, isEnableVecima);
          }
        } else {
          await this.drmIosEvent.playDrmStreaming(this.getTransmissionData(),await this.getStreamingUrlHls(), await this.getToken(),userData, uuid, false);
        }
      } else {
        if (isEnableVecima) {
          this.loadingService.setLoading(true, 'load-VecimaGenerate');
          const isValidaVecima : boolean =  await this.processVecimaStructure(textsV2);
          if (isValidaVecima) {
            let stUrl = '';
            const url = `${this.getStreamingUrl()}?t=${encodeURIComponent(this.getTokenStreamingVecima())}&s=${encodeURIComponent(this.getSessionIdVecima())}&c=${encodeURIComponent(this.getIpUserVecima())}&e=${encodeURIComponent(this.getDateExpirationVecima())}&v=${encodeURIComponent(this.getVersionVecima())}&u=${encodeURIComponent(uuid)}`;
            stUrl = this.addQueryStringVecima(url);
            this.loadingService.setLoading(false, 'load-VecimaGenerate');
            const dataStreamingVecima : any = {
              content_type: this.getTransmissionData()?.content_type,
              token: this.getTokenStreamingVecima()
              // token: this.getTransmissionData()?.token
            }
            await this.drmIosEvent.playStreaming(dataStreamingVecima,stUrl, userData, uuid, isEnableVecima);
          }
        } else {
          await this.drmIosEvent.playStreaming(this.getTransmissionData(),await this.getStreamingUrl(), userData, uuid, false);
        }
      }
    } else if(Capacitor.isNativePlatform() && this.platform.platforms().indexOf('android') >= 0 && (!IS_ADAPTABLE || IS_ADAPTABLE)) {
      await this.segmentAnalytics.eventTrack(EVENT_TRACK_077, {journey: 'user interface', player_name: 'exoplayer'});
      this.currentRoute = this.router.url;

      let objectwm = {};
      const data = this.getTransmissionData();
      const statusss = (texts.FINGER_PRINTING ? (texts.FINGER_PRINTING[data.content_type] ?? false) : false);
      const user =this.storage.getUser() ? this.storage.getUser() : this.storage.getUserCarrierBilling();
      if (statusss && user) {
        const jump = (texts.FINGER_PRINTING.jump ?? 1) * 1000;
        const timeOn = texts.FINGER_PRINTING.timeOn ?? 10;
        const timeOff = texts.FINGER_PRINTING.timeOff ?? 120;
        const font = IS_ADAPTABLE ? texts.FINGER_PRINTING.fontAndroid : texts.FINGER_PRINTING.fonttv ?? 12;
        const { id } = user;
        objectwm = {jump, timeOn, timeOff, font, id};
      }

      let bodyAdsGoogle = {};
      if (IS_ADAPTABLE) {
        try {
          bodyAdsGoogle = await this.adsservice.getSettings();
        } catch (e) {
          console.error(e);
        }
      }

      let userData: any = this.storage.getUser() ? this.storage.getUser() : this.storage.getUserCarrierBilling();
      let userData2 = userData ? (userData.phone_number ? userData.phone_number : userData.email) : 'anon';

       if(this.isDRM){
         this.drmEndpoints = texts.VIDEO_STREAMING;

         if (isEnableVecima) {
           this.loadingService.setLoading(true, 'load-VecimaGenerate');
           const isValidaVecima : boolean =  await this.processVecimaStructure(textsV2);
           if (isValidaVecima) {
             let stUrlDrm = '';
             const url = `${this.getStreamingUrlDash()}?t=${encodeURIComponent(this.getTokenStreamingVecima())}&s=${encodeURIComponent(this.getSessionIdVecima())}&c=${encodeURIComponent(this.getIpUserVecima())}&e=${encodeURIComponent(this.getDateExpirationVecima())}&v=${encodeURIComponent(this.getVersionVecima())}&u=${encodeURIComponent(userData?.id)}`;
             stUrlDrm = this.addQueryStringVecima(url);
             this.loadingService.setLoading(false, 'load-VecimaGenerate');
             const dataStreamingVecima : any = {
               video_title: this.getTransmissionData()?.video_title,
               isLive: this.getTransmissionData()?.isLive,
               token: this.getTokenStreamingVecima()
               // token: this.getTransmissionData()?.token
             }
             this.exoplayerService.setPlayer(dataStreamingVecima,stUrlDrm,userData2, true, this.getTokenDrmVecima(), this.drmEndpoints.wServer, objectwm, isEnableVecima, bodyAdsGoogle);
           }
         } else {
           let licenseToken: any;
           licenseToken = await this.getTokenPlayer('widevine',this.getTransmissionData());
           this.exoplayerService.setPlayer(this.getTransmissionData(),this.getStreamingUrlDash(),userData2, true, licenseToken, this.drmEndpoints.wServer, objectwm, false, bodyAdsGoogle);
         }
       }else{
        //await this.navController.navigateForward(`/loading`);
        if (isEnableVecima) {
          this.loadingService.setLoading(true, 'load-VecimaGenerate');
          const isValidaVecima : boolean =  await this.processVecimaStructure(textsV2);
          if (isValidaVecima) {
            let stUrl = '';
            const url = `${this.getStreamingUrl()}?t=${encodeURIComponent(this.getTokenStreamingVecima())}&s=${encodeURIComponent(this.getSessionIdVecima())}&c=${encodeURIComponent(this.getIpUserVecima())}&e=${encodeURIComponent(this.getDateExpirationVecima())}&v=${encodeURIComponent(this.getVersionVecima())}&u=${encodeURIComponent(userData?.id)}`;
            stUrl = this.addQueryStringVecima(url);
            this.loadingService.setLoading(false, 'load-VecimaGenerate');
            const dataStreamingVecima : any = {
              video_title: this.getTransmissionData()?.video_title,
              isLive: this.getTransmissionData()?.isLive,
              token: this.getTokenStreamingVecima()
             // token: this.getTransmissionData()?.token
            }
            this.exoplayerService.setPlayer(dataStreamingVecima,stUrl,userData2, false, "", "", objectwm, isEnableVecima, bodyAdsGoogle);
          }
        } else {
           this.exoplayerService.setPlayer(this.getTransmissionData(),this.getStreamingUrl(),userData2, false, "", "", objectwm, false, bodyAdsGoogle);
        }
       }

    } else {
      if (isEnableVecima) {
        this.loadingService.setLoading(true, 'load-VecimaGenerate');
        const isValidaVecima : boolean =  await this.processVecimaStructure(textsV2);
        if (isValidaVecima) {
          this.loadingService.setLoading(false, 'load-VecimaGenerate');
          this.redirectPlayerWeb();
        }
      } else {
        this.redirectPlayerWeb();
      }
    }
  }

  /* Get token player for DRM license */
  async getTokenPlayer(type: string,eventData: any): Promise<string> {
    // Time variables
    const tNow = Math.floor(Date.now() / 1000) - 5;
    const tEnd = Math.floor(Date.now() / 1000) + 30;
    const myuuid = await this.device.getUUID().then();
    const user = this.storage.getUser() ? this.storage.getUser() : this.storage.getUserCarrierBilling();
    const noCh = eventData.channelNumber;
    const wsl = eventData.securityLevel;
    const uuid = user.id;
    const dhcpV = eventData.dhcpValue;
    // const isBrowser = (this.platform.is('mobileweb') || this.platform.is('desktop'));
    const isBrowser = (this.platform.is('mobileweb') || this.platform.is('desktop')) && IS_ADAPTABLE;

    return await this.getDrmToken(eventData);
  }

  async getDrmToken(data: any): Promise<string> {
    let tokenDrm = '';

    const apiResponse: any = await this.httpClient.get(`${DRM_TOKEN_V2}/${data.contentful_id}/${data.eventType}`).toPromise();
    if (apiResponse) {
      tokenDrm = apiResponse.token;
    }
    return tokenDrm;
  }


  /* process Vecima */
  async processVecimaStructure(textsV2) : Promise<boolean> {
    let flag: boolean;
    try {
      const responseBody = await this.generateAssetPathVecima();
      if (responseBody?.newUrl !== '' && responseBody?.eventType) {
        const urlVecimaTokens =  textsV2?.VECIMAOPTIONS?.url;
        const res: any =  await this.generateTokensStreaming(this.getTransmissionData()?.contentful_id, responseBody?.newUrl, responseBody?.eventType, urlVecimaTokens);
        if (res?.status && res?.data && res?.statusCode === 200) {
          flag = true;
        } else {
          let objError: any;
          flag = false;
          let message: any = {};
          if (res?.statusCode === 429) {
            objError = {
              journey: 'Video Failed - vecimaLicense',
              error_id: res?.statusCode,
              error_message: res?.message
            };
            message = textsV2?.VECIMAOPTIONS?.messageVecimaError409;
          } else {
             objError = {
              journey: 'Video Failed - vecimaToken',
              error_id: res?.statusCode,
              error_message: res?.message
            };
            message = textsV2?.VECIMAOPTIONS?.messageVecimaError;
          }
          this.segmentAnalytics.eventTrack(EVENT_TRACK_044, objError).then();
          this.modalServices.messageVecima(message?.title, message?.subtitle, message?.button).then();
          this.loadingService.setLoading(false, 'load-VecimaGenerate');
        }
      } else {
        flag = false;
        this.methodErroVecima(textsV2);
      }
    } catch (e) {
      flag = false;
      this.methodErroVecima(textsV2);
    }
    return flag;
  }

  /* generate tokens Vecima */
  async generateTokensStreaming(idContentfull: string, assetUrl: string, eventType: string, DomainVecimaTokens) {
    let response: any = {};
    try {
      response =  await this.httpClient.get(`${DomainVecimaTokens}/streaming/${idContentfull}?asset=${assetUrl}&typeContent=${eventType}`).toPromise();
      this.setTokenDrmVecima(response?.data?.a);
      this.setTokenStreamingVecima(response?.data?.t);
      this.setSessionIdVecima(response?.data?.s);
      this.setDateExpirationVecima(response?.data?.e);
      this.setIpUserVecima(response?.data?.c);
      this.setQvecima(response?.data?.q);
      this.setVersionVecima(response?.data?.v);
    } catch (e) {
      response = e?.error;
    }
    return response;
  }

  /* delete Sesion Vecima session id */
  async deleteSessionIdStreaming(isRefresh? : boolean) {
    let responseStatusCode: number = 0;
    let sessionId = this.getSessionIdVecima() ? this.getSessionIdVecima() : this.storage.getVecimaSession();
    if (sessionId) {
      if (isRefresh) {
        this.refreshTokenVecima = true;
        responseStatusCode = await this.methodDeleteVecima(sessionId);
      } else {
        responseStatusCode = await this.methodDeleteVecima(sessionId);
      }
    }
    return responseStatusCode;
  }

  private async methodDeleteVecima(sessionId) {
    let status: number = 0;
    if (this.refreshTokenVecima) {
      this.refreshTokenVecima = false;
      try {
        const textsV2 = await this.textManagementV2Service.getTexts();
        const DomainVecimaTokens = textsV2?.VECIMAOPTIONS?.url;
        const response: any = await this.httpClient.delete(`${DomainVecimaTokens}/streaming/${sessionId}`).toPromise();
        if (response?.status && response?.statusCode === 200) {
          this.storage.removeVecimaSession();
          this.resetValuesVecima();
          status = response?.statusCode;
          this.refreshTokenVecima = true;
        }
      } catch (e) {
        this.refreshTokenVecima = true;
        status = e.status;
        const objError = {
          journey: 'Video Failed - DeleteTokenVecima',
          error_id: e?.status ? e?.status : '',
          error_message: e?.message ? e?.message : '',
          error_type: e?.error?.msg ? e?.error?.msg : ''
        };
        this.segmentAnalytics.eventTrack(EVENT_TRACK_044, objError).then();
        this.loadingService.setLoading(false, 'delete-sessionId-Vecima');
      }
    }
    return status;
  }

  redirectPlayerWeb() {
    this.fullScreanSource.next(true);
    this.segmentAnalytics.eventTrack(EVENT_TRACK_077, {journey: 'user interface', player_name: 'videoJs'}).then();
    this.navController.navigateForward(`/reproductor/${this.id}`).then();
  }

  async generateAssetPathVecima() {
    let url: string = '';
    let newUrl = '';
    const deviceInfo = await Device.getInfo();
    const os_name = deviceInfo.operatingSystem;
    const browserName: string = this.getBrowserInfos();
    if (this.getIsDRM()) {
      if (((os_name === 'ios' || os_name === 'mac') && browserName.toLowerCase().indexOf('safari') >= 0) ||
        ((os_name === 'ios' || os_name === 'mac') && Capacitor.isNativePlatform())) {
        url = this.getStreamingUrlHls();
      } else {
        url = this.getStreamingUrlDash();
      }
    } else if (this.getStreamingUrl()) {
      url = this.getStreamingUrl();
    }

    if (url) {
      const urlStreaming = new URL(url);
      let urlNew = urlStreaming.pathname;
      newUrl = urlNew.substring(1);
    }

    const urlEvent = this.router.url;
    const parseUrl = urlEvent.split('/');
    const eventType = parseUrl[parseUrl.length - 1];

    return {newUrl, eventType};
  }

  methodErroVecima(textsV2) {
    let messageError: any = {}
    messageError = textsV2?.VECIMAOPTIONS?.messageVecimaError;
    this.modalServices.messageVecima(messageError?.title, messageError?.subtitle, messageError?.button).then();
    this.loadingService.setLoading(false, 'load-VecimaGenerate');
  }


  getBrowserInfos = () => {
    const ua = navigator.userAgent; let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) {return tem.slice(1).join(' ').replace('OPR', 'Opera');}
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {M.splice(1, 1, tem[1]);}
    return M.join(' ');
  };


  public addQueryStringVecima(url) {
    if (this.getQvecima()) {
      if (JSON.stringify(this.getQvecima()) !== '{}') {
        const objNames = Object.getOwnPropertyNames(this.getQvecima());
        if (objNames.length > 0) {
          let queryStringQ: string = '';
          let queryStringFinalQ: string = '&q=';
          for (let name of objNames) {
            queryStringQ += `&${name}=${encodeURIComponent(this.getQvecima()[name])}`;
            queryStringFinalQ += `${name},`;
          }
          queryStringFinalQ = queryStringFinalQ.substring(0, queryStringFinalQ.length - 1);
          url += `${queryStringQ}${queryStringFinalQ}`;
        }
      }
    }
    return url;
  }



  /* End playback */
  endPlayback(state: any) {
    this.videoPlaybackSource.next(state);
  }

  /* Reset playback */
  resetPlaybackStatus() {
    this.videoPlaybackSource.next(false);
  }

  /* Remove video settings */
  removeStreaming() {
    this.id = null;
    this.streamingType = null;
    this.streamingUrl = null;
    this.previousUrl = null;
    this.transmissionData = null;
  }

  /* Set is DRM video*/
  setIsDRM(is: boolean){
    this.isDRM = is;
  }

  /* Return is DRM video */
  getIsDRM(){
    return this.isDRM;
  }

  /* Set transmission data */
  setTransmissionData(data:any): void {
    this.transmissionData = data;
  }

  /* Return transmission data */
  getTransmissionData(): any {
    return this.transmissionData;
  }

  /* set Token vecima DRm */
  setTokenDrmVecima(tokenDrm: string) {
    this.VecimaTokenDrm = tokenDrm;
  }

  /*  return token drm generate vecima */
  getTokenDrmVecima() {
    return this.VecimaTokenDrm;
  }

  /* set token streaming Vecima */
  setTokenStreamingVecima(tokenStreaming: string) {
    this.vecimaTokenStreaming = tokenStreaming;
  }

  /* get token Streaming Vecima */
  getTokenStreamingVecima() {
    return this.vecimaTokenStreaming;
  }

  /* set session Id vecima */
  setSessionIdVecima(idSession: string) {
    this.vecimaSessionId = idSession;
    this.storage.setVecimaSession(idSession);
  }


  /* get session Id vecima */
  getSessionIdVecima() {
    return this.vecimaSessionId;
  }

  /* reset value Id veciam  */
  resetValuesVecima() {
    this.vecimaSessionId = null;
    this.vecimaDateExpiration = null;
    this.vecimaIpUser = null;
    this.vecimaValueQ = null;
    this.vecimaTokenStreaming = null;
    this.VecimaTokenDrm = null;
    this.vecimaVersion = null;
  }

  /* set date expiration */
  setDateExpirationVecima(date: string) {
    this.vecimaDateExpiration = date;
  }

  /* get date expiration */
  getDateExpirationVecima() {
    return this.vecimaDateExpiration;
  }

  /* set ip user vecima */
  setIpUserVecima(ip: string) {
    this.vecimaIpUser = ip;
  }

  /* get ip user vecima */
  getIpUserVecima() {
    return this.vecimaIpUser;
  }


  /* set value q vecima */
  setQvecima(obj: any) {
    this.vecimaValueQ = obj;
  }

  getQvecima() {
    return this.vecimaValueQ;
  }

  /* ser version Vecima */
  setVersionVecima(version: string) {
    this.vecimaVersion = version;
  }

  /* get version Vecima */
  getVersionVecima() {
    return this.vecimaVersion;
  }

}
