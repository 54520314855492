import {Component, Input, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {Storage} from 'src/app/core/storage/storage';
import {NavController} from '@ionic/angular';
import {EVENT_TRACK_072} from 'src/app/config/analytics/events-track';
import {SegmentAnalytics} from 'src/app/core/providers/segment-analytics';
import {IS_ADAPTABLE} from 'src/app/config/constants';
import * as moment from 'moment';
import { VideoStreamingService } from 'src/app/services/video-streaming.service';
import { RecommendedService } from 'src/app/services/recommended.service';
import { EventDetailService } from 'src/app/services/event-detail.service';
import { ENTITLEMENT } from 'src/app/config/settings';
import { Capacitor } from '@capacitor/core';
import { DevicesService } from 'src/app/services/devices.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-my-recommended',
  templateUrl: './my-recommended.component.html',
  styleUrls: ['./my-recommended.component.scss'],
})
export class MyRecommendedComponent implements OnInit,OnChanges {
  @Input() data: any;
  @Input() title: string;
  recommended = [];
  showRecommended = false;
  isAdaptable = IS_ADAPTABLE;
  loadingImg = '../../../../assets/graphics/pictures/load-img.png';
  isWeb = Capacitor.getPlatform() === 'web';

  constructor(
    private storage: Storage,
    private navController: NavController,
    private segmentAnalytics: SegmentAnalytics,
    private videoStreamingService: VideoStreamingService,
    private recommendedService: RecommendedService,
    private eventDetailService: EventDetailService,
    private devicesService: DevicesService
  ) { }

  async ngOnInit() {
    try {
      const data = this.data;
      this.recommended = (data.length > 0) ? data : [];
      this.showRecommended = (data.length);
    } catch (error) {
    }
  }

  ngOnChanges(change: SimpleChanges): void {
    this.data = [];
    const results = change.data;

    if (results) {
      this.recommended = results.currentValue;
      this.showRecommended = (results.currentValue.length > 0);
    }
  }

  async viewDetail(event): Promise<void> {
    const authenticated = this.storage.getAccessToken();
    let route: string;
    const segmentData = {
      championship_id: event.championship_id,
      championship_name: event.championship_id,
      airdate: event.createdAt,
      sport_name: event.sport_name,
      video_title: event.video_title,
      journey: 'player',
      content_id: event.contentful_id,
      home_team_id: event.home_team_id,
      home_team: event.home_team,
      away_team_id: event.away_team_id,
      away_team: event.away_team,
      total_length: event.total_length,
      video_position: 0,
      session_id: event.session_id,
      video_quality: event.video_quality,
      content_category: event.content_category,
      content_type: this.defineContentTypeSegment(event.end_date),
    };

    await this.segmentAnalytics.eventTrack(EVENT_TRACK_072, segmentData);
    this.videoStreamingService.setEventCategory(event.content_category);
    let eventType = '';
    if (event.content_type === 'gameSchedules') {
      eventType = 'game';
    } else {
      eventType = 'highlights';
    }
    //let streamingType = await this.eventDetailService.getStreamType(event.contentful_id, eventType);
    route = `/detail/${event.contentful_id}/${eventType}`;
    this.storage.setPreviousRoute(route);
 /*    if (!authenticated && streamingType != ENTITLEMENT.FREE_TYPE) {
      if (this.isAdaptable) {
        route = '/-/iniciar-sesion';
      } else {
        route = '/-/iniciar-sesion-tv';
      }
    } */
    this.navController.navigateForward(route);
  }

  private defineContentTypeSegment(end_date: Date): string {
    let contentTypeFixed = '';
    const endDate = end_date;
    if (endDate) {
      const now = moment();
      const deadline = moment(endDate);

      const isAfter = moment(deadline).isAfter(now);
      if (isAfter) {
        contentTypeFixed = 'live';
      } else {
        contentTypeFixed = 'replay';
      }
    } else {
      contentTypeFixed = 'highlight';
    }
    return contentTypeFixed;
  }

  handleScrollSegment(qty: number): void {
    let segmentAux = document.getElementById('myRecommended_segment');
    segmentAux.scrollLeft = (segmentAux.scrollLeft + qty);
  }

}
