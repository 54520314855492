import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {SEGMENT} from '../../config/scripts';
import {Location} from '../interfaces/location';
import {Storage} from '../storage/storage';

@Injectable({
  providedIn: 'root'
})
export class ScriptRegisterUtil {
  private renderer: Renderer2;

  constructor(
    private storage: Storage,
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public register(): void {
    this.registerSegmentScript();
  }

 

  /* Dinamically add segment scripts to index.html */
  private registerSegmentScript(): void {
    const location: Location = this.storage.getLocation();
    if (location) {
      const url = `${SEGMENT}${location.code}`;
      const script = this.renderer.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      this.renderer.appendChild(this.document.head, script);
    }
  }


}
