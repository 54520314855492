import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GET_CLOUDSEARCH_RESULTS, GET_ENTRIES_FINDER } from '../config/endpoints';
import { MIDDLEWARE_HOST } from 'src/environments/environment';
import { SearchResult } from '../core/interfaces/search-result';
import { Storage } from '../core/storage/storage';
import {SegmentAnalytics} from '../core/providers/segment-analytics';
import {EVENT_TRACK_075} from '../config/analytics/events-track';
import * as moment from "moment";
@Injectable({
  providedIn: 'root'
})
export class SearchService {
  countryCode = '';
  page = 1;
  limit = 10;

  constructor(
    private http: HttpClient,
    private storage: Storage,
    private segmentAnalytics: SegmentAnalytics
  ) {
    const {code} = this.storage.getLocation();
    this.countryCode = code;
   }

  /* Get events based on search term */
  // eslint-disable-next-line @typescript-eslint/ban-types
  public async getResults(assetDomain: any, search: String = ''): Promise<SearchResult[]> {
    let results: SearchResult[] = [];
    let arrayAux = [];

    if (search == '') return results;
    try {
      const response: any = await this.http.get(`${GET_CLOUDSEARCH_RESULTS}/search-document?search=${search}`).toPromise();
      if (response.result.hits.found > 0){
        this.segmentAnalytics.eventTrack(EVENT_TRACK_075, {count: response.result.hits.found, journey: 'user interface'}).then();
        arrayAux = response.result.hits.hit;
        arrayAux.forEach((item) => {
          let startDate = new Date(Date.parse(item.fields.start_date[0]));
          let objectAux : SearchResult = {
            id : item.id,
            title : item.fields.title[0],
            schedule : item.fields.start_date ? this.getFormattedSchedule(startDate) : '',
            imgUrl : this.getImage(item, assetDomain),//`https:${item.fields.horizontal_thumbnails[0]}`,
            live: '',
            contentType : item.fields.contenttype[0]
          }
          results.push(objectAux);
        });
      } else {
        results = [];
      }
    } catch (error) {
      console.log(error);
    }
    return results;
  }

  /* Get formatted schedule */
  private getFormattedSchedule(startDate: Date) {
    let date = startDate.getDate();
    let month = startDate.getMonth();
    let year = startDate.getFullYear();
    let formattedDate = ""
    if (startDate){
      formattedDate = `${date}/${month + 1}/${year}`;
    }
    return formattedDate;
  }

  /* Get image url from Amazon S3 or Contentful */
  private getImage(obj, assetDomain){
    let domain = assetDomain?.search ? assetDomain.search : assetDomain.default
    if(assetDomain?.status && assetDomain.status === true && !obj?.fields.cover[0].includes('images.tigocloud.net')){
      return domain + obj?.fields.cover[0];
    }else
      return `https:${obj.fields.cover[0]}`
  }
}
