/**
 * Constants for Segment events.
 *
 * @remarks - The correlation of these constants are determined by a previously provided excel list.
 **/

export const EVENT_TRACK_001 = 'Splash Loaded';
export const EVENT_TRACK_002 = 'Home Clicked';
export const EVENT_TRACK_003 = 'Search Clicked';
export const EVENT_TRACK_004 = 'Anonymous Visit Clicked';
export const EVENT_TRACK_005 = 'Link Clicked';
export const EVENT_TRACK_006 = 'Login Loaded';
export const EVENT_TRACK_007 = 'Login Clicked';
export const EVENT_TRACK_008 = 'Login Method Selected';
export const EVENT_TRACK_009 = 'Login Account Selector Loaded';
export const EVENT_TRACK_010 = 'Login Account Selector Clicked';
export const EVENT_TRACK_012 = 'Login Succeeded';
export const EVENT_TRACK_013 = 'Login Failed';
export const EVENT_TRACK_014 = 'Logout Attempted';
export const EVENT_TRACK_015 = 'Logout Succeeded';
export const EVENT_TRACK_016 = 'Logout Failed';
// export const EVENT_TRACK_017 = 'Login ID Edit Clicked';
// export const EVENT_TRACK_018 = 'Login Invitation Loaded';
// export const EVENT_TRACK_019 = 'Login Invitation Clicked';
export const EVENT_TRACK_020 = 'Registration Clicked';
export const EVENT_TRACK_021 = 'Registration Method Selected';
// export const EVENT_TRACK_022 = 'Registration Loaded';
// export const EVENT_TRACK_023 = 'Registration Personal Details Completed';
 export const EVENT_TRACK_024 = 'Registration Succeeded';
// export const EVENT_TRACK_025 = 'Registration Failed';
// export const EVENT_TRACK_026 = 'Registration User Validation Attempted';
// export const EVENT_TRACK_027 = 'Registration User Validation Failed';
// export const EVENT_TRACK_028 = 'Registration User Validation Skipped';
// export const EVENT_TRACK_029 = 'Registration User Validation Completed';
// export const EVENT_TRACK_030 = 'Password Forgot Clicked';
// export const EVENT_TRACK_031 = 'Password Set Loaded';
// export const EVENT_TRACK_032 = 'Password Set Failed';
// export const EVENT_TRACK_033 = 'Password Set Succeeded';
export const EVENT_TRACK_034 = 'Promotion Viewed';
export const EVENT_TRACK_035 = 'Promotion Clicked';
// export const EVENT_TRACK_036 = 'News Viewed';
export const EVENT_TRACK_037 = 'News Clicked';
export const EVENT_TRACK_038 = 'Match Not Started Notified';
export const EVENT_TRACK_039 = 'Sharing Failed';
export const EVENT_TRACK_040 = 'Video Started';
export const EVENT_TRACK_041 = 'Video Finished';
export const EVENT_TRACK_042 = 'Video Resumed';
export const EVENT_TRACK_043 = 'Video Paused';
export const EVENT_TRACK_044 = 'Video Failed';
export const EVENT_TRACK_045 = 'Video Resolution Change Selected';
export const EVENT_TRACK_046 = 'Share Button Clicked';
// export const EVENT_TRACK_047 = 'Schedule List Viewed';
// export const EVENT_TRACK_048 = 'Matchweek Clicked';
// export const EVENT_TRACK_049 = 'Match Clicked';
// export const EVENT_TRACK_050 = 'Stats Championship List Viewed';
export const EVENT_TRACK_051 = 'Scores Menu Clicked';
export const EVENT_TRACK_052 = 'Stats Menu Clicked';
// export const EVENT_TRACK_053 = 'Stats Menu Selection Clicked';
export const EVENT_TRACK_054 = 'Stats Submenu Clicked';
// export const EVENT_TRACK_055 = 'Stats Submenu  Selection Clicked';
// export const EVENT_TRACK_056 = 'Stats Match Clicked';
export const EVENT_TRACK_057 = 'Settings Clicked';
export const EVENT_TRACK_058 = 'Notifications Clicked';
export const EVENT_TRACK_059 = 'Notifications Selected';
export const EVENT_TRACK_060 = 'Notifications Activate Clicked';
export const EVENT_TRACK_061 = 'Notifications Deactivate Clicked';
// export const EVENT_TRACK_062 = 'Alerts Clicked';
export const EVENT_TRACK_063 = 'Alerts Activate Clicked';
// export const EVENT_TRACK_064 = 'Alerts Dectivate Clicked';
export const EVENT_TRACK_065 = 'Subscription Clicked';
export const EVENT_TRACK_066 = 'Preferences  Clicked';
export const EVENT_TRACK_067 = 'Payment Info Clicked';
export const EVENT_TRACK_068 = 'Settings Loaded';
export const EVENT_TRACK_069 = 'Reset Password Attempted';
export const EVENT_TRACK_070 = 'Reset Password Succeeded';
export const EVENT_TRACK_071 = 'Reset Password Failed';
export const EVENT_TRACK_072 = 'Video Clicked';
export const EVENT_TRACK_073 = 'Video Closed';
export const EVENT_TRACK_074 = 'Favourites Clicked';
export const EVENT_TRACK_075 = 'Search Results Loaded';
export const EVENT_TRACK_076 = 'Message Displayed';
export const EVENT_TRACK_077 = 'Player Selected';
export const EVENT_TRACK_078 = 'Device Activate Clicked';
export const EVENT_TRACK_079 = 'Device Activate Succeeded';
export const EVENT_TRACK_080 = 'Device Activate Failed';
export const EVENT_TRACK_081 = 'Device Removal Clicked';
export const EVENT_TRACK_082 = 'Device Removal Succeeded';
export const EVENT_TRACK_083 = 'Device Removal Failed';
export const EVENT_TRACK_084 = 'Device Deactivate Clicked';
export const EVENT_TRACK_085 = 'Device Deactivate Succeeded';
export const EVENT_TRACK_086 = 'Device Deactivate Failed';
export const EVENT_TRACK_087 = 'Subscription Billing Story Clicked';
export const EVENT_TRACK_088 = 'Subscription Billing Story Displayed';
export const EVENT_TRACK_089 = 'Credit Card Edit Info Clicked';
export const EVENT_TRACK_090 = 'Payment Info Selected';
export const EVENT_TRACK_091 = 'Add New Credit Card';
export const EVENT_TRACK_092 = 'Credit Card Number Entered';
export const EVENT_TRACK_093 = 'New Credit Card Added';
export const EVENT_TRACK_094 = 'Delete Credit Card Attempted';
export const EVENT_TRACK_095 = 'Delete Credit Card Selected';
export const EVENT_TRACK_096 = 'Cancel Subscription Attempted';
export const EVENT_TRACK_097 = 'Cancel Subscription Clicked';
export const EVENT_TRACK_098 = 'Cancel Subscription Failed';
export const EVENT_TRACK_099 = 'Cancel Subscription Succeeded';
export const EVENT_TRACK_100 = 'Subscription Attempted';
export const EVENT_TRACK_101 = 'Credit Card Save Confirmed';
export const EVENT_TRACK_102 = 'Tigo Sports Membership Displayed';
export const EVENT_TRACK_103 = 'Subscription Offer Clicked';
export const EVENT_TRACK_104 = 'Carrier Selected';
export const EVENT_TRACK_105 = 'Order Failed';
export const EVENT_TRACK_106  = 'Order Completed';
export const EVENT_TRACK_107  = 'Go Back Clicked';
export const EVENT_TRACK_110  = 'Payment Succeeded';
export const EVENT_TRACK_111  = 'Login Attempted';
export const EVENT_TRACK_112  = 'Network Loss';
export const EVENT_TRACK_113 = 'orderCompleted';
export const EVENT_TRACK_114 = 'orderCancelled';
export const EVENT_TRACK_115 = 'Product Clicked';
export const EVENT_TRACK_116 = 'Screencast Selected';
export const EVENT_TRACK_117 = 'Product List Load Failed';
export const EVENT_TRACK_118 = 'Product List Viewed';
export const EVENT_TRACK_119 = 'Pin Login Submitted';
export const EVENT_TRACK_120 = 'Pin Login Succeeded';
export const EVENT_TRACK_121 = 'Pin Login Failed';
export const EVENT_TRACK_122 = 'Checkout Started';
export const EVENT_TRACK_123 = 'Order Details Loaded';
export const EVENT_TRACK_124 = 'Checkout Attempted';
export const EVENT_TRACK_125 = 'Order Cancelled';
export const EVENT_TRACK_126 = 'Credit Card Selected';
export const EVENT_TRACK_127 = 'Terms And Conditions Clicked';
//export const EVENT_TRACK_128 = 'Credit Card Edit Clicked';
export const EVENT_TRACK_129 = 'Credit Card Save Failed';
// export const EVENT_TRACK_130 = 'Recurring Payment Invite Loaded';
// export const EVENT_TRACK_131 = 'Recurring Payment Invite Clicked';
export const EVENT_TRACK_132 = 'Recurring Payment Subscription Attempted';
export const EVENT_TRACK_133 = 'Recurring Payment Subscription Submitted';
export const EVENT_TRACK_134 = 'Recurring Payment Subscription Failed';
export const EVENT_TRACK_135 = 'Recurring Payment Subscription Succeeded';
export const EVENT_TRACK_136 = 'Profile Clicked';
export const EVENT_TRACK_137 = 'Profile Loaded';
export const EVENT_TRACK_138 = 'Profile Load Failed';
export const EVENT_TRACK_139 = 'Country Selected';
export const EVENT_TRACK_140 = 'League Selected';
export const EVENT_TRACK_141 = 'Team Selected';
export const EVENT_TRACK_142 = 'Device Active Clicked';
export const EVENT_TRACK_143 = 'Device Active Succeeded';
export const EVENT_TRACK_144 = 'Device Active Failed';
export const EVENT_TRACK_145 = 'Device Admin Modal Displayed';
export const EVENT_TRACK_146 = 'Device Admin Modal Option Clicked';
export const EVENT_TRACK_147 = 'Device Admin Loaded';
export const EVENT_TRACK_148 = 'Device Admin Option Clicked';
export const EVENT_TRACK_149 = 'Associate Account Clicked';
export const EVENT_TRACK_150 = 'Associate Account Type Continue Clicked';
export const EVENT_TRACK_151 = 'Associate Account Succeeded';
export const EVENT_TRACK_152 = 'Delete User Attempted';
export const EVENT_TRACK_153 = 'Delete User Confirmation Loaded';
export const EVENT_TRACK_154 = 'Delete User Confirmation Clicked';
export const EVENT_TRACK_155 = 'Delete User Word Validation Loaded';
export const EVENT_TRACK_156 = 'Delete User Word Validation Clicked';
export const EVENT_TRACK_157 = 'Delete User Succeeded';
// export const EVENT_TRACK_145 = 'Verification Code Submitted';




export const EVENT_TRACK_158 = 'League Selected';
export const EVENT_TRACK_159 = 'Team Selected';
export const EVENT_TRACK_160 = 'Favorite Team Clicked';
export const EVENT_TRACK_161 = 'Favorite Team Option Clicked';
export const EVENT_TRACK_162 = 'Explore Team Loaded';

export const EVENT_TRACK_163 = 'Hardware Acceleration Disabled Alert';