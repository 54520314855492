import {Component, Input, OnChanges, OnInit, SimpleChanges, HostListener, OnDestroy} from '@angular/core';
import {OtherContentService} from 'src/app/services/other-content.service';
import {Storage} from 'src/app/core/storage/storage';
import {NavController, Platform} from '@ionic/angular';
import {EVENT_TRACK_072} from 'src/app/config/analytics/events-track';
import {SegmentAnalytics} from 'src/app/core/providers/segment-analytics';
import {IS_ADAPTABLE} from 'src/app/config/constants';
import { VideoStreamingService } from 'src/app/services/video-streaming.service';
import { EventDetailService } from 'src/app/services/event-detail.service';
import { ENTITLEMENT } from 'src/app/config/settings';
import { Capacitor } from '@capacitor/core';
import { DevicesService } from 'src/app/services/devices.service';
import { AuthService } from 'src/app/services/auth.service';
import { TextManagementV2Service } from 'src/app/services/text-management-v2.service';

@Component({
  selector: 'app-other-content',
  templateUrl: './other-content.component.html',
  styleUrls: ['./other-content.component.scss'],
})
export class OtherContentComponent implements OnInit, OnChanges, OnDestroy {
  textsV2: any;
  assetDomain: any;
  @Input() data: any;
  @Input() title: string;
  @Input() id_category: any;
  @Input() id_ev:any;
  @Input() isMoreContent:boolean;
  @Input() banneradvertising: any;
  @Input() queryParams: any;
  others = [];
  showOtherContent = false;
  isAdaptable = IS_ADAPTABLE;
  loadingImg = '../../../../assets/graphics/pictures/load-img.png';
  isWeb = Capacitor.getPlatform() === 'web';
  pageNumber = 2;
  showafter = false;
  temporaryobj = [];
  banneravailable: boolean = true;
  widthbanner=0;
  heightbanner=0;
  marginbanner=0;
  iddiv = 'div-gpt-ad-1661900411589-0';
  slot : any;
  isemptybanner: boolean = true;

  constructor(
    private otherContentService: OtherContentService,
    private platform: Platform,
    private storage: Storage,
    private navController: NavController,
    private segmentAnalytics: SegmentAnalytics,
    private videoStreamingService: VideoStreamingService,
    private eventDetailService: EventDetailService,
    private devicesService: DevicesService,
    private textManagementV2Service: TextManagementV2Service
  ) {
  }

  async ngOnInit() {
    this.textsV2 = await this.textManagementV2Service.getTexts();
    this.assetDomain = this.textsV2?.ASSET_DOMAIN;
    try {
      let data;
      if(this.isMoreContent){
        data = await this.otherContentService.getEvents(1, this.assetDomain, this.id_category, this.queryParams);
      }else{
        data = this.data;
      }
      if (data.length > 0) {
        this.others = (data.length > 0) ? data : [];
        this.showOtherContent = (data.length);
      }
    } catch (error) {
    }
  }

  @HostListener('window:scroll', ['$event'])
  async doSomethingOnScroll(event) {
    const totalwidth = Math.round(event.target.offsetWidth + event.target.scrollLeft);
    if (totalwidth === event.target.scrollWidth) {
      const newArr = await this.otherContentService.getEvents(this.pageNumber, this.assetDomain, this.id_category, this.queryParams);
      this.pageNumber++;
      this.others.push(...newArr);
    }
  }

  ngOnChanges(change: SimpleChanges): void {
    this.data = [];
    const results = change.data;
    const banner = change.banneradvertising;

    if (results) {
      this.others = results.currentValue;
      this.showOtherContent = (results.currentValue.length > 0);
      this.pageNumber = 2;
      this.showafter = this.showOtherContent;
      this.temporaryobj = this.others;
    }

    if (this.showafter) {
      this.showOtherContent = this.showafter;
      this.others = this.temporaryobj;
    }

    if (this.others.length > 0 && this.showOtherContent) {
      if (banner && this.isAdaptable) {
        this.loadScript(banner.currentValue);
      }
    }
  /*//  this.data = [];
  //  const results = change.data;
    this.others = change.data?.currentValue;

    if (this.others) {
    //  this.others = results.currentValue;
      this.showOtherContent = (this.others.length > 0);
    }*/
  }

  ngOnDestroy(): void {
    if (this.isAdaptable) {
      this.Removeslot();
    }
  }

  /* If user is authenticated then redirect to detail page, else redirect to login page */
  async viewDetail(event): Promise<void> {
    const authenticated = this.storage.getAccessToken();
    let route: string;
    const segmentData = {
      championship_id: event.championship_id,
      championship_name: event.championship_id,
      airdate: event.createdAt,
      sport_name: event.sport_name,
      video_title: event.video_title,
      journey: 'player',
      content_id: event.contentful_id,
      home_team_id: event.home_team_id,
      home_team: event.home_team,
      away_team_id: event.away_team_id,
      away_team: event.away_team,
      total_length: event.total_length,
      video_position: 0,
      session_id: event.session_id,
      video_quality: event.video_quality,
      content_category: event.content_category,
      content_type: 'highlights',
    };
    await this.segmentAnalytics.eventTrack(EVENT_TRACK_072, segmentData);
    this.videoStreamingService.setEventCategory(event.content_category);
    let eventType = '';
    if (event.content_type === 'gameSchedules') {
      eventType = 'game';
    } else {
      eventType = 'highlights';
    }
    //let streamingType = await this.eventDetailService.getStreamType(event.contentful_id, eventType);
    route = `/detail/${event.contentful_id}/${eventType}`;
    this.storage.setPreviousRoute(route);
/*     if (!authenticated && streamingType != ENTITLEMENT.FREE_TYPE) {
      if (this.isAdaptable) {
        route = '/-/iniciar-sesion';
      } else {
        route = '/-/iniciar-sesion-tv';
      }
    } */
    this.navController.navigateRoot(route);
  }

  handleScrollSegment(qty: number): void {
     let segmentAux = document.getElementById(this.id_ev);
    segmentAux.scrollLeft = (segmentAux.scrollLeft + qty);
  }

  moreContent():void{
     this.navController.navigateForward("otros-contenidos")
  }

  async loadScript(banners) {
    const platform = Capacitor.getPlatform();
    if (banners[platform]) {
      let bannervalidate: any = [];
      if (this.platform.is('mobileweb') || this.platform.is('mobile')) {
        bannervalidate = banners.android;
      } else {
        bannervalidate = banners[platform];
      }
      const databanner: any = bannervalidate.length > 0 ? bannervalidate.find(E => E.type === 'OtrosContenidos') : null;
      if (databanner && databanner?.enable) {
        let slottarget;
        this.banneravailable = databanner?.enable;
        try {

          const googletag: any = window['googletag'] || {};
          if (window['googletag'] && googletag.apiReady) {
            googletag.cmd = googletag.cmd || [];
           // this.slot = googletag.pubads().getSlots();
            const divbanner = document.getElementById(this.iddiv);
            if (!this.slot && divbanner) {
              let that = this;
              googletag.cmd.push(() => {
                slottarget =  googletag.defineSlot(databanner.banner, [[databanner.width, databanner.height]], this.iddiv)
                  .addService(googletag.pubads());
                googletag.pubads().enableSingleRequest();
                googletag.pubads().collapseEmptyDivs(true, true);
                googletag.enableServices();
              });
              googletag.display(this.iddiv);

              // event is fired where code is injected into a slot
              googletag.pubads().addEventListener('slotRenderEnded',
                function(event) {
                  const slot = event.slot;
                  if (slot === slottarget) {
                    that.slot = slottarget;
                    that.isemptybanner = event.isEmpty;
                    if (event.isEmpty) {
                      that.marginbanner = 0;
                      that.banneravailable = false;
                    }
                  }
                }
              );
              this.widthbanner = databanner.width;
              this.heightbanner = databanner.height;
              this.marginbanner = 1;
            } else {
              if (this.slot) {
                if (!this.isemptybanner) {
                  this.marginbanner = 1;
                  this.banneravailable = true;
                } else {
                  this.marginbanner = 0;
                  this.banneravailable = false;
                }
              } else {
                this.marginbanner = 0;
                this.banneravailable = false;
              }
            }
          }
        } catch (e) {
          console.log(e);
          this.banneravailable = false;
          this.marginbanner = 0;
        }
      } else {
        this.banneravailable = false;
      }
    }
  }


// RemoveSlot

  Removeslot() {
    const googletag: any = window['googletag'] || {};
    if (window['googletag'] && googletag.apiReady) {
      googletag.cmd = googletag.cmd || [];
      if (this.slot) {
        googletag.destroySlots([this.slot]);
      }
    }
  }

}
