import { Injectable } from '@angular/core';
import { registerPlugin } from '@capacitor/core';
import {VideoStreamingService} from './video-streaming.service';
import {PlayerService} from './player.service';
import {Subject} from 'rxjs';
import {Storage} from '../core/storage/storage';
import {TextManagementService} from './text-management.service';
import {DevicesService} from './devices.service';
import {LoadingService} from './loading.service';
import { TextManagementV2Service } from './text-management-v2.service';
const PluginChromecast = registerPlugin<any>('PluginChromecast');
export default PluginChromecast;
@Injectable({
  providedIn: 'root'
})
export class ChromecastService {
  datacast: any = [];
  datamediachomecast;
  iscontroldevice = false;
  iscountDeactiveUser = 0;
  onUserConcurrent: any;
  url = '';
  licenseDrm = '';
  urlLicense = '';
  token = ''
  private addItemDevice = new Subject<boolean>();
  addItemDevice$ = this.addItemDevice.asObservable();


  constructor(private videostreaming: VideoStreamingService,
              private playerservice: PlayerService,
              private storage: Storage,
              private textManagementService: TextManagementService,
              private textManagementV2Service: TextManagementV2Service,
              private deviceService: DevicesService,
              private loadingService: LoadingService,) {}


  addItemDeviceControl(item: boolean) {
    this.addItemDevice.next(item);
  }


  async Initializatedplugin(appId) {
    await PluginChromecast.initialize({appId});
  }

  async StreamChromeCast(mediaUrl, title, subtitle, token, userinfo, islive: boolean, licenseDrm, urlLicense, isVecima: boolean) {
    const response = await PluginChromecast.requestSession(
      {mediaUrl, title, subtitle, token, userinfo, islive, licenseDrm, urlLicense, isVecima});
    this.setDataMediaChomecast(response);
  }

  async StopAutomaticChromecast(plugindata) {
    await PluginChromecast.sessionStop(plugindata);
    await this.RemoveDataMediaChromecast();
  }

  /* tranmission for chromecast */

  setTransmitionChromecast(data: any): void {
    this.datacast = data;
  }

  getTransmitionChromecast() {
    return this.datacast;
  }

  setDataMediaChomecast(data: any): void {
    this.datamediachomecast = data;
    this.storage.setChromecastBody(data);
    this.storage.setIsChromecast('true');
  }

  getDataMediaChomecast() {
    return this.datamediachomecast;
  }

  RemoveDataMediaChromecast() {
    this.datamediachomecast = null;
    this.storage.removeChromecastBody();
    this.storage.removeIsChromecast();
    this.url = '';
    this.licenseDrm = '';
    this.urlLicense = '';
    this.token = ''
  }

  setDeviceControl(value: boolean): void {
    this.iscontroldevice = value;
    this.addItemDeviceControl(value);
  }

  getDeviceControl() {
    return this.iscontroldevice;
  }


  async validateUrlStream(transmission: any) {
    this.loadingService.setLoading(true, 'load-VecimaGenerate');
    let result;
    const response: any = await this.videostreaming.programmingValidations(transmission, true);
    if (response?.avilable && response?.isApproved) {
      try {
        let isValidaVecima: boolean = false;
        const texts = await this.textManagementService.getTexts();
        const textsV2 = await this.textManagementV2Service.getTexts();
        this.onUserConcurrent = textsV2?.USERCONCURRENTON;
        const isEnableVecima = textsV2?.VECIMAOPTIONS?.vecimaGenerateTokensOn;
        const isdrm = this.playerservice.getIsDRM();
        let userData: any = this.storage.getUser();
        let userData2 = userData ? (userData.phone_number ? userData.phone_number : userData.email) : 'anon';
        if (isdrm) {
          const drmEndpoints = texts.VIDEO_STREAMING;
          this.urlLicense = drmEndpoints.wServer;
          if (isEnableVecima && !this.storage.getChromecastBody()) {
             isValidaVecima =  await this.playerservice.processVecimaStructure(textsV2);
            if (isValidaVecima) {
              let urlGenerate = `${this.playerservice.getStreamingUrlDash()}?t=${encodeURIComponent(this.playerservice.getTokenStreamingVecima())}&s=${encodeURIComponent(this.playerservice.getSessionIdVecima())}&c=${encodeURIComponent(this.playerservice.getIpUserVecima())}&e=${encodeURIComponent(this.playerservice.getDateExpirationVecima())}&v=${encodeURIComponent(this.playerservice.getVersionVecima())}&u=${encodeURIComponent(userData?.id)}`;
              this.url = this.playerservice.addQueryStringVecima(urlGenerate);
              this.licenseDrm = this.playerservice.getTokenDrmVecima();
              this.token = this.playerservice.getTokenStreamingVecima();
            }
          } else {
            this.token = this.playerservice.getToken();
            this.url = this.playerservice.getStreamingUrlDash()+'?tts='+this.token+'&cts='+userData2;
            this.licenseDrm = await this.playerservice.getTokenPlayer('widevine', this.getTransmitionChromecast());
          }
        } else {
          if (isEnableVecima && !this.storage.getChromecastBody()) {
             isValidaVecima  =  await this.playerservice.processVecimaStructure(textsV2);
            if (isValidaVecima) {
              let urlGenerate = `${this.playerservice.getStreamingUrl()}?t=${encodeURIComponent(this.playerservice.getTokenStreamingVecima())}&s=${encodeURIComponent(this.playerservice.getSessionIdVecima())}&c=${encodeURIComponent(this.playerservice.getIpUserVecima())}&e=${encodeURIComponent(this.playerservice.getDateExpirationVecima())}&v=${encodeURIComponent(this.playerservice.getVersionVecima())}&u=${encodeURIComponent(userData?.id)}`;
              this.url = this.playerservice.addQueryStringVecima(urlGenerate);
              this.token = this.playerservice.getTokenStreamingVecima();
            }
          } else {
            this.token = this.playerservice.getToken();
            this.url = this.playerservice.getStreamingUrl()+'?tts='+this.token+'&cts='+userData2;
          }
        }
        this.iscountDeactiveUser = 0;
        this.loadingService.setLoading(false, 'load-VecimaGenerate');
        if (isEnableVecima) {
          if (isValidaVecima || this.url !== '') {
            await this.StreamChromeCast(this.url, transmission?.video_title, 'FHD', this.token, userData2, transmission?.isLive, this.licenseDrm, this.urlLicense, isEnableVecima);
          }
        } else {
          await this.StreamChromeCast(this.url, transmission?.video_title, 'FHD', this.token, userData2, transmission?.isLive, this.licenseDrm, this.urlLicense, false);
        }
        result = true;
      } catch (e) {
        console.log('error', e);
      }
    } else {
      result = false;
      this.loadingService.setLoading(false, 'load-VecimaGenerate');
      this.deviceService.setConectionDeactive().then();
    }
    return result;
  }


  RemoveStreamingChromecast() {
    this.playerservice.removeStreaming();
  }


  /* events for chromecast */
  switchEventsForChromecast(event) {
    if (event) {
      const data = {
        data: {},
        event: '',
      };
      switch (event.event) {
        case 'cancel': {
          data.data = {off: true};
          data.event = event.event;
          const datamediachromecast = this.getDataMediaChomecast() ? this.getDataMediaChomecast() : this.storage.getChromecastBody();
          if (!datamediachromecast && this.storage.getIsChromecast() !== 'true') {
            this.isDeactiveSesionUser().then();
            this.playerservice.deleteSessionIdStreaming().then();
            this.RemoveDataMediaChromecast();
          }
          break;
        }
        case 'stop': {
          data.data = {off: true, stopper: true};
          data.event = event.event;
          this.isDeactiveSesionUser().then();
          this.playerservice.deleteSessionIdStreaming().then();
          break;
        }
        case 'stopAutomatic': {
          data.data = {off: true};
          data.event = event.event;
          this.isDeactiveSesionUser().then();
          this.playerservice.deleteSessionIdStreaming().then();
          break;
        }
      }
      return data;
    }
  }

  async isDeactiveSesionUser() {
    if (this.onUserConcurrent?.status && this.onUserConcurrent?.status === true) {
      if (this.iscountDeactiveUser === 0) {
        // this.loadingService.setLoading(true, 'setConnectionDeactiveChromecast');
        try {
          this.setDeviceControl(false);
          const response: any = await this.deviceService.setConectionDeactive();
        } catch (e) {
          console.error(e);
        }
        this.iscountDeactiveUser++;
      }
    }
  }





}
