import { gql } from "apollo-angular";
import { environment } from '../../environments/environment';

export const MUTATION_CONNECTION = gql`mutation
PutConnection($deviceId: String!, $userId: String!) {
  putConnection (
      deviceId: $deviceId,
      userId: $userId
  ) {
      deviceId
      status
      userId
  }
}
`;


export const GET_DEVICE_BY_ID = gql`query ReadDeviceById($deviceId: String!) {
  readDeviceById(deviceId: $deviceId){
    limitAccountManager
    limitViewDevice
    item {
      accessToken
      accounts {
        billingType
        businessCategory
        businessUnit
        linkedHomeids
        linkedMsisdns
        nationalId
        nationalIdType
        planId
        planName
        planType
        primarySubscriberId
        registrationDate
      }
      address {
        city
        ip
        state
      }
      closedSession
      master
      country
      deviceId
      enableView
      recentActivity
      firstSession
      status
      refreshToken
      systemInfo {
        operatingSystem
        browser
      }
      userId
    }
  }
}
`;

export const GET_DEVICES_BY_USERID = gql`query ReadAllDevice {
  readAllDevice {
    limitAccountManager
    limitViewDevice
    items {
        accessToken
        accounts {
            billingType
            businessCategory
            businessUnit
            linkedHomeids
            linkedMsisdns
            nationalId
            nationalIdType
            planId
            planName
            planType
            primarySubscriberId
            registrationDate
        }
        address {
            city
            ip
            state
        }
        closedSession
        master
        country
        deviceId
        enableView
        recentActivity
        firstSession
        status
        refreshToken
        systemInfo {
            operatingSystem
            browser
        }
        userId
    }
  }
}
`;

export const GET_ALL_DEVICE_ACTIVE = gql `query MyQuery {
  readAllDeviceActive {
    limitAccountManager
    limitViewDevice
    items {
        accessToken
        accounts {
            billingType
            businessCategory
            businessUnit
            linkedHomeids
            linkedMsisdns
            nationalId
            nationalIdType
            planId
            planName
            planType
            primarySubscriberId
            registrationDate
        }
        address {
            city
            ip
            state
        }
        contentView
        closedSession
        master
        country
        deviceId
        enableView
        recentActivity
        firstSession
        status
        refreshToken
        systemInfo {
            operatingSystem
            browser
        }
        userId
    }
  }
}
`;


export const GET_NUMBER_MASTER_ACCOUNT =  gql `query MyQuery {
  readDeviceManager{
    limitAccountManager
    limitViewDevice
    activeAccountManager
    item {
        accessToken
        accounts {
            billingType
            businessCategory
            businessUnit
            linkedHomeids
            linkedMsisdns
            nationalId
            nationalIdType
            planId
            planName
            planType
            primarySubscriberId
            registrationDate
        }
        address {
            city
            ip
            state
        }
        closedSession
        master
        country
        deviceId
        enableView
        recentActivity
        firstSession
        status
        refreshToken
        systemInfo {
            operatingSystem
            browser
        }
        userId
    }
  }
}`;


export const MUTATION_CREATE_MASTER_ACCOUNT = gql`mutation
  CreateMasterAccount($deviceId: String!) {
  createMasterAccount (
      deviceId: $deviceId
  ) {
      limitAccountManager
      limitViewDevice
      item {
          accessToken
          accounts {
              billingType
              businessCategory
              businessUnit
              linkedHomeids
              linkedMsisdns
              nationalId
              nationalIdType
              planId
              planName
              planType
              primarySubscriberId
              registrationDate
          }
          address {
              city
              ip
              state
          }
          closedSession
          master
          country
          deviceId
          enableView
          recentActivity
          firstSession
          status
          refreshToken
          systemInfo {
              operatingSystem
              browser
          }
          userId
      }
    }
}`;


export const MUTATION_DELETE_MASTER_ACCOUNT = gql `mutation
DeleteMasterAccount($deviceId: String!) {
  deleteMasterAccount (
    deviceId: $deviceId
) {
    limitAccountManager
    limitViewDevice
    item {
        accessToken
        accounts {
            billingType
            businessCategory
            businessUnit
            linkedHomeids
            linkedMsisdns
            nationalId
            nationalIdType
            planId
            planName
            planType
            primarySubscriberId
            registrationDate
        }
        address {
            city
            ip
            state
        }
        closedSession
        master
        country
        deviceId
        enableView
        recentActivity
        firstSession
        status
        refreshToken
        systemInfo {
            operatingSystem
            browser
        }
        userId
    }
  }
}`;


export const MUTATION_DELETE_DEVICE = gql `mutation
DeleteMasterAccount($deviceId: String!) {
  deleteDevice (
    deviceId: $deviceId
) {
    accessToken
    accounts {
        billingType
        businessCategory
        businessUnit
        linkedHomeids
        linkedMsisdns
        nationalId
        nationalIdType
        planId
        planName
        planType
        primarySubscriberId
        registrationDate
    }
    address {
        city
        ip
        state
    }
    closedSession
    master
    country
    deviceId
    enableView
    recentActivity
    firstSession
    status
    refreshToken
    systemInfo {
        operatingSystem
        browser
    }
    userId
  }
}`;

export const MUTATION_SET_CONECTION = gql `mutation
SetConnection($deviceId: String!, $contentView: String!) {
    putConnection (
        deviceId: $deviceId
        contentView: $contentView
    ) {
        deviceId
        enableView
        userId
        itemEnable {
            deviceId
            enableView
            userId
        }
    }
 }`;

export const SUBSCRIPTION_CONECTION = gql `subscription subscribeEstablishConnection {
    subscribeEC {
      userId
      deviceId
      enableView
    }
  }
`;

export const SUBSCRIPTION_SESION = gql `subscription subscribeSession {
  subscribeClosedSession {
    userId
    deviceId
    enableView
  }
}
`;

export const MUTATION_SET_CONECTION_DEACTIVE = gql `mutation {
    putConnectionDeactivate {
        deviceId
        enableView
        userId
    }
}`;

/* Endpoints Vecima  */
//export const SESIONS_TOKENS_VECIMA = environment.APP_VECIMA_CREATE_TOKENS_PATH + '/streaming';
