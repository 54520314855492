import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {API_AUTH} from '../config/endpoints';
import {environment} from '../../environments/environment';
import {JwtHelperService} from '@auth0/angular-jwt';
import * as moment from 'moment';
import {Storage} from '../core/storage/storage';
import {MiddlewareAuth} from '../core/interfaces/middleware-auth';
import {EventEmitter} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MiddlewareService {

  $eventtoken = new EventEmitter<any>();

  constructor(
    private httpClient: HttpClient,
    private jwtHelper: JwtHelperService,
    private storage: Storage
  ) {
  }

  /* Get middleware authorization */
  // public async getAuthorization(): Promise<string> {
  //   let token : string;
  //   let envVersion = environment.PROCESS_VERSION;
  //   let envStorage = this.storage.getEnvironmentVersion();
  //   if (envVersion !== envStorage) {
  //     token = await this.getToken();
  //   }
  //   if (token) {
  //     const expirationDate = this.jwtHelper.getTokenExpirationDate(token);
  //     const expirationDateUtc = moment.utc(expirationDate);
  //     const dateUtc = moment.utc();
  //     const days = expirationDateUtc.diff(dateUtc, 'days');
  //     if (days <= 1) {
  //       token = await this.getToken();
  //     }
  //   } else {
  //     token = await this.getToken();
  //   }
  //   return token;
  // }

  /* Get new token from middleware */
  // private async getToken(): Promise<string> {
  //   const {GRANT_TYPE, CLIENT_ID, CLIENT_SECRET} = environment;
  //   let auth: any;
  //   const body = new HttpParams()
  //     .set('grant_type', GRANT_TYPE)
  //     .set('client_id', CLIENT_ID)
  //     .set('client_secret', CLIENT_SECRET);

  //   try {
  //     //const token: MiddlewareAuth = await this.httpClient.post<MiddlewareAuth>(API_AUTH, body).toPromise();
  //     //const {access_token} = token;
  //     //this.storage.setMiddlewareAuthorization(access_token);
  //     //auth = access_token;
  //   } catch (e) {}

  //   return auth;
  // }
}
