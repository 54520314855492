
<div class="text-center">
  <div class="row ">
    <div class="col">
    <img [src]="data.img_src"  width="50px" height="50px" alt="ico">
  </div>
  </div>

<div class="text-position">
<label class="title">{{data.title}}</label>
<label class="sub-title m-3">{{data.subtitle}}</label>

</div>
<div class="row">
<div class="col">
<button class="continue-button" (click)="subscribe()">SÍ, SUSCRÍBEME</button>
</div>
</div>
<div class="row">
  <div class="col">
<a (click)="close()" class="style_btn"><label class="m-3">NO, MUCHAS GRACIAS</label></a>
</div>
</div>
</div>