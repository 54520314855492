<ng-container *ngIf="!showErrorMessage" >
<div class="text-center">
  <div class="row ">
    <div class="col">
    <!-- <img [src]="data.img_src"  width="50px" height="50px" alt="ico"> -->
  </div>
  </div>

<div class="text-position">
<label class="title">{{data?.title}}</label>

<div class="label-container">
<div class="row">
  <form class="custom-card-content"  [formGroup]="form">
  <mat-form-field appearance="outline" class="form-field"> 
      <mat-label>Escribe aquí</mat-label>
      <input matInput placeholder="Escribe aquí"  formControlName="deleteLabel">
  </mat-form-field>
  </form>


</div>
</div>
</div>
<button class="style_btn" [ngClass]="{'style_disable': !isValid, 'style_valid': isValid}" (click)="delete()"  [disabled]="!isValid"><label class="m-4">{{data?.buttonText}}</label></button>
</div>
</ng-container>

<ng-container *ngIf="showErrorMessage" >
<div class="text-center">
  <div class="row ">
    <div class="col">
    <img src="assets/graphics/payment/transaction-error.svg"  width="50px" height="50px" alt="ico">
  </div>
  </div>

<div class="text-position">
<label class="title">{{data?.buttonDelete}}</label>

</div>
<button class="style_btn style_valid"  (click)="close()" ><label class="m-4">{{data?.errorMessage}}</label></button>
</div>
</ng-container>