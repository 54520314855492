export const CREDIT_CARDS = [
    {
        type: 'visa',
        icon: '../../../../assets/graphics/payment/visa.svg'
    },
    {
        type: 'mastercard',
        icon: '../../../../assets/graphics/payment/mastercard.svg'
    },
    {
        type: 'american-express',
        icon: '../../../../assets/graphics/payment/AmericanExpress.svg'
    }
];

export const DEFAULT_CARD =  '../../../../assets/graphics/payment/ico-CC.svg';
