import {Component,Directive, OnInit, AfterViewInit, OnDestroy, ElementRef, ViewChild,Input} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ContentfulService } from 'src/app/services/contentful.service';
import { IFrameComponent, iframeResizer } from 'iframe-resizer';
import { FACTORY_PUBLISHER } from 'src/app/config/endpoints';

@Component({
  selector: 'app-match-results',
  templateUrl: './match-results.component.html',
  styleUrls: ['./match-results.component.scss'],
})

export class MatchResultsComponent implements   OnInit, AfterViewInit, OnDestroy {
  @ViewChild('appIframeResizer', {static: false}) iframe: ElementRef;
  component: IFrameComponent;
  urlResults: any;

  @Input()id_match:any;
  @Input()league:any;
  constructor(
    private sanitizer: DomSanitizer,
  ) { this.urlResults = this.sanitizer.bypassSecurityTrustResourceUrl(''); }

  ngAfterViewInit() {
  
      const components = iframeResizer({
        checkOrigin: false,
        heightCalculationMethod: 'documentElementOffset',
        log: false
      }, this.iframe.nativeElement);
  
      /* save component reference so we can close it later */
      this.component = (components && components.length > 0) ? components[0] : null;
  
  }

  ngOnDestroy(): void {

    if (this.component && this.component.iFrameResizer) {
      this.component.iFrameResizer.close();
    }

  }
  async ngOnInit() {
    await this.getUrl();
  }
  private async getUrl() {
    //const urlResponse = await this.contentfulService.getResultsUrl();
    let urlResponse = `https://estaticopy.tigocloud.net/py/html/v3/minapp/itemMaM/itemMaM.html?channel=deportes.futbol.${this.league}.${this.id_match}.mam&lang=es_LA&theme=tigopy`
    if(urlResponse){
     
        this.urlResults = this.sanitizer.bypassSecurityTrustResourceUrl(urlResponse);
   
    }
  }
}
