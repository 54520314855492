
<div class="text-center">
  <div class="row ">
    <div class="col">
    <img [src]="data.img_src"  width="50px" height="50px" alt="ico">
  </div>
  </div>

<div class="text-position">
<label class="title">{{data.title}}</label>
<label class="sub-title m-3">{{data.subtitle}}</label>

</div>
  <a [routerLink]="data.route" (click)="close()" class="style_btn"><label class="m-3">VOLVER A INICIO</label></a>
</div>
