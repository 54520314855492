import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class Auth0Service {
  private addItemAuth0 = new Subject<boolean>();
  itemIsAuth0 = this.addItemAuth0.asObservable();

  constructor() {}

  addItemisAuth0(item: boolean) {
    this.addItemAuth0.next(item);
  }


}
