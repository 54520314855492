import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GET_BANNERS } from '../config/endpoints';
import { CARROUSEL_LIMIT } from '../config/constants';

@Injectable({
  providedIn: 'root'
})
export class BannersService {

  constructor(
    private httpClient: HttpClient
  ) { }

  /* Get banners data from middleware */
  public async getBanners(assetDomain: any): Promise<any> {
    let banners: any = [];
    let sectionAux = '';
    let bannersAux = [];
    let bannerDataAux = {};
    try{
      const dataResponse: any = await this.httpClient.get(`${GET_BANNERS}/1/${CARROUSEL_LIMIT}`).toPromise();
      if (dataResponse.length > 0){
        dataResponse.forEach((banner) => {
          sectionAux = banner.json.fields.section;
          bannerDataAux = {
            contentful_id: banner.contentful_id,
            section : sectionAux,
            title : banner.json.fields.name,
            description : banner.json.fields ? banner.json.fields.description : '',
            subtitle: banner.json.fields ? banner.json.fields.subtitle : '',
            imageUrl: this.getImage(banner, assetDomain),
            createdAt: banner.json.sys.createdAt,
            championship_id : '',
            championship_name : '',
            sport_name : banner ? banner?.detail?.sport?.json?.fields?.name : '',
            video_title: banner.json.fields ? banner.json.fields.name : '',
            content_id: banner.contentful_id,
            home_team_id: banner.json.fields.homeTeam ? banner.json.fields.homeTeam?.sys.id : '' ,
            home_team: '',
            away_team_id: banner.json.fields.awayTeam ? banner.json.fields.awayTeam.sys.id : '',
            away_team: '',
            total_length: banner.contentful_data ? banner.contentful_data.fields.totalLength : 0,
            session_id: '',
            video_quality: '',
            content_category: 'banners',
            content_type: banner.detail?.content_type ? banner.detail?.content_type : '',
            schedule: '',
            textDisabled: '',
            valueEntry: banner.json.fields.valueEntry ? banner.json.fields.valueEntry : '',
            publisher_country: banner ? banner?.country : '',
            id: banner ? banner?.json?.sys?.id : '',
            promotion_creative_id: banner ? banner?.detail?.json?.fields?.description?.content[0]?.content[0]?.value : '',
            position: banner ? banner?.json?.fields?.order : '',
            championship_id_highlights: banner ? banner?.detail?.category?.json?.sys?.id : '',
            championship_name_highlights: banner ? banner?.detail?.category?.json?.fields?.name : '',
            championship_id_games: banner ? banner?.detail?.league?.json?.sys?.id : '',
            championship_name_games: banner ? banner?.detail?.league?.json?.fields?.name : '',
            endDate: banner?.json?.fields?.endDate
          }
          bannersAux.push(bannerDataAux);
        })
      }
      banners = dataResponse ? bannersAux : [];
    } catch(err) {
      console.log(err);
    }
    return banners;
  }

  /* Get image url from Amazon S3 or Contentful */
  private getImage(obj, assetDomain){
    let domain = assetDomain?.banners ? assetDomain.banners : assetDomain.default
    if(assetDomain?.status && assetDomain.status === true && obj?.json?.fields?.imageOttS3){
      return domain + obj?.json?.fields?.imageOttS3;
    }else
      return obj.json.fields ? `https:${obj.json.fields.imageOtt?.file?.fields?.file?.url}` : ''
  }
}
