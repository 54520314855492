import { Injectable } from '@angular/core';
import {PLATFORM_LOCATION} from '../config/endpoints';
import {HttpClient, HttpHeaders, HttpXhrBackend} from '@angular/common/http';
import {Storage} from "../core/storage/storage";
import {StorageHelper} from "../core/helpers/storage.helper";
import {SecurityHelper} from "../core/helpers/security.helper";
import {Location} from "../core/interfaces/location";
@Injectable({
  providedIn: 'root'
})
@Injectable()
export class DevinsService {

  constructor(
  ) { }

  async inSpec() {
    const d = new Date();
    const time = d.getTime();
    const data = {
      time
    };
    try{
      const storage = new Storage(new StorageHelper(new SecurityHelper()));
      const token = storage.getMiddlewareAuthorization() ? storage.getMiddlewareAuthorization() : '';
      const location: Location = storage.getLocation();
      const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
      const headers= new HttpHeaders()
        .set('content-type', 'application/json')
        .set('space',location.code)
        .set('Authorization', 'Bearer '+token);

      const resp = await httpClient.post(PLATFORM_LOCATION,data,{headers}).toPromise();
      if(storage.getMiddlewareAuthorization()){
        storage.removeAccessToken();
        storage.removeAuthToken();
        storage.removeRefreshToken();
        storage.removeTokenExpires();
        storage.removeRefreshTokenTigoId();
        storage.removeTokenTigoId();
        storage.removeUser();
        storage.removeUserPersonal();
        storage.removeEntitlement();
        storage.removeTokenPersonal();
        storage.removeMiddlewareAuthorization();
        storage.removeAccountMasterAppSync();
      }
    }catch (e){
      //
    }
  }
}
