import {Injectable, Inject, Renderer2, RendererFactory2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {v4 as uuidv4} from 'uuid';
import {DEVICE_FINGER} from '../config/endpoints';
import {DevicesService} from './devices.service';
import {environment} from '../../environments/environment';
import {SecurityHelper} from './../core/helpers/security.helper';

@Injectable({
  providedIn: 'root'
})
export class PaymentSessionRegisterService {
  uuid = uuidv4();
  private renderer: Renderer2;

  constructor(
    rendererFactory: RendererFactory2,
    private httpClient: HttpClient,
    private deviceService: DevicesService,
    private security: SecurityHelper,
    @Inject(DOCUMENT) private document
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  generateDeviceFingerprintId() {
    this.deviceService.setDeviceId(this.uuid);
    this.loadScript();
    return this.deviceService.getDeviceId();
  }

  async getDeviceFingerprintId() {
    try {
      const deviceFingerId: any = await this.httpClient.post(DEVICE_FINGER, {productId: 1}).toPromise();
      if (deviceFingerId) {
        const text = (deviceFingerId.data.html.split('<noscript>')[1]).replace('</noscript>', '');
        //create img element
        const img = document.createElement('img');
        img.src = text.split('\"')[1].replace('amp;', '') + '&ts=' + (new Date()).getTime();
        this.renderer.appendChild(this.document.body, img);
        return deviceFingerId.data.deviceFingerprintTransactionId;
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  private loadScript(): void {
    const { ORG_ID } = environment;
    const url = `https://h.online-metrix.net/fp/tags.js?org_id=${this.security.decrypt(ORG_ID,1)}&session_id=${environment.MERCHANT_ID}${this.deviceService.getDeviceId()}`;
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    this.renderer.appendChild(this.document.head, script);

    const noscript = this.renderer.createElement('noscript');
    const iframe = this.renderer.createElement('iframe');
    iframe.style = 'width: 100px; height: 100px; border: 0; position: absolute; top: -5000px;';
    iframe.src = url;
    noscript.appendChild(iframe);
    this.renderer.appendChild(this.document.body, iframe);
  }

}
