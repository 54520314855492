<div class="text-center">
  <div class="text-position">
    <img (click)="close()"  src="assets/graphics/options/tabs/Close.svg" class="close"  alt="ico">
  </div>
  <div class="text-position">
    <p class="title">{{text?.modal?.title}}</p>
    <p class="description">{{text?.modal?.description}}</p>
  </div>
  
  <div class="container-formItems">
    <div class="Form__item">
      <div class="container">
        <div class="row">
          <div class="col-2 d-flex justify-content-center">
            <img class="logo" [src]="data?.objContent?.logo" alt="ico">
          </div>
          <div class="containerTexts col-10">
            <div class="message">{{data?.objContent?.title}}</div>
            <div class="messagePrice">{{data?.objContent?.price}}</div>
            <div class="messageDescription">{{data?.objContent?.description}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="buttons justify-content-end">
    <button *ngIf="isAdaptable" (click)="close()"  class="cancel">{{text?.modal?.cancelButton}}</button>
    <button *ngIf="isAdaptable" (click)="goToSubscription()" class="continue-button">{{text?.modal?.confirmButton}}</button>
    <button *ngIf="!isAdaptable" (click)="close()" class="continue-button">{{text?.modal?.tvButton}}</button>
  </div>
</div>