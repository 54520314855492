import { Component, Input, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { ModalController, NavController } from '@ionic/angular';
import {SegmentAnalytics} from '../../../core/providers/segment-analytics';
import { EVENT_TRACK_005, EVENT_TRACK_100 } from '../../../config/analytics/events-track';
import { BrowserService } from 'src/app/services/browser.service';
import {TextManagementService} from '../../../services/text-management.service';
import { TvLoginService } from 'src/app/services/tv-login.service';
import {Storage} from '../../../core/storage/storage';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-message-toast',
  templateUrl: './message-toast.component.html',
  styleUrls: ['./message-toast.component.scss'],
})
export class MessageToastComponent  implements OnInit {
  @Input() data:any;
  MessageToastStatus = MessageToastStatus
  constructor(private modalCtrl:ModalController) { }

  ngOnInit() {
    //console.log(this.data);
  }
  async close(){
    await this.modalCtrl.dismiss();
  }

 async deleteDevice(){
  console.log('close toast...');
  console.log(this.data.deleteUserType);
  await this.modalCtrl.dismiss();
 }
}

export enum MessageToastStatus {
  successed,
  failured
}