import { Injectable } from '@angular/core';
import { registerPlugin } from '@capacitor/core';

const AvPlayerDrmIos = registerPlugin<any>('AvPlayerDrmIos');
export default AvPlayerDrmIos;
@Injectable({
  providedIn: 'root'
})

export class AVPlayerServiceService {

  async play(streamURL, streamToken, jwt, tts, cts, certificateURL, licenseURL, fingerprinting, eventType, uuid, isVecima) {
    const {value} = await AvPlayerDrmIos.play({
      streamURL, streamToken, jwt, tts, cts, certificateURL, licenseURL, fingerprinting, eventType, uuid, isVecima
    });
  }

  async playNoDRM(streamURL, jwt, cts, fingerprinting, eventType, uuid, isVecima) {
    const {value} = await AvPlayerDrmIos.playNoDRM({
      streamURL, jwt, cts, fingerprinting, eventType, uuid, isVecima
    });
  }

  async currentTime() {
    const {value} = await AvPlayerDrmIos.currentTime();
    return value;
  }

  async duration() {
    const {value} = await AvPlayerDrmIos.duration();
    return value;
  }
}
