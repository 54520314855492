import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ConfigurationsService} from './configurations.service';
import { Storage } from '../core/storage/storage';
import * as moment from 'moment';
import { NavController } from '@ionic/angular';
import { LocationService } from './location.service';

@Injectable({
  providedIn: 'root'
})
export class TextManagementV2Service {

    constructor(private configurationService: ConfigurationsService,private storage: Storage, private navController:NavController,private locationService:LocationService) { }

    public async getTexts() {
        try{
            let storedOptions = this.storage.getTextsV2();
            if (storedOptions) {
                const day = moment().diff(storedOptions.createdAt, 'minutes',true);
                if (day >= 1) {
                    await this.saveTexts();
                }
            } else {
                await this.saveTexts();
            }
            storedOptions = this.storage.getTextsV2();

            const texts = this.getFormattedTexts(storedOptions);

            return texts;
        }catch(e){
            console.log(e);
        }

    }

    private async saveTexts(){
        await this.locationService.locate();
        try{
            const currentTexts =  await this.configurationService.getConfigurationByVariable("ott_text_v2");
            if(currentTexts){
                this.storage.setTextsV2(currentTexts);
            }
        }catch(e){
            console.log(e);
        }
    }

    private getFormattedTexts(storedTexts: any): any {
        const currentTexts = (storedTexts) ? storedTexts?.data : '';

        return {
            USERCONCURRENTON: currentTexts ? (currentTexts.UserConcurrentsOn) : {},
            CONCURRENT_MASTER_USERS_ON: currentTexts ? (currentTexts.concurrentMasterUsersOn) : {},
            FAVORITE_TEAMS: currentTexts ? (currentTexts.favoriteTeamsOn) : {},
            ASSET_DOMAIN: currentTexts ? (currentTexts.assetDomain) : {},
            MULTI_ENTITLEMENT: currentTexts ? (currentTexts.multiEntitlement) : {},
            WEB_GL: currentTexts ? (currentTexts.webGL) : {},
            VECIMAOPTIONS: currentTexts ? (currentTexts.vecimaOptions) : {},
        };
      }

}
