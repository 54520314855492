import {Component, HostListener, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {IS_ADAPTABLE} from 'src/app/config/constants';
import {Storage} from 'src/app/core/storage/storage';
import {NavController} from '@ionic/angular';
import {SegmentAnalytics} from 'src/app/core/providers/segment-analytics';
import {EVENT_TRACK_072} from 'src/app/config/analytics/events-track';
import { VideoStreamingService } from 'src/app/services/video-streaming.service';
import { LiveNowService } from 'src/app/services/live-now.service';
import * as moment from 'moment';
import { EventDetailService } from 'src/app/services/event-detail.service';
import { ENTITLEMENT } from 'src/app/config/settings';
import { Capacitor } from '@capacitor/core';
import { DevicesService } from 'src/app/services/devices.service';
import { AuthService } from 'src/app/services/auth.service';
import { TextManagementV2Service } from 'src/app/services/text-management-v2.service';
@Component({
  selector: 'app-live-now-cards',
  templateUrl: './live-now-cards.component.html',
  styleUrls: ['./live-now-cards.component.scss'],
})
export class LiveNowCardsComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() title: string;
  @Input() isMultiEntitlement: any;
  @Input() multiEntitlement: any;
  @Input() queryParams: any;
  

  liveGames = [];
  isAdaptable = IS_ADAPTABLE;
  showLiveNow = false;
  loadingImg = '../../../../assets/graphics/pictures/load-img.png';
  isWeb = Capacitor.getPlatform() === 'web';
  showArrows = false;
  pageNumber = 2;
  showliveafter = false;
  temporaryobjlive = [];
  textsV2: any;
  assetDomain: any;

  constructor(
    private storage: Storage,
    private navController: NavController,
    private segmentAnalytics: SegmentAnalytics,
    private videoStreamingService: VideoStreamingService,
    private liveNowService: LiveNowService,
    private eventDetailService: EventDetailService,
    private devicesService: DevicesService,
    private textManagementV2Service: TextManagementV2Service
  ) {
  }

  async ngOnInit() {
    this.textsV2 = await this.textManagementV2Service.getTexts();
    this.assetDomain = this.textsV2?.ASSET_DOMAIN;

    if (this.data.length > 0) {
      try {
        const data = this.data;
        this.liveGames = (data.length > 0) ? data : [];
        this.showLiveNow = (data.length);
        this.showArrows = (data.length > 2 && this.isWeb);
      } catch (error) {
      }
    }
  }

  @HostListener('window:scroll', ['$event'])
  async doSomethingOnScroll(event) {
    const totalwidth = Math.round(event.target.offsetWidth + event.target.scrollLeft);
    if (totalwidth === event.target.scrollWidth) {
      const newArr = await this.liveNowService.getEvents(this.pageNumber, this.assetDomain, this.queryParams, this.multiEntitlement);
      this.pageNumber++;
      this.liveGames.push(...newArr);
    }
  }

  ngOnChanges(change: SimpleChanges): void {
   // this.data = [];
  //  const results = change.data;
    this.data = [];
    const results = change.data;

    if (results) {
      this.liveGames = results.currentValue;
      this.showLiveNow = (results.currentValue.length > 0);
      this.showArrows = (results.currentValue.length > 2 && this.isWeb);
      this.pageNumber = 2;
      this.showliveafter = this.showLiveNow;
      this.temporaryobjlive = this.liveGames;
    }

    if (this.showliveafter) {
      this.showLiveNow = this.showliveafter;
      this.liveGames = this.temporaryobjlive;
      this.showArrows = (this.temporaryobjlive.length > 2 && this.isWeb);
    }

    /*this.liveGames = change.data?.currentValue;

    if (this.liveGames) {
    //  this.liveGames = results.currentValue;
      this.showLiveNow = (this.liveGames.length > 0);
      this.showArrows = (this.liveGames.length > 2 && this.isWeb);
    }*/
  }

  /* If the user is authenticated then redirect to detail page, else redirect to login page */
  async viewDetail(event): Promise<void> {
    const authenticated = this.storage.getAccessToken();
    let route: string;
    const segmentData = {
      championship_id: event.championship_id,
      championship_name: event.championship_id,
      airdate: event.createdAt,
      sport_name: event.sport_name,
      video_title: event.video_title,
      journey: 'player',
      content_id: event.contentful_id,
      home_team_id: event.home_team_id,
      home_team: event.home_team,
      away_team_id: event.away_team_id,
      away_team: event.away_team,
      total_length: event.total_length,
      video_position: 0,
      session_id: event.session_id,
      video_quality: event.video_quality,
      content_category: event.content_category,
      content_type: this.defineContentTypeSegment(event.end_date),
    };
    await this.segmentAnalytics.eventTrack(EVENT_TRACK_072, segmentData);
    this.videoStreamingService.setEventCategory(event.content_category);
    let eventType = '';
    if (event.content_type === 'gameSchedules') {
      eventType = 'game'
    } else {
      eventType = 'highlights'
    }

    //let streamingType = await this.eventDetailService.getStreamType(event.contentful_id, eventType);
    route = `/detail/${event.contentful_id}/${eventType}`;
    this.storage.setPreviousRoute(route);

 /*    if (!authenticated && streamingType != ENTITLEMENT.FREE_TYPE) {
      if (this.isAdaptable) {
        route = '/-/iniciar-sesion';
      } else {
        route = '/-/iniciar-sesion-tv';
      }
    } */




    this.navController.navigateForward(route);
  }

  private defineContentTypeSegment(end_date: Date): string {
    let contentTypeFixed = '';
    const endDate = end_date;
    if (endDate) {
      const now = moment();
      const deadline = moment(endDate);

      const isAfter = moment(deadline).isAfter(now);
      if (isAfter) {
        contentTypeFixed = 'live';
      } else {
        contentTypeFixed = 'replay';
      }
    } else {
      contentTypeFixed = 'live';
    }
    return contentTypeFixed;
  }

  handleScrollSegment(qty: number): void {
    let segmentAux = document.getElementById('liveNow__segment');
    segmentAux.scrollLeft = (segmentAux.scrollLeft + qty);
  }
}
