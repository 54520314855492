import {NgModule} from '@angular/core';
import {ApolloModule, APOLLO_OPTIONS} from 'apollo-angular';
import {ApolloClientOptions, DefaultOptions, InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import {environment} from 'src/environments/environment';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import {getMainDefinition} from '@apollo/client/utilities';
import {WebSocketLink} from '@apollo/client/link/ws';
import {SubscriptionClient} from 'subscriptions-transport-ws';
import {v4 as uuidv4} from 'uuid';
import * as graphqlPrinter from 'graphql/language/printer';
import {Storage} from './core/storage/storage';
import moment from 'moment';

const uri = environment.APP_SYNC_PATH; // <-- add the URL of the GraphQL server here
const uri_ws = environment.APP_SYNC_PATH_WS; // <-- add the URL of the GraphQL server here WS
const header_encode = (obj: any) => btoa(JSON.stringify(obj));
let isRegresh = false;
let bcFinish = null;
let bcClosed = null;
let clientSocket = null;
let clientReconect = 0;

// @ts-ignore
class UUIDOperationIdSubscriptionClient extends SubscriptionClient {
  // @ts-ignore
  generateOperationId() {
    return uuidv4();
  }
  // @ts-ignore
  processReceivedData(receivedData) {
    try {
      const parsedMessage = JSON.parse(receivedData);
      if (parsedMessage?.type === 'start_ack') {return;} // sent by AppSync but meaningless to us
    } catch (e) {
      throw new Error('Message must be JSON-parsable. Got: ' + receivedData);
    }
    // @ts-ignore
    super.processReceivedData(receivedData);
  }
}

const wsConfig = {
  ws: null,
  api_header: null
};

const connection_url = (_storaa: Storage) => {
  const token = _storaa.getMiddlewareAuthorization();
  wsConfig.api_header = {
    host: uri.replace('https://','').replace('/graphql',''),
    Authorization: `Bearer ${token}`,
  };
  wsConfig.ws =
    uri_ws +
    '?header=' +
    header_encode(wsConfig.api_header) +
    '&payload=' +
    header_encode({});
};

const getUrl = () => wsConfig.ws;

export function createApollo(httpLink: HttpLink, _storaa: Storage): ApolloClientOptions<any> {
  if (!bcFinish) {
    bcFinish = new BroadcastChannel('eventRegreshTokenFinish');
    bcFinish.onmessage = (messageEvent) => {
      isRegresh=false;
      clientReconect = 2;
    };
  }
  if (!bcClosed) {
    bcClosed = new BroadcastChannel('eventClosetSocket');
    bcClosed.onmessage = (messageEvent) => {
      if (clientSocket != null) {
        clientSocket.unsubscribeAll();
        clientSocket.close(true, true);
        clientSocket = null;
      }
    };
  }

  const http = httpLink.create({uri});
  const createAppSyncGraphQLOperationAdapter = () => ({
    applyMiddleware: async (options, next) => {
      options.data = JSON.stringify({
        query:
          typeof options.query === 'string'
            ? options.query
            : graphqlPrinter.print(options.query),
        variables: options.variables,
      });

      connection_url(_storaa);
      options.extensions = { authorization: wsConfig.api_header };

      delete options.operationName;
      delete options.variables;

      next();
    }
  });
  connection_url(_storaa);
  if (clientSocket != null) {
    clientSocket.unsubscribeAll();
    clientSocket.close(true, true);
    clientSocket = null;
  }
  clientSocket = new UUIDOperationIdSubscriptionClient(
    getUrl(),
    {
      timeout: 5 * 60 * 1000,
      reconnect: true,
      lazy: true,
      connectionCallback: (err) => {
        // clientReconect++;
        connection_url(_storaa);
        ws.subscriptionClient.url = wsConfig.ws;
        const errorMessage = err ? err.toString() : ' ';
        if (!isRegresh && Boolean(err) && clientReconect>5 && clientReconect<10 && errorMessage.search('UnauthorizedException')) {
         // console.log('condition1', !isRegresh && Boolean(err) && clientReconect>3);
          const hoy = Date.now();
          clientReconect++;
         // console.log(moment(hoy).format('YYYY-MM-DD hh:mm A'));
         // console.log('******');
          isRegresh = true;
          const bc = new BroadcastChannel('eventRegreshTokenStart');
          bc.postMessage('START');
        } else if (!isRegresh && Boolean(err) && errorMessage.search('UnauthorizedException'))  {
          clientReconect++;
        }
        if ((clientReconect>=1 && !err) || (clientReconect>=10)) {
          const bc = new BroadcastChannel('eventSubscription');
          bc.postMessage('START');
        }
        console.log('connectionCallback', err ? 'ERR' : 'OK', err || '');
      },
    },
    WebSocket,
  ).use([createAppSyncGraphQLOperationAdapter()]);
  const ws: any = new WebSocketLink(clientSocket);

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  };

  const link = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
    {graphQLErrors.map(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );}

    if (networkError) {console.log(`[Network error]: ${networkError}`);}
  }).split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    ws,
    http
  );

  const authLink = setContext((_, { headers }) =>
    // return the headers to the context so httpLink can read them
    ({
      headers: {
        ...headers,
      },
    })
  );

  return {
    link: authLink.concat(link),
    cache: new InMemoryCache(),
    defaultOptions
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, Storage],
    },
  ],
})
export class GraphQLModule {}
