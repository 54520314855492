import { Component, OnInit, Input } from '@angular/core';
import { ModalController,NavController } from '@ionic/angular';
import {SegmentAnalytics} from '../../../core/providers/segment-analytics';
import { EVENT_TRACK_005, EVENT_TRACK_100 } from '../../../config/analytics/events-track';
import {Storage} from '../../../core/storage/storage';
import { BrowserService } from 'src/app/services/browser.service';
import { environment } from 'src/environments/environment';
import {Capacitor} from '@capacitor/core';
import { TvLoginService } from 'src/app/services/tv-login.service';
import {TextManagementService} from '../../../services/text-management.service';
@Component({
  selector: 'app-message-subscription',
  templateUrl: './message-subscription.component.html',
  styleUrls: ['./message-subscription.component.scss'],
})
export class MessageSubscriptionComponent implements OnInit {
  eventtrack005 = EVENT_TRACK_005;
  eventtrack100 = EVENT_TRACK_100;
  uniqueIdentifier;
  @Input() data:any;
  constructor(private modalCtrl:ModalController,private navController: NavController,
              private segmentanalytics: SegmentAnalytics,  private storage: Storage,
              private browserService: BrowserService,private tvLogin: TvLoginService,
              private textManagementService: TextManagementService) { }

  ngOnInit() {
    const user: any = this.storage.getUser();
    const { uniqueIdentifier } = user ? user : '';
    this.uniqueIdentifier = uniqueIdentifier;
  }
  async close(){
    await this.modalCtrl.dismiss();
    const datasegment = {
      flag: 'NO, MUCHAS GRACIAS',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      link_url: `${window.location.origin}${window.location.pathname}`,
      journey: 'user interface'
    };
    await this.handlerevent(this.eventtrack005, datasegment);
    this.navController.navigateForward(this.data.route_back);
 }

 async subscribe(){

  await this.modalCtrl.dismiss();
  const segmendata = {
     journey: 'ppv',
     // eslint-disable-next-line @typescript-eslint/naming-convention
     payment_account: `${this.uniqueIdentifier}`
   };
  await this.handlerevent(this.eventtrack100, segmendata);
  let type = '';
  const platform = Capacitor.getPlatform();
  const user = this.storage.getUser() ? this.storage.getUser() :this.storage.getUserCarrierBilling();
  const deviceID = await this.tvLogin.getDeviceID().then();
  type = user ? user.entitlement_category : '';
  /*if (user) {
   type = (user.entitlement_category === 'home' || user.entitlement_category === 'other') ? 'other' : user.entitlement_category;
  }*/
  if (platform === 'web') {
    await this.navController.navigateForward(`/ajustes/subscription/payment-methods?status=payment`);
  } else {
    const infopayment: any  =  await this.textManagementService.getTurnoPayment();
    const idToken = this.storage.getTokenTigoId(); // ID token TIGO
    const accessToken = this.storage.getAccessTigoId(); // Access token TIGO
    const token = await this.tvLogin.generateToken(idToken, accessToken);
    await this.browserService.openExternalLink(`${environment.BASE_URL}/ajustes/subscription/payment-methods?status=payment&token=${token}&device=${deviceID}&type=${type}&Pvv=${infopayment.pvv}&Scp=${infopayment.suscription}`);
  }
 }

 async handlerevent(event, obj) {
    await this.segmentanalytics.eventTrack(event, obj);
 }
}
