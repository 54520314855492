import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainMobileToolbarComponent} from './layout/platforms/mobile/main-mobile-toolbar/main-mobile-toolbar.component';
import {MarkdownModule} from 'ngx-markdown';
import {BannerComponent} from './components/banner/banner.component';
import {LiveNowCardsComponent} from './components/live-now-cards/live-now-cards.component';
import {UpcomingEventsComponent} from './components/upcoming-events/upcoming-events.component';
import {RepetitionsComponent} from './components/repetitions/repetitions.component';
import {RecommendedComponent} from './components/recommended/recommended.component';
import {NewsComponent} from './components/news/news.component';
import {OtherContentComponent} from './components/other-content/other-content.component';
import {FavoritesComponent} from './components/favorites/favorites.component';
import {RecommendedV2Component} from './components/recommended-v2/recommended-v2.component';
import {RouterModule} from '@angular/router';
import {FinderWebComponent} from './components/finder-web/finder-web.component';
import {FinderWebTVComponent} from './components/finder-web-tv/finder-web-tv.component';
import {ReadDocumentJsonComponent} from './components/read-document-json/read-document-json.component';
import {IonicModule} from '@ionic/angular';
import {CustomKeyboardComponent} from './components/custom-keyboard/custom-keyboard.component';
import { ProgressLoadComponent } from './components/progress-load/progress-load.component';
import { MatchResultsComponent } from './components/match-results/match-results.component';
import { MyRecommendedComponent } from './components/my-recommended/my-recommended.component';
import { RequestPermissionComponent } from './components/request-permission/request-permission.component';
import { MyCardsComponent } from './components/my-cards/my-cards.component';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { MessageSubscriptionComponent } from './components/message-subscription/message-subscription.component';
import { MessageDialogsubscriptionComponent } from './components/message-dialogsubscription/message-dialogsubscription.component';
import { MessageDeleteComponent } from './components/message-delete/message-delete.component';
import { MessageDialogSelectUserMasterComponent } from './components/message-dialog-select-user-master/message-dialog-select-user-master.component';
import { MessageDeleteDeviceComponent } from './components/message-delete-device/message-delete-device.component';
import { MessageToastComponent } from './components/message-toast/message-toast.component';
import { MessageConcurrentUserMasterComponent } from './components/message-concurrent-user-master/message-concurrent-user-master.component';
import { MessageConcurrentUsermasterDeviceComponent } from './components/message-concurrent-usermaster-device/message-concurrent-usermaster-device.component';
import { MessageConcurrentuserNomasterComponent } from './components/message-concurrentuser-nomaster/message-concurrentuser-nomaster.component';
import { FavoriteTeamsBannerComponent } from './components/favorite-teams-banner/favorite-teams-banner.component';
import { MessageMultiEntitlementComponent } from './components/message-multi-entitlement/message-multi-entitlement.component';
import { MessageVecimaComponent } from './components/message-vecima/message-vecima.component';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
import { ToggleSessionTvComponent } from './components/toggle-session-tv/toggle-session-tv.component';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import { CardFormComponent } from './components/card-form/card-form.component';
import { MatSelectModule } from '@angular/material/select';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCleaveDirectiveModule } from 'ngx-cleave-directive';
import { BillingFormControlComponent } from './components/card-form/billing-form-control/billing-form-control.component';
import { SwiperModule } from 'swiper/angular';
import {MatRadioModule} from '@angular/material/radio';

@NgModule({
  declarations: [
    MainMobileToolbarComponent,
    BannerComponent,
    LiveNowCardsComponent,
    UpcomingEventsComponent,
    RepetitionsComponent,
    RecommendedComponent,
    NewsComponent,
    OtherContentComponent,
    FavoritesComponent,
    RecommendedV2Component,
    FinderWebComponent,
    FinderWebTVComponent,
    ReadDocumentJsonComponent,
    CustomKeyboardComponent,
    ProgressLoadComponent,
    MatchResultsComponent,
    MyRecommendedComponent,
    RequestPermissionComponent,
    MyCardsComponent,
    MessageDialogComponent,
    MessageSubscriptionComponent,
    MessageDialogsubscriptionComponent,
    MessageDeleteComponent,
    CardFormComponent,
    BillingFormControlComponent,
    MessageConcurrentUserMasterComponent,
    MessageConcurrentUsermasterDeviceComponent,
    MessageConcurrentuserNomasterComponent,
    MessageDialogSelectUserMasterComponent,
    MessageDeleteDeviceComponent,
    MessageToastComponent,
    FavoriteTeamsBannerComponent,
    MessageMultiEntitlementComponent,
    MessageVecimaComponent,
    TermsConditionsComponent,
    ToggleSessionTvComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MarkdownModule,
    IonicModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatFormFieldModule,
    NgxCleaveDirectiveModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    SwiperModule,
    MatRadioModule,
  ],
  exports: [
    MainMobileToolbarComponent,
    BannerComponent,
    LiveNowCardsComponent,
    UpcomingEventsComponent,
    RepetitionsComponent,
    RecommendedComponent,
    NewsComponent,
    OtherContentComponent,
    FavoritesComponent,
    RecommendedV2Component,
    FinderWebComponent,
    FinderWebTVComponent,
    ReadDocumentJsonComponent,
    CustomKeyboardComponent,
    ProgressLoadComponent,
    MatchResultsComponent,
    MyRecommendedComponent,
    RequestPermissionComponent,
    MyCardsComponent,
    MessageDialogComponent,
    MessageDialogsubscriptionComponent,
    CardFormComponent,
    BillingFormControlComponent,
    MessageConcurrentUserMasterComponent,
    MessageDialogSelectUserMasterComponent,
    MessageDeleteDeviceComponent,
    MessageToastComponent,
    FavoriteTeamsBannerComponent,
    MessageMultiEntitlementComponent,
    MessageVecimaComponent,
    TermsConditionsComponent,
    ToggleSessionTvComponent
  ]
})
export class SharedModule {
}
