import {Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { CREDIT_CARDS, DEFAULT_CARD } from './constants/card.constants';
import { ValidateAccountNumberLength, ValidateCardType, ValidateCardExpiration, ValidateIcon, ValidateErrors, CreditCard } from './validators/card.validators';
import { TokenizationService } from 'src/app/services/tokenization.service';
import { LoadingService } from 'src/app/services/loading.service';
import { NavController } from '@ionic/angular';
import { BillingFormBuilder } from './base-builder/billing-form-builder';
import { BillingControlBase } from './base-model/billing-control-base';
import { BillingService } from 'src/app/services/billing-service';
import {SegmentAnalytics} from '../../../core/providers/segment-analytics';
import {
  EVENT_TRACK_093,
  EVENT_TRACK_123,
  EVENT_TRACK_125,
  EVENT_TRACK_132
} from '../../../config/analytics/events-track';
import {SuscriptionService} from '../../../services/suscription.service';
import {Storage} from '../../../core/storage/storage';
import { PaymentService } from 'src/app/services/payment.service';
import {TextManagementService} from '../../../services/text-management.service';
import {MessageDialogService} from '../../../services/message-dialog.service';
import {environment} from '../../../../environments/environment';
import {PaymentSessionRegisterService} from '../../../services/payment-session-register.service';
import {AES, enc} from 'crypto-ts';
import {SecurityHelper} from '../../../core/helpers/security.helper';

@Component({
  selector: 'app-card-form',
  templateUrl: './card-form.component.html',
  styleUrls: ['./card-form.component.scss'],
})
export class CardFormComponent implements OnInit, OnChanges {
  eventtrack093 = EVENT_TRACK_093;
  automaticpayment = false;
  creditCards = [
    {
        type: 'visa',
        icon: '../../../../assets/graphics/payment/visa.svg'
    },
    {
        type: 'mastercard',
        icon: '../../../../assets/graphics/payment/mastercard.svg',
    },
    {
        type: 'american-express',
        icon: '../../../../assets/graphics/payment/AmericanExpress.svg',
    }
];

cardIcon = DEFAULT_CARD;
form: UntypedFormGroup;
control: boolean;
country_code = '';
controls: BillingControlBase<string>[] = [];
format_billing: Array<any>;
first_selected: string;
key_reference = '';
@Input() formType: string;
@Input() isPayment: boolean;
@Input() idoffer: string;
//@Output() cardForm = new EventEmitter();
@Output() newCards = new EventEmitter();
accountNumberValidation = {
  valid: true,
  message: ''
};
record = {
  type: false,
  status: false,
  data: null
};
turnofsucription: any = {};
messagecard: any =  {};
options = [false, false];
infocard: any = {};

isTigoMobile = false;
   block = 0;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private tokenizationService: TokenizationService,
    private loadingService: LoadingService,
    private navController: NavController,
    private billingService: BillingService,
    private billingFormBuilder: BillingFormBuilder,
    private segmentanalytics: SegmentAnalytics,
    private suscriptionservice: SuscriptionService,
    private storage: Storage,
    private paymentService: PaymentService,
    private textManagementService: TextManagementService,
    private msgService: MessageDialogService,
    private paymentSessionRegService: PaymentSessionRegisterService,
    private security: SecurityHelper
    ) {
    this.getMessageCards();
    this.loadingService.setLoading(true, 'card-form');
    this.form = this.formBuilder.group({
      isNew: [true],
      accountNumber: ['', [Validators.required, ValidateCardType, ValidateAccountNumberLength]],
      cardExpiration: ['', [Validators.required, ValidateCardExpiration, Validators.minLength(5)]],
      cvv: [{ value: '', disabled: false }],
      customerName: ['', Validators.required],
      // registrationEmail: ['',Validators.required],
      type: ['']


    });
    const user: any = this.storage.getUser();
    this.isTigoMobile = this.validateUserType(user);
    if(this.isTigoMobile){
    this.form.addControl('registrationEmail', new UntypedFormControl('', [Validators.required, Validators.email]));
    }
  }


  async ngOnInit() {
    this.validateAccountNumber();
    // const user: any = this.storage.getUser();
    // this.isTigoMobile = this.validateUserType(user);
    await this.validateenrollment();
    this.control = true;

    // if (this.formType == 'full' || this.formType == 'new') {
      this.format_billing = await this.billingService.getFormControls();
      //first country
      this.first_selected = this.format_billing[0].country ? this.format_billing[0].country : '';
      this.key_reference = this.format_billing[0].options[0].key ? this.format_billing[0].options[0].key : '';
      this.country_code = this.format_billing[0].code ? this.format_billing[0].code : '';
      this.controls = this.format_billing[0].options ? this.format_billing[0].options : '';
      this.form = this.billingFormBuilder.addControl(this.controls, this.form);
    // }
  }

  /* ngonchange for offset */
  ngOnChanges(change: SimpleChanges): void {
    if (change) {
      if (change.idoffer) {
        this.idoffer = change.idoffer.currentValue;
      }
    }
  }

  async getMessageCards() {
    try {
      const { data }: any = await this.textManagementService.getPaymentConfig();
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const OptionCards: any = data.messagecards;
      this.turnofsucription = await this.textManagementService.getTurnoPayment();
      this.messagecard = OptionCards;
      this.infocard = data.infocard;
      this.loadingService.setLoading(false, 'card-form');
    } catch (e) {
      console.error(e);
    }
  }

  validateUserType(user: any){
    const {phone_number} = user;
    return phone_number != '';
   }



  private validateAccountNumber(): void {
    this.form.controls.accountNumber.valueChanges.subscribe(value => {
      this.cardIcon = ValidateIcon(value);
      if (value) {
        const { valid, message } = ValidateErrors(this.form.controls.accountNumber);
        this.accountNumberValidation = { valid, message };
        this.addSizeCvv(valid, value);
      }
    });
  }

  private addSizeCvv(valid: boolean, value: any): void {
    const cvv = this.form.controls.cvv;
    const validators = [Validators.required];
    if (valid) {
      const { code, type } = CreditCard(value);
      this.form.controls.type.setValue(type);
      this.block = code.size;
      validators.push(Validators.minLength(this.block));
      cvv.enable();
    } else {
      cvv.reset();
      cvv.disable();
    }
    cvv.setValidators(validators);
  }

  async close(){
    const id = this.getProductId();
    let userData: any = this.storage.getUser() ? this.storage.getUser() : this.storage.getUserCarrierBilling();
    userData = userData ? (userData.phone_number ? userData.phone_number : userData.email): 'anon';
    const obj = {
      product_id: id,
      journey: 'ppv',
      automatic_payment_enabled: this.turnofsucription.suscription === 'true' || this.options[0],
      product_name: await this.getInfoProduct(id, 'product_name'),
      product_category: 'ppv',
      product_variant: this.turnofsucription.suscription === 'true' ? 'subscription' : 'pvv',
      invoice_selected: this.options[1],
      product_price: await this.getInfoProduct(id, 'amount'),
      payment_method: 'credit_card',
      order_id: '',
      email_invoice: userData,
      amount: await this.getInfoProduct(id, 'amount'),
    };
    this.segmentanalytics.eventTrack(EVENT_TRACK_125, obj);
    this.navController.back();
  }

  getProductId() {
    let id = '';
    if (this.turnofsucription.pvv === 'true' && this.idoffer) {
      const { PYT_VALUE} = environment;
      const secret = enc.Utf8.parse(this.security.decrypt(PYT_VALUE,1));
      id = AES.decrypt(this.idoffer.toString(), secret).toString(enc.Utf8);
    } else {
      id = '';
    }
    return id;
  }

 async getInfoProduct(id: string, event: string) {
    let value = '';
    const paymentOptions = await this.textManagementService.getPaymentConfig();
    switch (event) {
      case 'product_name':
        if (this.turnofsucription.suscription === 'true') {
          const { MessagesPaymentMethodGeneral } = paymentOptions?.data;
          value = MessagesPaymentMethodGeneral?.messageAccess;
        } else {
          const { OfferSelectionOption } = paymentOptions?.data;
          const data = OfferSelectionOption.PaymentOffer.find((E: any) => E.idAccess === id);
          value = data?.name;
        }
        break;
      case 'amount':
        if (this.turnofsucription.suscription === 'true') {
          const { MessagesPaymentMethodGeneral } = paymentOptions?.data;
          value = MessagesPaymentMethodGeneral?.messageAmount;
        } else {
          const { OfferSelectionOption } = paymentOptions?.data;
          const data = OfferSelectionOption.PaymentOffer.find((E: any) => E.idAccess === id);
          value = data?.total;
        }
        break;
    }
    return value;
  }


  public async subscribe(): Promise<void>{
    let deviceFingerId: any;
    let cards: any;
    this.form.value.billingForm.country = this.country_code ? this.country_code : '';
    try {
     this.loadingService.setLoading(true,'send-card');
      const deviceFingerId = environment.PYT_TYPE === 'cybersource' ?
        await this.paymentSessionRegService.generateDeviceFingerprintId() : await this.paymentService.generateDeviceFingerPrint();
        const objSegment = {
          product_id: '',
          journey: 'ppv',
          invoice_selected: false,
          automatic_payment_enabled: this.turnofsucription.suscription === 'true',
          payment_method: 'credit_card',
          product_category: 'ppv',
          product_name: this.turnofsucription.suscription === 'true' ? 'Suscripción' : 'Pagos Unicos',
          product_variant: 'Packages',
          product_price: ''
      };
      this.segmentanalytics.eventTrack(EVENT_TRACK_123, objSegment).then();
      if (this.turnofsucription.pvv === 'true' && this.idoffer) {
        if (this.options[0]) {
          cards =  await this.tokenizationcards(this.form.value);
          if (this.isPayment === true && cards) {
            await this.paymentService.sendPurchaseSingle(cards[cards.length - 1],this.form.value.cvv,deviceFingerId,this.idoffer);
          }
        } else {
          if (this.isPayment === true) {
            await this.paymentService.sendPurchaseSingle(null,'',deviceFingerId,this.idoffer, this.form.value);
          }
        }
      } else if (this.turnofsucription.suscription === 'true' && !this.idoffer) {
        const objPayment = {
          journey: 'ppv',
          payment_account: this.form.value?.accountNumber
        };
        this.segmentanalytics.eventTrack(EVENT_TRACK_132, objPayment).then();
        cards =  await this.tokenizationcards(this.form.value);
        if(this.isPayment === true && cards) {
          await this.paymentService.sendPurchase(cards[cards.length - 1],this.form.value.cvv,deviceFingerId);
      }
    }
    /*if(this.isPayment == true && cards){
    const deviceFingerId = await this.paymentService.generateDeviceFingerPrint();
    if (this.turnofsucription === 'true' && this.idoffer) {
      await this.paymentService.sendPurchaseSingle(cards[cards.length - 1],this.form.value.cvv,deviceFingerId,this.idoffer);
    } else {
      await this.paymentService.sendPurchase(cards[cards.length - 1],this.form.value.cvv,deviceFingerId);
     }
    }*/
    const datasegment = {
      journey: 'ppv',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      card_type: 'Credit',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      card_expire_date: `${this.form.get('cardExpiration').value}`,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      automatic_payment_enabled: this.automaticpayment
    };
    await this.segmentanalytics.eventTrack(this.eventtrack093, datasegment);
    this.form.reset();
    this.loadingService.setLoading(false,'send-card');
    if (cards) {
      this.newCards.emit(cards);
    }
    }catch(e) {
      this.loadingService.setLoading(false,'send-card');
      this.loadingService.setLoading(false,'purchase');
      this.errorMessage();
      this.form.reset();
      console.error(e);
    }
  }

  errorMessage() {
    this.textManagementService.getPaymentConfig().then(paymentOptions => {
      const messageError = paymentOptions.data.paymentFailed;

      const img = 'assets/graphics/payment/transaction-error.svg';
      this.msgService.messageDialogSuscroption(
        messageError.title,
        messageError.message,
        img,'',
        messageError.buttonMessage
      );
    });
  }

  selectChange(param): void {
    const item_selected = this.format_billing.find(i => i.country === param);
    this.country_code = item_selected.code;
    this.key_reference = item_selected.options[0].key;
    this.controls = item_selected.options;
    this.form = this.billingFormBuilder.addControl(this.controls, this.form);
    this.formValidator();
  }

  formValidator(): void {
    // this.form.statusChanges.subscribe(status => {
    //   if (status === 'VALID') {
    //     this.record.status = true;
    //   } else {
    //     this.record.status = false;
    //   }
    //   if(this.control){
    //     this.record.status = true;
    //   }
    //   this.record.data = this.form.value;
    //   if(this.record.data.billingForm && this.record.data.billingForm[this.key_reference] != ""){
    //     this.record.data.billingForm.country = this.country_code;
    //   }
    //   this.cardForm.emit(this.record);
    // });
  }

  private async validateenrollment() {
    const user = await this.storage.getUser();
    if (user) {
      const { id } = user;
      const iduser = id.replace(/-/g, '');
      const response: any = await this.suscriptionservice.getsuscription(iduser);
      if (response.httpStatusCode === 200 && response.body) {
        this.automaticpayment = true;
      } else {
        this.automaticpayment = false;
      }
    }
  }

  public async selectedOption(opt, value) {
    this.options[opt] = !value;
    if (!this.options[1]) {
      this.form.get('billingForm').get('firstName').setValue('');
      this.form.get('billingForm').get('lastName').setValue('');
      this.form.get('billingForm').get('documentType').setValue('');
      this.form.get('billingForm').get('documentNumber').setValue('');
    }
  }

  private async tokenizationcards(data) {
    await this.tokenizationService.sendTokenizationCard(data);
    return  await this.tokenizationService.getCreditCards();
  }

  public openTermsConditions() {
    this.msgService.messageTermsConditions('assets/graphics/options/tabs/Close.svg').then();
  }

}
