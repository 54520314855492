import {Injectable} from '@angular/core';
import {Storage} from '../core/storage/storage';
import {environment} from '../../environments/environment';
import {COUNTRIES} from '../config/constants';
import {Location} from '../core/interfaces/location';
import {HttpClient} from '@angular/common/http';
import {LOCATION} from '../config/endpoints';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(
    private storage: Storage,
    private httpClient: HttpClient) {
  }

  /* Get location from middleware */
  public async locate(): Promise<void> {
    const {GENERAL_PROJECT, COUNTRY} = environment;
    const locationData = await this.getLocation();

    if (GENERAL_PROJECT) {
      this.saveLocation(locationData.country, locationData.ip, locationData.country);
    } else {
      this.saveLocation(COUNTRY, locationData.ip, locationData.country);
    }
  }

  /* Get current location based on user's ip */
  public async getLocation(): Promise<any> {
    const locationData = {
      ip: '',
      country: '',
      city: '',
      region: ''
    };
    let response: any;

    try {
      const data: any = await this.storage.getLocationUser();
      if (!data) {
        response = await this.httpClient.get(LOCATION).toPromise();
        if (response && response.countryCode) {
          this.storage.setLocationUser(response);
        }
      } else {
        const day = moment().diff(data.createdAt, 'day');
      //  const hours = moment().diff(data.createdAt, 'hours');

         if (day >= 1) {
       // if (hours > 2) {
          response = await this.httpClient.get(LOCATION).toPromise();
          this.storage.setLocationUser(response);
        }
        response = data.data;
      }

      if (response) {
        locationData.ip = response.ip;
        locationData.country = response.countryCode;
        locationData.city = response.city_name;
        locationData.region = response.region_name;
      }
      return locationData;
    } catch (e) {
      return false;
    }


  }

  /* Set current location in storage */
  private saveLocation(country: string, ip: string, originCountry: string): boolean {
    let status = false;
    const location: Location = this.determineLocationMode(country);
    if (location) {
      location.ip = ip;
      location.originCode = originCountry;
      this.storage.setLocation(location);
      status = true;
    }

    return status;
  }

  /* Define location mode, if app environment is DEBUG then add to country code 'DEV' */
  private determineLocationMode(country: string): Location {
    let location: Location;
    const environmentVersionStorage = this.storage.getEnvironmentVersion();
    const environmentVersionConstant = environment.PROCESS_VERSION;
    const storedLocation: Location = this.storage.getLocation();

    if (storedLocation && (environmentVersionStorage === environmentVersionConstant)) {
      location = storedLocation;
    } else {
      location = COUNTRIES.find((e) => e.productionCode === country);
      const {APP_DEBUG} = environment;

      if (location) {
        location.code = (APP_DEBUG ? location.debugCode : location.productionCode);
      }
      this.storage.setEnvironmentVersion(environmentVersionConstant);
    }

    return location;
  }
}


