import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { NEWRELIC } from "../../config/scripts";
import { Platform } from "@ionic/angular";
import {Capacitor} from '@capacitor/core';
import { IS_ADAPTABLE } from "src/app/config/constants";
@Injectable({
    providedIn: "root"
})

export class ScriptNewRelicUtil {
    private renderer: Renderer2;
    constructor(
        rendererFactory: RendererFactory2,
        private platform: Platform,
        @Inject(DOCUMENT) private document
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    public register(): void {
        if (this.platform.is('android') || this.platform.is('desktop') || this.platform.is('mobileweb'))
            this.registerNewRelicScript();
    }

    private registerNewRelicScript(): void {
        let platform = 'tv'
        if(IS_ADAPTABLE){
        platform = Capacitor.getPlatform() === 'web' ? Capacitor.getPlatform() : 'android';
        }
        const url = `${NEWRELIC}${platform}`;
        const script = this.renderer.createElement('script');
        script.src = url;
        this.renderer.appendChild(this.document.head, script);
    }

}