import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {DELETESUSCRIPTIONS, GETSUSCRIPTIONS, SUSCRIPTIONS, GETTRANSACTIONINFO} from '../config/endpoints';
import { Storage } from 'src/app/core/storage/storage';

@Injectable({
  providedIn: 'root'
})
export class SuscriptionService {

  constructor(
    private httpClient: HttpClient,
    private storage: Storage
  ) { }

  get(entity: string, query: string) {

    const user = this.storage.getUser();
    //let uuid = 'b719a74085fb11ec84789567d5d86abd';
    let uuid = '';
    if (user) {
      uuid = user.uniqueIdentifier ? user.uniqueIdentifier : user.uuid;
      uuid = uuid.replace(/-/g, '');
    }

    const url = `${SUSCRIPTIONS}/${entity}/${uuid}/${query}`;

    const headers: any = {
      'Content-Type': 'application/json',
    };
    return this.httpClient.get(url, { headers });
  }


  getsuscription(id: string) {
    const url = `${GETSUSCRIPTIONS}/${id}`;
    return this.httpClient.get(url, {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      }
    }).toPromise();
  }

  deletesuscription(id: string, enrollid: string) {
    const url = `${DELETESUSCRIPTIONS}/${id}/${enrollid}`;
    return this.httpClient.delete(url, {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      }
    }).toPromise();
  }

  gettransaction(uuid: string, country: string) {
    const url = `${GETTRANSACTIONINFO}`;
    const obj = {
      uuid,
      country
    };
    return this.httpClient.post(url, obj, {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      }
    }).toPromise();
  }


}
