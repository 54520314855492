import { Injectable } from '@angular/core';

import {
  Capacitor
} from '@capacitor/core';
import { BrazeService } from './braze.service';
import {
  PushNotificationSchema,
  ActionPerformed,
  Token, PushNotifications
} from '@capacitor/push-notifications';
import { SegmentAnalytics } from '../core/providers/segment-analytics';
import { EVENT_TRACK_059 } from '../config/analytics/events-track';
import { Storage } from '../core/storage/storage';
import * as appboy from '@braze/web-sdk';
import { environment } from 'src/environments/environment';
import { Device } from '@capacitor/device';
import {MatDialog} from '@angular/material/dialog';
import { RequestPermissionComponent } from '../shared/components/request-permission/request-permission.component';
import {SecurityHelper} from './../core/helpers/security.helper';
import * as AppboyPlugin from 'appboy-cordova-sdk';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(
    private brazeService: BrazeService,
    private storage: Storage,
    private segmentAnalytics: SegmentAnalytics,
    public dialog: MatDialog,
    private router: Router,
    private security: SecurityHelper) {
  }

  public async enablePush(): Promise<void> {
    await this.brazeService.register();
  }

  public async disablePush() {
    await this.brazeService.disable();
  }

  /* Starts the process to request authorization from the user */
  public async initPush(): Promise<void> {
    if (Capacitor.getPlatform() === 'web') {
      return;   // Temporarily disabled
      const { BW_API_PIX, BW_BASE, SAFARI_WEBSITE_PUSH_ID } = environment;
      appboy.initialize(this.security.decrypt(BW_API_PIX,1), {
        baseUrl: this.security.decrypt(BW_BASE,1),
        safariWebsitePushId: SAFARI_WEBSITE_PUSH_ID,
        enableLogging: true
      });

      console.log(appboy.isPushBlocked());
      console.log(appboy.isPushSupported());
      if (!appboy.isPushBlocked() && appboy.isPushSupported()) {
        await this.registerWebPush();
      }
        // const deviceInfo = await Device.getInfo();
        // const os = deviceInfo.operatingSystem;
        // if (os === 'mac') {
        //   Notification.requestPermission(async (permission) => {
        //     if (permission === 'granted') {
        //       await this.registerWebPush();
        //     }
        //   });
        // } else {
        //   Notification.requestPermission().then(async (permission) => {
        //     if (permission === 'granted') {
        //       await this.registerWebPush();
        //     }
        //   });
        // }
    } else {
      this.registerNativePush();
    }
  }

  public async registerWebPush(): Promise<void> {
    if (appboy.isPushPermissionGranted()) {
      await this.brazeService.register();
    } else {
    //  this.dialog.open(RequestPermissionComponent);
    }
  }

  /* If user gives authorization, then add listener for every kind of notifications */
  public registerNativePush() {
    PushNotifications.requestPermissions().then(async (permission: any) => {
      if (permission.receive === 'granted') {
        const conf: string = this.storage.getNotificationsFlag();
        if (conf === 'true') {
          await this.brazeService.register();
        }
        PushNotifications.register();
      }
    });

    PushNotifications.addListener(
      'registration',
      (token: Token) => {
        if (Capacitor.getPlatform() === 'ios') {
          AppboyPlugin.setRegisteredPushToken(token.value);
        }
      }
    );

    PushNotifications.addListener('registrationError', (error: any) => { });

    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
      }
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification: ActionPerformed) => {
        const data = notification.notification.data;
        if (data && data?.ab_uri) {
          const item = data?.ab_uri.replace(/.*?:\/\//, '');
          if (item) {
            console.log(item);
            let route = '';
            const authenticated: string = this.storage.getAccessToken();
            if (item === 'carrier-billing-providers') {if (!authenticated) {route = '/-/TiposProveedores';}}
            else if (item === 'home') {if (!authenticated) {route = '/inicio';}}
            else {route = `/${item}`;}
            await Device.getInfo();
            await this.router.navigateByUrl(route);
          }
        }
        await this.segmentAnalytics.eventTrack(EVENT_TRACK_059, { flag: data?.title });
      }
    );
  }

}
