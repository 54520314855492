<div class="text-center">
  <div class="text-position">
    <img src="assets/graphics/options/tabs/Close.svg" class="close" (click)="close()" alt="ico">
  </div>
  <br>
  <div class="text-position">
    <p class="title">{{data?.objContent?.title}}</p>
    <p class="subtitle">{{data?.objContent?.message}}</p>
  </div>
  <div class="row">
    <div class="col">
      <button class="continue-button" (click)="close()">{{data?.objContent?.closeButton}}</button>
    </div>
  </div>
</div>