export const ENTITLEMENT = {
  SUCCESS_RESPONSE: 'ok',
  ERROR_RESPONSE: 'error',
  FREE_TYPE: 'Free, No Registration Required',
  NOT_FREE_TYPE: 'Not Free',
  AUTHENTICATED_FREE_TYPE: 'Free, Registration Required'
};
export const MESSAGES = {
  NETWORK: 'La conexión a la red no esta disponible. Verifica nuevamente más tarde.',
  PENDING_EVENT: 'El evento aún no ha comenzado. Verifica el horario e ingresa nuevamente.',
  EVENT_NOT_AVAILABLE: 'El evento no está disponible.',
  PREMIUM_RESTRICTION: 'Para disfrutar contenido premium debes tener autorización.',
  REPLAY_PENDING: 'El juego ha finalizado. Próximamente podrás disfrutar la repetición en la sección Juegos en Vivo.' +
    ' Intenta más tarde.',
  NOT_AVAILABLE_REGION: 'Este evento no está disponible en tu región.',
  PURCHASE_NOT_AVAILABLE: 'No esta disponible realizar una compra en tu región',
  HEADER_EVENT_NOT_AVAILABLE: 'No puedes acceder al evento',
  DEVICE_RESTRICTION: 'Su usuario únicamente permite la visualización de partidos en 1 dispositivo, debe ir a configuración y gestionar los permisos de dispositivos.',
  DEVICE_NOT_REGISTERED: 'Su dispositivo no ha podido ser registrado, para continuar primero debe completar esta operación',
  ACTIVE_DEVICES_LIMIT: 'Lo sentimos, has llegado al máximo de dispositivos activos permitidos, debes desactivar o eliminar uno para poder continuar'
};
