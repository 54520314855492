import {Component, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalController, NavController} from '@ionic/angular';
import {DevicesService} from '../../../services/devices.service';
import {TextManagementService} from '../../../services/text-management.service';
import {LoadingService} from '../../../services/loading.service';
import {Storage} from '../../../core/storage/storage';
import {MessageDialogService} from '../../../services/message-dialog.service';
import {IS_ADAPTABLE} from '../../../config/constants';

@Component({
  selector: 'app-message-concurrent-user-master',
  templateUrl: './message-concurrent-user-master.component.html',
  styleUrls: ['./message-concurrent-user-master.component.scss'],
})
export class MessageConcurrentUserMasterComponent  implements OnInit, OnDestroy {


  activeDevices: any = [];
  text: any = {};
  isadaptable = IS_ADAPTABLE;

  @Input() data: any;
  constructor(private modalCtrl: ModalController,
              private deviceservice: DevicesService,
              private textmanagementservice: TextManagementService,
              private loadingService: LoadingService,
              private storage: Storage,
              private msgService: MessageDialogService) { }

  ngOnInit() {
    this.getDeviceActive();
    this.getText();
  }

  ngOnDestroy(): void {}

  dismissModal() {
    this.modalCtrl.dismiss();
  }

  async getText() {
    const text: any = await this.textmanagementservice.getTexts();
    this.text = text?.MASTERUSERCONCURRENT;
  }

  getDeviceActive() {
    try {
      this.loadingService.setLoading(true, 'loadUserConcurrentUser');
      const devices = this.deviceservice.getAllDeviceActive();
      devices.then((res: any) => {
        if (res && res.length > 0) {
          this.activeDevices = res;
          this.activeDevices = this.moveChromecastToEnd(this.activeDevices);
          this.deviceservice.setDeviceActive(this.activeDevices);
          this.loadingService.setLoading(false, 'loadUserConcurrentUser');
        }
      });
    } catch (e) {
      this.close();
      console.error(e);
    }
  }

  moveChromecastToEnd(devices) {
    devices.map((ad, index) => {
      if(ad?.contentView?.includes('(Chromecast)')){
          devices.splice(index, 1);
          devices.push(ad);
      }
    });
    return devices;
  }

  close() {
    this.modalCtrl.dismiss({cancel: true});
  }


  async play() {
    const modalSession: any = await this.modalCtrl.getTop();
    if (modalSession) {
      modalSession.dismiss();
    }
    const responseModal = await this.msgService.messageConcurrentuserMasterDevice(this.data?.objContent);
    if (!this.isadaptable) {
      if (responseModal?.data?.cancel || responseModal?.role === 'backdrop') {
        this.deviceservice.setFlagControllerTv(false);
      }
    }
   /* if (responseDevice.data?.cancel === true || responseDevice.data?.role === 'backdrop') {
      this.navController.back();
    } */
  }

}
