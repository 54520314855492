import { Component, OnInit } from '@angular/core';
import {TextManagementService} from '../../../services/text-management.service';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-toggle-session-tv',
  templateUrl: './toggle-session-tv.component.html',
  styleUrls: ['./toggle-session-tv.component.scss'],
})
export class ToggleSessionTvComponent  implements OnInit {
  text: any = {};

  constructor( private textManagementService: TextManagementService,
               private modalCtrl: ModalController) { }

  ngOnInit() {
    this.getText().then();

  }


  async getText() {
    const text: any = await this.textManagementService.getTexts();
    this.text = text?.LOGOUTMODALTV;
  }

  close() {
    this.modalCtrl.dismiss({closeSession: false}).then();
  }

  closeSession() {
    this.modalCtrl.dismiss({closeSession: true}).then();
  }

}
