import {Injectable} from '@angular/core';
import {AuthOption} from '../core/interfaces/auth-option';
import {nanoid} from 'nanoid';
import {ConfigurationsService} from './configurations.service';
import {Storage} from '../core/storage/storage';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import {AuthService} from './auth.service';
import {Capacitor} from '@capacitor/core';
import {ActivateCodeService} from './activate-code.service';
import {ACCESS_TOKEN, ACCESS_TOKEN_AUTH0, ACCESS_TOKEN_HE} from '../config/endpoints';
import { SegmentAnalytics } from '../core/providers/segment-analytics';
import { EVENT_TRACK_012, EVENT_TRACK_013 } from '../config/analytics/events-track';
import {DevicesService} from './devices.service';
import { Router } from '@angular/router';
import {environment} from '../../environments/environment';
import {AppVersion} from '@awesome-cordova-plugins/app-version/ngx';
import {Auth0Helper} from '../core/helpers/auth0.helper';

@Injectable({
  providedIn: 'root'
})
export class TigoIdService {
  TIGO_ID = 'ott_tigo_sports_tigo_id_authorize_';

  constructor(
    private configurationService: ConfigurationsService,
    private storage: Storage,
    private httpClient: HttpClient,
    private authService: AuthService,
    private activatedCodeService: ActivateCodeService,
    private segmentAnalytics: SegmentAnalytics,
    private devicesService: DevicesService,
    private appVersion: AppVersion,
    private auth0Helper: Auth0Helper
  ) {
  }
  /* Get Tigo ID URL */
  public async getUrlTigoID(option: AuthOption,loginType="cognito"): Promise<string> {
      const settings: any = await this.getTigoIDSettings(loginType);
      let url = settings ? settings.url : '';
      const anonId = this.storage.getAnonymousIdSegment();

      if (url) {
        url = url.replace('OPTION_SCOPE', option.scope);
        url = url.replace('SESSION_NONCE', nanoid());
        url = url.replace('ANONYMOUS_ID', anonId);
        url = url.replace('FLAGS_PARAMS', option.flags);
      }

    return url;
  }

  /* Get TIGO ID Settings */
  public async getTigoIDSettings(loginType="cognito"): Promise<string> {
    let settings: any;

    try {
      const response = await this.configurationService.getConfigurationByVariable(this.TIGO_ID+loginType);
      settings = response ?  this.storage.getIsWebView() === 'true' ? response.movil : (Capacitor.getPlatform() === 'web' ? response.web : response.movil) : '';
    } catch (e) {
      const objSegment = {
        journey: 'login TigoID Settings',
        error_id: e?.status ? e?.status : '',
        error_message: e?.message ? e?.message : '',
        error_type: e?.error?.msg ? e?.error?.msg : ''
      };
      this.segmentAnalytics.eventTrack(EVENT_TRACK_013, objSegment).then();
    }

    return settings;
  }

  /* Generate new Tigo ID Token */
  public async generateTokenTigoID(code: string, type?: string): Promise<any> {
    let auth: any;
    type = type ? type : this.authService.getAuthType();
    const tvAuthState = this.activatedCodeService.getTVAuthState();
    const isAuth0 = Boolean(this.authService.getOptionLogin()?.webviewUrlAuth0);
    const isHEPhone = Boolean(this.authService.getOptionLogin()?.webviewUrlHE);

    try {
      const platform = Capacitor.getPlatform() === 'web' ? Capacitor.getPlatform() : 'movil';
      const url = `${isAuth0 ? ACCESS_TOKEN_AUTH0 : isHEPhone ? ACCESS_TOKEN_HE : ACCESS_TOKEN}/${platform}`;
      const deviceInfo = await this.devicesService.getDeviceData();
      deviceInfo.code = code;
      deviceInfo.versionApp = Capacitor.getPlatform() === 'web' ? environment.WEB_VERSION : await this.appVersion.getVersionNumber();
      deviceInfo.codeChallenge = this.storage.getOptionLogin().codeVerifier;
      let loginType = 'cognito';
      if(tvAuthState && this.storage.getLoginType()){
         loginType = this.storage.getLoginType();
         this.storage.removeLoginType();
      }
      auth = await this.httpClient.post(url,deviceInfo,{
        headers: {
          'platform': tvAuthState ? 'tv':'none',
          'login' : loginType
        }
      }).toPromise();

      if (auth && !tvAuthState) {
        await this.authService.setAuthentication(auth, type);
        this.storage.setMiddleAuth(auth.accessToken);
        this.segmentAnalytics.identifyCustomer();
        await this.segmentAnalytics.eventTrack(EVENT_TRACK_012, { journey: "login"});
      } else {
        const version4 = this.storage.getVersionTv();
        if(version4 === 'v4'){
          await this.activatedCodeService.activateCodeV4(auth);
        } else {
          await this.activatedCodeService.activateCodeV3(auth);
        }
      }
    } catch (e) {
      const objSegment = {
        journey: 'login tigoIdSv',
        error_id: e?.status ? e?.status : '',
        error_message: e?.message ? e?.message : '',
        error_type: e?.error?.msg ? e?.error?.msg : ''
      };
      this.segmentAnalytics.eventTrack(EVENT_TRACK_013, objSegment).then();
    }

    return auth;
  }


  public async generateUrlAuthorizeHE(urlHE: string) {
    let verifier = '';
    try {
      const params = new URL(String(urlHE));
      verifier = this.auth0Helper.createRandomStringHE();
      const challengeBuffer = await this.auth0Helper.sha256Auth0(verifier);
      const codeChallenge = this.auth0Helper.bufferToBase64UrlEncodedAuth0(challengeBuffer);
      const numbers = Math.floor(Math.random() * (30 - 1 + 1) + 1);
      const state = this.auth0Helper.generateStateHE(numbers);
      params.searchParams.set('nonce', nanoid());
      params.searchParams.set('code_challenge', codeChallenge);
      params.searchParams.set('state', state);
      urlHE = params.toString();
    } catch (e) {}
    return { urlHE, verifier };
  }

}
