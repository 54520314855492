import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ModalController, NavController, ToastController} from '@ionic/angular';
import {ToastOptions} from '@ionic/core';
import {ENTITLEMENT} from '../config/settings';
import {Storage} from '../core/storage/storage';
import {EntitlementService} from './entitlement.service';
import {GeolocationService} from './geolocation.service';
import {PlayerService} from './player.service';
import {DRM_TOKEN_V2,LOGS_DRM} from '../config/endpoints';
import {DeviceHelper} from '../core/helpers/device.helper';
import {Capacitor} from '@capacitor/core';
import {HttpClient} from '@angular/common/http';
import {Location} from '../core/interfaces/location';
import {LocationService} from './location.service';
import {SegmentAnalytics} from '../core/providers/segment-analytics';
import {EVENT_TRACK_038, EVENT_TRACK_076} from '../config/analytics/events-track';
import {BrowserService} from './browser.service';
import { User } from '../core/interfaces/user';
import { OTHER_TYPE_LOGIN } from '../config/analytics/properties';
import { TextManagementService } from './text-management.service';
import {ConfigurationsService} from './configurations.service';
import {AlertEntitlementPage} from '../public/alert-entitlement/alert-entitlement.page';
import {COUNTRY_CODE_KEY, IS_ADAPTABLE} from '../config/constants';
import {DevicesService} from './devices.service';
import { TextManagementV2Service } from './text-management-v2.service';
import { MessageDialogService } from './message-dialog.service';

@Injectable({
  providedIn: 'root'
})
export class VideoStreamingService {

  eventGroup = [];
  eventCategory = '';
  isAdaptable = IS_ADAPTABLE;
  onMultiEntitlement: any;
  /* Toast settings */
  toastTransmissionAvailable: ToastOptions = {
    message: '',
    duration: 3000,
    position: 'bottom',
    cssClass: 'tigo-toast',
    buttons: [
      {
        side: 'start',
        icon: 'time-outline',
        text: '',
      }, {
        icon: 'close-circle-outline',
        // role: 'cancel',
      }
    ]
  };
  toastNotTransmissionAvailableRegion: ToastOptions = {
    message: '',
    duration: 3000,
    position: 'bottom',
    cssClass: 'tigo-toast',
    buttons: [
      {
        side: 'start',
        icon: 'earth-outline',
        text: '',
      }, {
        icon: 'close-circle-outline',
        // role: 'cancel',
      }
    ]
  };
  turnofsucription: any = {};

  constructor(
    private playerService: PlayerService,
    private geolocationService: GeolocationService,
    private toastController: ToastController,
    private router: Router,
    private storage: Storage,
    private navController: NavController,
    private entitlementService: EntitlementService,
    private deviceHelper: DeviceHelper,
    private httpClient: HttpClient,
    private locationService: LocationService,
    private segmentAnalytics: SegmentAnalytics,
    private browserService: BrowserService,
    private textManagementService: TextManagementService,
    private textManagementV2Service: TextManagementV2Service,
    private configurationsService: ConfigurationsService,
    private modalController: ModalController,
    private deviceService: DevicesService,
    private msgService: MessageDialogService
  ) {
  }

  /* Start validation for streaming */
  async streamVideo(transmission: any) {
    const texts = await this.textManagementService.getTexts();
    const storedLocation: Location = this.storage.getLocation();
    const textsV2 = await this.textManagementV2Service.getTexts();
    this.onMultiEntitlement = textsV2?.MULTI_ENTITLEMENT;

    if (transmission.allowedRegion.includes('ALL')) {
      await this.programmingValidations(transmission);
    } else {
      const currentLocation: any = await this.locationService.getLocation();
      if (transmission.allowedRegion.includes(currentLocation.country)) {
        await this.programmingValidations(transmission);
      } else {
        this.toastNotTransmissionAvailableRegion.message = texts.NOT_AVAILABLE_REGION;
        await this.trackMessage(this.toastNotTransmissionAvailableRegion.message, 'info');
        const toast = await this.toastController.create(this.toastNotTransmissionAvailableRegion);
        toast.present();
      }
    }
  }

  /* Check for transmission type */
  async programmingValidations(transmission: any, ischromecast?: boolean) {
    this.playerService.setIsDRM(transmission.isDrm);
    const texts = await this.textManagementService.getTexts();
    const isApproved =  await this.approveReproduction(transmission);
    let avilable = true;
    this.playerService.setToken(transmission.token);
    if (transmission.isProgrammed) {
      const properties: any = {
        championship_id: transmission.championship_id,
        championship_name: transmission.championship_name,
        journey: 'user interface',
        sport_name: transmission.sport_name,
        airdate: transmission.airdate,
        content_id: transmission.content_id,
        video_title: transmission.title
      };
      await this.segmentAnalytics.eventTrack(EVENT_TRACK_038, {...properties});
      this.toastTransmissionAvailable.message = texts.PENDING_EVENT;
      avilable = false;
      await this.trackMessage(this.toastTransmissionAvailable.message, 'info');
      const toast = await this.toastController.create(this.toastTransmissionAvailable);
      toast.present();
    } else if (transmission.isReplay) {
      if (transmission.replayUrl) {
        this.playerService.setStreamingUrl(transmission.replayUrl);
        this.playerService.setTransmissionData(transmission);
      } else if (transmission.isDrm && (transmission.replayEndpoints || transmission.replayEndpoints)) {
        this.playerService.setStreamingUrlDash(transmission.replayEndpoints.streamingUrlDash);
        this.playerService.setStreamingUrlHls(transmission.replayEndpoints.streamingUrlHls);
        this.playerService.setTransmissionData(transmission);
        this.playerService.setWidevineSecurityLevel(transmission);
        this.playerService.setChannelNumber(transmission);
      } else {
        if(transmission.token){
        this.toastTransmissionAvailable.message = texts.REPLAY_PENDING;
        await this.trackMessage(this.toastTransmissionAvailable.message, 'info');
        const toast = await this.toastController.create(this.toastTransmissionAvailable);
        avilable = false;
        toast.present();
        }
      }
      if (transmission.isDrm) {
        this.playerService.setIsDRM(true);
      } else {
        this.playerService.setIsDRM(false);
      }
    } else if (transmission.isLive) {
      this.playerService.setStreamingUrl(transmission.streamingUrl);
      this.playerService.setTransmissionData(transmission);
      if (transmission.isDrm) {
        this.playerService.setIsDRM(true);
        this.playerService.setStreamingUrlDash(transmission.streamingUrlDash);
        this.playerService.setStreamingUrlHls(transmission.streamingUrlHls);
      } else {
        this.playerService.setIsDRM(false);
      }
    } else if (transmission.noSchedule) {
      this.toastTransmissionAvailable.message = texts.EVENT_NOT_AVAILABLE;
      await this.trackMessage(this.toastTransmissionAvailable.message, 'info');
      const toast = await this.toastController.create(this.toastTransmissionAvailable);
      avilable = false;
      toast.present();
    }

    if (!ischromecast) {
      if(isApproved && avilable){
        await this.playerService.playVideo();
      } else {
        this.deviceService.setConectionDeactive().then();
      }
    }

    return {isApproved, avilable};
  }

  /* If transmission settings and user entitlement are valids, then play video, else request authentication */
  async approveReproduction(transmission: any) {
    this.playerService.setId(transmission.contentful_id);
    this.playerService.setStreamingType(transmission.streamingType);
    const currentRoute = this.router.url;
    this.playerService.setPreviousUrl(currentRoute);

    if (transmission.streamingType === ENTITLEMENT.FREE_TYPE) {
      return true;
    } else {
      return this.validateAuthentication(transmission);
    }
  }

  /* Check for user entitlement */
  private async validateAuthentication(transmission: any) {
    const authorizedToken = this.storage.getAccessToken();
    const user: User = this.storage.getUser();

    if (authorizedToken) {
      if (transmission.streamingType === ENTITLEMENT.AUTHENTICATED_FREE_TYPE) {
        return true;
      } else if (transmission.streamingType === ENTITLEMENT.NOT_FREE_TYPE) {
        const entitlementFlag = !(transmission.token==='');
        if (entitlementFlag === true) {
          return true;
          await this.playerService.playVideo();
        } else {
          if(this.onMultiEntitlement?.status && this.onMultiEntitlement?.status === true && transmission?.is_multi_entitlement_content){
            this.showMultiEntitlementModal(transmission);
          }
          else{
            this.alertToast(transmission, user).then();
          }
        }
      }
    } else {
      this.navController.navigateForward(['/-/iniciar-sesion']);
    }
  }

  private async startFlow(transmission: any, user: User) {
    if (this.isAdaptable) {
      if (this.turnofsucription.pvv === 'true') {
        this.navController.navigateForward('/-/PasarelaAcceso');
      } else if(this.turnofsucription.suscription === 'true') {
        this.navController.navigateForward('/-/no-suscripcion');
      }
    }
  }

  async getDrmToken(data: any): Promise<string> {
    let tokenDrm = '';
    const apiResponse: any = await this.httpClient.get(`${DRM_TOKEN_V2}/${data.contentful_id}/${data.eventType}`).toPromise();
    if (apiResponse) {
      tokenDrm = apiResponse.token;
    }
    return tokenDrm;
  }

  async trackMessage(msg, type) {
    await this.segmentAnalytics.eventTrack(EVENT_TRACK_076, {
      message: msg, event_type: type, journey: 'user interface'
    });
  }

  public setEventCategory(category: string): void {
    this.eventCategory = category;
  }

  public getEventCategory(): string {
    return this.eventCategory;
  }

  public async setLogsDRM(idEvent: string,nameEvent: string,type: string,state: string){
    const userTigo = this.storage.getUser();
    const userCB = this.storage.getUserCarrierBilling();

    if(userTigo){
      this.generateLogTigo(userTigo,idEvent,nameEvent,type,state);
      return;
    }
      this.generateLogCarrierBilling(userCB,idEvent,nameEvent,type,state);
  }

  private async generateLogTigo(user,idEvent,nameEvent:string,type:string,state:string){

    try{
    const phoneEmail = user.tokenTigoId.email ? user.tokenTigoId.email : user.tokenTigoId.phone_number;
    const url = `${LOGS_DRM}/${user.uniqueIdentifier ? user.uniqueIdentifier : user.uuid}`;
    const data =
      {
        noContract: user?.accountNumber ? user.accountNumber : 'NoContract' ,// número contrato de tigo id,
        phoneEmail: phoneEmail, //phone email de tigo id,
        nameEvent: nameEvent, //nombre del evento del player si es play siempre sera play,
        contentfulId:idEvent,
        type: type, //fairplay o widevine,
        state: state //generado o no generado licencia DRM,
      }

    await this.httpClient.post(url, data).toPromise();
    }catch(e){

    }

  }

  private async generateLogCarrierBilling(user,idEvent,nameEvent:string,type:string,state:string){
    const url = `${LOGS_DRM}/${user.id}`;
    const data =
      {
        providerCb: user.authentication_type, //nombre del proveedor, en este caso personal
        subscriberidCb: user.suscriber_id,
        phoneEmail: 'anon',
        nameEvent: nameEvent,
        contentfulId:idEvent,
        type: type,
        state: state
      }
    await this.httpClient.post(url, data).toPromise();
  }

  private generateContract =(user)=>{
    if(user?.user_model){
        const userModel = user.user_model;
        if(userModel.homeList.length > 0){
          return userModel.homeList[0].homeid;
        }
          return 'NoContract';
      }else if(user?.phone_contract_no){
          return user.phone_contract_no;
      }else{
          return 'NoContract';
      }
  };

  private async alertToast(transmission, user) {
    const texts = await this.textManagementService.getTexts();
    try {
      this.turnofsucription = await this.textManagementService.getTurnoPayment();
      const dataResponse = await this.configurationsService.getConfigurationByVariable('modal_entitlement');
      const { state } = dataResponse[Capacitor.getPlatform().toLowerCase()];
      if (state && user.login_type !== OTHER_TYPE_LOGIN) {
        await this.openModal(dataResponse[Capacitor.getPlatform().toLowerCase()]);
      } else { throw new Error('Toast'); }
    } catch (e) {
      const infouser: any = this.storage.getUser() ? this.storage.getUser() : this.storage.getUserCarrierBilling();
      if (infouser.authentication_method === 'toolbox') {
        this.toastTransmissionAvailable.message = texts.PREMIUM_RESTRICTION_TOOLBOX;
      } else {
        this.toastTransmissionAvailable.message = texts.PREMIUM_RESTRICTION;
      }
      await this.trackMessage(this.toastTransmissionAvailable.message, 'info');
      const toast = await this.toastController.create(this.toastTransmissionAvailable);
      await toast.present();
      await this.startFlow(transmission, user);
    }
  }

  private async showMultiEntitlementModal(data: any){
    await this.msgService.messageMultiEntitlement(data.multi_entitlement);
  }

  private async openModal(data: any) {
    const modal = await this.modalController.create({
      component: AlertEntitlementPage,
      componentProps: data,
      cssClass: Capacitor.getPlatform().toLowerCase() === 'web' ? 'modal-entitlement-web': ''
    });
    modal.onDidDismiss().then(async (dataReturned) => {});
    return await modal.present();
  }
}
