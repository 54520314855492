import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import {SegmentAnalytics} from '../../../core/providers/segment-analytics';
import {TextManagementService} from '../../../services/text-management.service';
import { DevicesService } from 'src/app/services/devices.service';
import {Storage} from "../../../core/storage/storage";
import { IS_ADAPTABLE } from 'src/app/config/constants';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-message-delete-device',
  templateUrl: './message-delete-device.component.html',
  styleUrls: ['./message-delete-device.component.scss'],
})

export class MessageDeleteDeviceComponent  implements OnInit {

  @Input() data:any;
  texts: any = {};
  constructor(
    private modalCtrl:ModalController,
    private navController: NavController,
    private segmentanalytics: SegmentAnalytics,
    private deviceService: DevicesService,
    private textManagementService: TextManagementService,
    private storage: Storage,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.textManagementService.getTexts().then(data => {
      /*Filter texts*/
      /*Master device*/
      if(this.data.deleteUserType === DeleteDeviceType.master)
        this.texts = data.DELETEDEVICECURRENTUSERMASTER
      /*Device*/
      else
        this.texts = data.DELETEDEVICECURRENTUSER
    });
  }
  async close(){
    await this.modalCtrl.dismiss({cancel: true});
    if(this.data.route_back)
      this.navController.navigateForward(this.data.route_back);
 }

 async deleteDevice(){
  let dataResponse = null;
  /*Delete Master device*/
  if(this.data.deleteUserType === DeleteDeviceType.master) {
    dataResponse = await this.deleteDeviceAsMasterById()
  }
  else {
    /*Delete device*/
    dataResponse = await this.deleteDeviceById();
    const deviceId = this.storage.getDeviceUUID();
    if (dataResponse.error == null) {
      //Desautenticar
      if(dataResponse && dataResponse.data && dataResponse.data.deviceId && dataResponse.data.deviceId == deviceId){
        this.redirectToLogin();
      }
      else {
        window.history.back();
      }
    }
  }
  await this.modalCtrl.dismiss(dataResponse);
 }

 async handlerevent(event, obj) {
    await this.segmentanalytics.eventTrack(event, obj);
 }
  /*Delete Device by deviceId*/
  async deleteDeviceById() {
    return await this.deviceService.deleteDevice(this.data.id);
  }

  /*Delete Master Device by deviceId*/
  async deleteDeviceAsMasterById() {
    return await this.deviceService.deleteMasterAccountDevice(this.data.id);
  }

  /*Redirecto to login*/
  private redirectToLogin(){
    const route = IS_ADAPTABLE ? '/-/iniciar-sesion': '/-/iniciar-sesion-tv';
    this.authService.removeAuthenticationRefresh(route).then();
  }
}
export enum DeleteDeviceType {
  master,
  noMaster
}
