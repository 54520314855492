<ng-container *ngIf="_docs.length">

  <div class="settingsForm__item" *ngFor="let doc of _docs">
    <p class="itemCard">
      <input type="radio" name="{{doc.accountNumber}}" id="{{doc.accountNumber}}" value="{{doc.accountNumber}}" [checked]="false" >
      <img class="itemCard__imgCard" src="../../../../assets/graphics/tc/{{doc.icon}}" alt="">
      <label for="{{doc.accountNumber}}">{{doc.accountNumber}}</label>
      <img class="itemCard__deleteIco" src="../../../../assets/graphics/options/buttons/ico_delete_card.svg" alt="">
    </p>
    <p class="cardExp">Expira 07/22</p>
  </div>
  <div class="settingsForm__item">
    <p class="itemCard">
      <input type="radio" name="addCard" id="addCard" value="addCard" [checked]="false" >
      <img class="itemCard__imgCard" src="../../../../assets/graphics/tc/addCard_ico.svg" alt="">
      <label for="addCard">Agrega tarjeta</label>
      <img class="itemCard__deleteIco" src="../../../../assets/graphics/options/buttons/ico_delete_card.svg" alt="">
    </p>
  </div>

</ng-container>

<div class="myCards" *ngIf="!_docs.length">
  <h3>No tienes tarjetas agregadas.</h3>
</div>