import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  visibility: BehaviorSubject<boolean>;
  loadingMap: Map<string, boolean> = new Map<string, boolean>();
  isProcessNetwork: BehaviorSubject<boolean>;
  constructor() {
    this.visibility = new BehaviorSubject(true);
    this.isProcessNetwork = new BehaviorSubject(false);
  }
  setLoading(loading: boolean, key: string): void {
    if (loading === true) {
      this.loadingMap.set(key, loading);

      if (this.loadingMap.size === 1) {
        this.visibility.next(true);
      }
    } else if (loading === false && this.loadingMap.has(key)) {
      this.loadingMap.delete(key);
    }
    if (this.loadingMap.size === 0) {
      this.visibility.next(false);
    }
  }

  getLoading(key: string): boolean{
    return this.loadingMap.has(key);
  }
}
