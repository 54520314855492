import {Injectable} from '@angular/core';
import {v4 as uuidv4} from 'uuid';
import {Device} from '@awesome-cordova-plugins/device/ngx';
import {Capacitor} from '@capacitor/core';
import {Storage} from '../storage/storage';
import FingerprintJS from '@fingerprintjs/fingerprintjs'

/**
 * Device identification through UUID.
 *
 * @remarks - For native platforms, the device's UUID is used, while a UUID is generated for web platforms.
 **/
@Injectable({
  providedIn: 'root'
})
export class DeviceHelper {

  constructor(
    private device: Device,
    private storage: Storage) {
  }

  /**
   * Get device ID.
   *
   * @returns {string} - Device UUID.
   **/
  public async getUUID(): Promise<string> {
    /* If platform = web then generate an random uuid, else get device uuid */
    let uuid = '';
    if (Capacitor.getPlatform() === 'web') {
      uuid = await this.generateUUID();
    } else {
      uuid = this.device.uuid;
    }
    return uuid;
  }

  /**
   * Generate UUID for web platform.
   *
   * @remarks - The UUID is stored in storage to be used in subsequent sessions.
   * @returns {string} - Generated UUID.
   **/
  private async generateUUID(): Promise<string> {
    /* Check if there is an stored uuid, else generate an random uuid */
    let uuid = '';
    const storedValue = this.storage.getDeviceUUID();
    if (storedValue) {
      uuid = storedValue;
    } else {
      // Initialize an agent at application startup.
      const fpPromise = FingerprintJS.load();
      let result = await fpPromise
        .then(fp => fp.get())
        .then(result => result.visitorId);
      //const id = uuidv4();
      const id = result;
      this.storage.setDeviceUUID(id);
      uuid = id;
    }

    return uuid;
  }
}
