<div class="finder">
    <div class="finderContent">
        <h2>{{showTextInFinder}}</h2>
        <div class="finderItems" *ngIf="textInFinder">
            <ion-grid>
                <ion-row>
                    <ion-col >
                        <ion-segment scrollable>
                        <div class="finder__Item" *ngFor="let item of data" value="item.title" (click)="viewDetail(item.id, item.contentType)">
                            <ion-img src={{item.imgUrl}} alt="1"></ion-img>
                            <img class="icoPlayInternal" src="assets/graphics/options/buttons/ico_play_internal.svg" alt="">
                            <div class="tagLive" *ngIf="item.live">
                                <img src="assets/graphics/options/buttons/ico_record.svg" alt="2">
                                <ion-label>En Vivo</ion-label>
                            </div>
                            <div class="finder__Info">
                                <ion-label class="infoTitle">{{item.title}}</ion-label>
                                <ion-label class="infoDisabled">{{item.schedule}}</ion-label>
                            </div>
                        </div>
                        </ion-segment>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
        <h2 *ngIf="data.length === 0" class="finderVoid">{{this.text?.noResult}}</h2>
    </div>
</div>
