import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {JwtHelperService, JWT_OPTIONS} from '@auth0/angular-jwt';
import {AuthInterceptor} from './core/interceptors/auth.interceptor';
import {UniqueDeviceID} from '@awesome-cordova-plugins/unique-device-id/ngx';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from './shared/shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Device} from '@awesome-cordova-plugins/device/ngx';
import {AppVersion} from '@awesome-cordova-plugins/app-version/ngx';
import {Market} from '@awesome-cordova-plugins/market/ngx';

import { Broadcaster } from '@awesome-cordova-plugins/broadcaster/ngx';

import {MarkdownModule} from 'ngx-markdown';
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser/ngx';
import { AndroidFullScreen } from '@awesome-cordova-plugins/android-full-screen/ngx';

//import {Sim} from 'cordova-plugin-sim';
import {ScreenOrientation} from '@awesome-cordova-plugins/screen-orientation/ngx';


import { OverlayModule } from '@angular/cdk/overlay';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import {AuthModule} from '@auth0/auth0-angular';
import { GraphQLModule } from './graphql.module';
import {DevinsService} from "./services/devins.service";

declare global {
  interface Window {
    analytics: any;
  }
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot({_forceStatusbarPadding: true}),
        AppRoutingModule,
        SharedModule,
        OverlayModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MarkdownModule.forRoot(),
        MatDialogModule,
        NgxQRCodeModule,
        AuthModule.forRoot({
            domain: 'domainAuth0123',
            clientId: 'clientIdAuth0123',
            useRefreshTokens: true,
            useRefreshTokensFallback: false,
            authorizationParams: {
                redirect_uri: 'redirectUriAuth0123',
            }
        }),
        GraphQLModule,
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        JwtHelperService,
        { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        UniqueDeviceID,
        Device,
        AppVersion,
        Market,
        Broadcaster,
        //Sim,
        InAppBrowser,
        ScreenOrientation,
        AndroidFullScreen,
        DevinsService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
