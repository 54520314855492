import { Component, OnInit, Input } from '@angular/core';
import { ModalController,NavController } from '@ionic/angular';
@Component({
  selector: 'app-message-confirmation',
  templateUrl: './message-confirmation.component.html',
  styleUrls: ['./message-confirmation.component.scss'],
})

export class MessageConfirmationComponent implements OnInit {
  @Input() data:any;
  constructor(private modalCtrl:ModalController) { }

  ngOnInit() {}
  async close(value:boolean){
    await this.modalCtrl.dismiss({cancel:value});
 }
}
