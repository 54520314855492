import { AbstractControl } from '@angular/forms';
import * as moment from 'moment';
import * as creditCardType from 'credit-card-type';
import { CREDIT_CARDS, DEFAULT_CARD } from '../constants/card.constants';

export function ValidateCardType(control: AbstractControl): any {
    const valueControl: string = control.value;
    let validator: any;
    if (valueControl) {
        const card = CreditCard(valueControl);
        if (!card) {
            validator = { invalidCardType: true };
        } else {
            const element = CREDIT_CARDS.find(i => i.type === card.type);
            validator = (element ? null : { invalidCardType: true });
        }
    }
    return validator;
}

export function ValidateAccountNumberLength(control: AbstractControl): any {
    const valueControl: string = control.value;
    let validator: any;
    if (valueControl) {
        const card = CreditCard(valueControl);
        if (card) {
            const element = CREDIT_CARDS.find(i => i.type === card.type);
            const cardFound = (element ? card : null);
            if (cardFound) {
                const value = valueControl.replace(/ /g, '');
                if (value.length < cardFound.lengths[0]) {
                    validator = { invalidAccountNumberLength: true };
                } else {
                    validator = null;
                }
            }
        }
    }
    return validator;
}

export function ValidateIcon(valueControl?: string): string {
    let icon: string;
    if (valueControl && valueControl.length >= 4) {
        const card = CreditCard(valueControl);
        if (card) {
            const element = CREDIT_CARDS.find(i => i.type === card.type);
            icon = (element ? element.icon : DEFAULT_CARD);
        } else {
            icon = DEFAULT_CARD;
        }
    } else {
        icon = DEFAULT_CARD;
    }

    return icon;
}

export function ValidateErrors(control: AbstractControl): any {
    const { errors } = control;
    let valid = true;
    let message = '';

    if (control.invalid) {
        message = defineError(errors);
        valid = false;
    }

    return { valid, message };
}

export function ValidateCardExpiration(control: AbstractControl): any {
    const valueControl: string = control.value;
    let validator: any;
    if (valueControl) {
        const valid = ValidateDate(valueControl);
        validator = (valid ? null : { invalidCardExpiration: true });
    }

    return validator;
}

function defineError(errors: any): any {
    let message = '';
    if (errors.invalidCardType) {
        message = 'El número de la tarjeta no es válida';
    }

    if (errors.invalidAccountNumberLength) {
        message = 'Ingrese el número completo de la tarjeta';
    }

    return message;
}

export function CreditCard(value: any): any {
    const types = creditCardType(value);
    return types[0];
    return null
}

function ValidateDate(value: any): any {
    const date = moment();
    const now = date.format('MM/YY');
    let valid = true;

    if (value.length >= 5) {
        valid = moment(now, 'MM/YY').isSameOrBefore(moment(value, 'MM/YY'));
    }

    return valid;
}
