import {Component, Input, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-read-document-json',
  templateUrl: './read-document-json.component.html',
  styleUrls: ['./read-document-json.component.scss'],
})
export class ReadDocumentJsonComponent implements OnInit {
  @Input() content: any;

  constructor() { }

  ngOnInit() {}

}
