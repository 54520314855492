import { Component, OnInit, Input } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Storage } from 'src/app/core/storage/storage';
import { RecommendedService } from 'src/app/services/recommended.service';
import { EVENT_TRACK_072 } from 'src/app/config/analytics/events-track';
import { SegmentAnalytics } from 'src/app/core/providers/segment-analytics';
import { IS_ADAPTABLE } from 'src/app/config/constants';
import * as moment from 'moment';
import { VideoStreamingService } from 'src/app/services/video-streaming.service';
import { EventDetailService } from 'src/app/services/event-detail.service';
import { ENTITLEMENT } from 'src/app/config/settings';
import { DevicesService } from 'src/app/services/devices.service';
import { AuthService } from 'src/app/services/auth.service';
import { TextManagementV2Service } from 'src/app/services/text-management-v2.service';
@Component({
  selector: 'app-recommended-v2',
  templateUrl: './recommended-v2.component.html',
  styleUrls: ['./recommended-v2.component.scss'],
})
export class RecommendedV2Component implements OnInit {
  textsV2: any;
  assetDomain: any;
  showRecommendedV2 = false;
  data=[];
  isAdaptable = IS_ADAPTABLE;
  loadingImg = '../../../../assets/graphics/pictures/load-img.png';
  @Input() idRecommendedView: string;

  constructor(
    private recommendedService : RecommendedService,
    private storage : Storage,
    private navController : NavController,
    private segmentAnalytics : SegmentAnalytics,
    private videoStreamingService: VideoStreamingService,
    private eventDetailService: EventDetailService,
    private devicesService: DevicesService,
    private textManagementV2Service: TextManagementV2Service
  ) { }

  async ngOnInit() {
    this.textsV2 = await this.textManagementV2Service.getTexts();
    this.assetDomain = this.textsV2?.ASSET_DOMAIN;
    this.loadEvents();
  }

  /* Get 'Recommended' events */
  async loadEvents() {
    try {
      this.data = [];
      const dataResponse = await this.recommendedService.getEvents(1, this.assetDomain);
      if (dataResponse.length > 0){
        this.data = dataResponse.filter(item => item.content_id !== this.idRecommendedView);
        this.showRecommendedV2 = true;
      } else {
        this.showRecommendedV2 = false;
        this.data = [];
      }
    } catch (error) {}
  }

  /* If user is authenticated then redirect to detail page, else redirect to login page */
  async viewDetail(event): Promise<void> {
    let authenticated = this.storage.getAccessToken();
    let route : string;
    let segmentData = {
      championship_id: event.championship_id,
      championship_name: event.championship_id,
      airdate: event.createdAt,
      sport_name: event.sport_name,
      video_title: event.video_title,
      journey: 'player',
      content_id: event.contentful_id,
      home_team_id: event.home_team_id,
      home_team: event.home_team,
      away_team_id: event.away_team_id,
      away_team: event.away_team,
      total_length: event.total_length,
      video_position: 0,
      session_id: event.session_id,
      video_quality: event.video_quality,
      content_category: event.content_category,
      content_type: this.defineContentTypeSegment(event.end_date),
    };
    await this.segmentAnalytics.eventTrack(EVENT_TRACK_072, segmentData);
    await this.segmentAnalytics.eventTrack(EVENT_TRACK_072, segmentData);
    this.videoStreamingService.setEventCategory(event.content_category);
    let eventType = '';
    if (event.content_type === "gameSchedules"){
      eventType = "game"
    } else {
      eventType = "highlights"
    }
  //  let streamingType = await this.eventDetailService.getStreamType(event.contentful_id, eventType);
    route = `/detail/${event.contentful_id}/${eventType}`;
    this.storage.setPreviousRoute(route);
/*     if (!authenticated && streamingType != ENTITLEMENT.FREE_TYPE){
      if (this.isAdaptable){
        route = '/-/iniciar-sesion';
      } else {
        route = '/-/iniciar-sesion-tv';
      }
    } */
    this.navController.navigateRoot(route);
  }

  private defineContentTypeSegment(end_date: Date): string {
    let contentTypeFixed = '';
    const endDate = end_date;
    if (endDate) {
      const now = moment();
      const deadline = moment(endDate);

      const isAfter = moment(deadline).isAfter(now);
      if (isAfter) {
        contentTypeFixed = 'live';
      } else {
        contentTypeFixed = 'replay';
      }
    } else {
      contentTypeFixed = 'highlights';
    }
    return contentTypeFixed;
  }
}
