<div class="keyboard">
  <div class="keyboard__row tv-row">
    <button >Tigo</button>
    <button (click)="handleKeydown(32)" class="tv-control" id = "txt00" tabindex="36"><img src="assets/graphics/options/buttons/ico_space.svg" alt="ico"></button>
    <button (click)="handleKeydown(8)" class="tv-control" id = "txt01" tabindex="36"><img src="assets/graphics/options/buttons/ico_delete.svg" alt="ico"></button>
  </div>
  <div class="keyboard__row tv-row">
    <button (click)="handleKeydown(97)" class="keys tv-control" id = "txt10" tabindex="25" #txtA>a</button>
    <button (click)="handleKeydown(98)" class="keys tv-control" id = "txt11" tabindex="26" #txtB>b</button>
    <button (click)="handleKeydown(99)" class="keys tv-control" id = "txt12" tabindex="27">c</button>
    <button (click)="handleKeydown(100)" class="keys tv-control" id = "txt13" tabindex="28">d</button>
    <button (click)="handleKeydown(101)" class="keys tv-control" id = "txt14" tabindex="29">e</button>
    <button (click)="handleKeydown(102)" class="keys tv-control" id = "txt15" tabindex="30">f</button>
  </div>
  <div class="keyboard__row tv-row">
    <button (click)="handleKeydown(103)" class="keys tv-control" id = "txt20" tabindex="31">g</button>
    <button (click)="handleKeydown(104)" class="keys tv-control" id = "txt21" tabindex="31">h</button>
    <button (click)="handleKeydown(105)" class="keys tv-control" id = "txt22" tabindex="31">i</button>
    <button (click)="handleKeydown(106)" class="keys tv-control" id = "txt23" tabindex="31">j</button>
    <button (click)="handleKeydown(107)" class="keys tv-control" id = "txt24" tabindex="31">k</button>
    <button (click)="handleKeydown(108)" class="keys tv-control" id = "txt25" tabindex="31">l</button>
  </div>
  <div class="keyboard__row tv-row">
    <button (click)="handleKeydown(109)" class="keys tv-control" id = "txt30" tabindex="32">m</button>
    <button (click)="handleKeydown(110)" class="keys tv-control" id = "txt31" tabindex="32">n</button>
    <button (click)="handleKeydown(111)" class="keys tv-control" id = "txt32" tabindex="32">o</button>
    <button (click)="handleKeydown(112)" class="keys tv-control" id = "txt33" tabindex="32">p</button>
    <button (click)="handleKeydown(113)" class="keys tv-control" id = "txt34" tabindex="32">q</button>
    <button (click)="handleKeydown(114)" class="keys tv-control" id = "txt35" tabindex="32">r</button>
  </div>
  <div class="keyboard__row tv-row">
    <button (click)="handleKeydown(115)" class="keys tv-control" id = "txt40" tabindex="33">s</button>
    <button (click)="handleKeydown(116)" class="keys tv-control" id = "txt41" tabindex="33">t</button>
    <button (click)="handleKeydown(117)" class="keys tv-control" id = "txt42" tabindex="33">u</button>
    <button (click)="handleKeydown(118)" class="keys tv-control" id = "txt43" tabindex="33">v</button>
    <button (click)="handleKeydown(119)" class="keys tv-control" id = "txt44" tabindex="33">w</button>
    <button (click)="handleKeydown(120)" class="keys tv-control" id = "txt45" tabindex="33">x</button>
  </div>
  <div class="keyboard__row tv-row">
    <button (click)="handleKeydown(121)" class="keys tv-control" id = "txt50" tabindex="34">y</button>
    <button (click)="handleKeydown(122)" class="keys tv-control" id = "txt51" tabindex="34">z</button>
    <button (click)="handleKeydown(48)" class="keys tv-control" id = "txt52" tabindex="34">0</button>
    <button (click)="handleKeydown(49)" class="keys tv-control" id = "txt53" tabindex="34">1</button>
    <button (click)="handleKeydown(50)" class="keys tv-control" id = "txt54" tabindex="34">2</button>
    <button (click)="handleKeydown(51)" class="keys tv-control" id = "txt55" tabindex="34">3</button>
  </div>
  <div class="keyboard__row tv-row">
    <button (click)="handleKeydown(52)" class="keys tv-control" id = "txt60" tabindex="35">4</button>
    <button (click)="handleKeydown(53)" class="keys tv-control" id = "txt61" tabindex="35">5</button>
    <button (click)="handleKeydown(54)" class="keys tv-control" id = "txt62" tabindex="35">6</button>
    <button (click)="handleKeydown(55)" class="keys tv-control" id = "txt63" tabindex="35">7</button>
    <button (click)="handleKeydown(56)" class="keys tv-control" id = "txt64" tabindex="35">8</button>
    <button (click)="handleKeydown(57)" class="keys tv-control" id = "txt65" tabindex="35">9</button>
  </div>
</div>
