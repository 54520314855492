import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {AES, enc} from 'crypto-ts';

@Injectable({
  providedIn: 'root'
})
export class SecurityHelper {
  //prp = this.decrypt(this.decrypt(environment.PROCESS_PIX, 1),1);
  prp = environment.PROCESS_PIX;
  ao = `${environment.O5}${environment.A1}`;

  constructor() {
  }
  /* Encrypt values */
  public encrypt(value: string, type: number=0): string {
    let process = this.ao;
    if (type == 0){
      const bytes = AES.decrypt(this.prp, this.ao);
      process=bytes.toString(enc.Utf8);
    }
    const encrypted = AES.encrypt(value, process);
    return encrypted.toString();
  }

  /* Decrypt values */
  public decrypt(value: string, type: number=0): string {
    let process = this.ao;
    if (type == 0){
      const bytes = AES.decrypt(this.prp, this.ao);
      process=bytes.toString(enc.Utf8);
    }
    const bytes = AES.decrypt(value, process);
    return bytes.toString(enc.Utf8);
  }
}
