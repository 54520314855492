import { Injectable } from '@angular/core';
import { BillingControlBase } from '../base-model/billing-control-base';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class BillingFormBuilder {
    billingForm: UntypedFormGroup;

    constructor(
        private formBuilder: UntypedFormBuilder
    ) {
    }

    buildForm(): void {
        this.billingForm = this.formBuilder.group({});
    }

    addControl(controls: BillingControlBase<string>[], form: UntypedFormGroup): any {
        if (controls) {
            form.removeControl('billingForm');
            this.buildForm();
            for (const control of controls) {
                const validators = this.getValidators(control);
                this.billingForm.addControl(control.key, new UntypedFormControl(control.value || '', validators));
            }
            form.addControl('billingForm', this.billingForm);
        }
        return form;
    }

    getValidators(control: any): any {
        const validators = [];
        if (control.required) {
            validators.push(Validators.required);
        }
        if (control.email) {
            validators.push(Validators.email);
        }
        if (control.min) {
            validators.push(Validators.min(control.min));
        }
        if (control.max && control.max !== 0) {
            validators.push(Validators.max(control.max));
        }
        if (control.pattern) {
            validators.push(Validators.pattern(control.pattern));
        }
        if (control.minLength) {
            validators.push(Validators.minLength(control.minLength));
        }
        if (control.maxLength && control.maxLength !== 0) {
            validators.push(Validators.maxLength(control.maxLength));
        }

        return validators;
    }

    removeControl(controls: BillingControlBase<string>[], form: UntypedFormGroup): any {
        if (controls) {
            form.removeControl('billingForm');
            this.buildForm();
            for (const control of controls) {
                this.billingForm.addControl(control.key, new UntypedFormControl(control.value || ''));
            }
            form.addControl('billingForm', this.billingForm);
        }
        return form;
    }
}
