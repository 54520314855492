import { Component, OnInit } from '@angular/core';
import {
  ModalController,
  NavParams, Platform
} from '@ionic/angular';
import {BrowserService} from "../../services/browser.service";

@Component({
  selector: 'app-alert-entitlement',
  templateUrl: './alert-entitlement.page.html',
  styleUrls: ['./alert-entitlement.page.scss'],
})
export class AlertEntitlementPage implements OnInit {
  confirm: any;
  alternateButton: any;
  image: string;
  message: string;
  state: boolean;
  cancel: any;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private platform: Platform,
    private browserService: BrowserService,
  ) { }

  ngOnInit() {
    const { confirm, alternate_button, cancel, image, message, state } = this.navParams.data;
    this.confirm = confirm;
    this.alternateButton = alternate_button;
    this.image = image;
    this.message = message;
    this.state = state;
    this.cancel = cancel;
  }

  async closeModal() {
    const onClosedData = 'Wrapped Up!';
    await this.modalController.dismiss(onClosedData);
  }

  async openBrowser(link: string) {
    if (this.platform.is('android')){
      window.open(link);
    } else {
      await this.browserService.openExternalLink(link);
    }
  }
}
