import { Injectable } from '@angular/core';
import { Storage } from '../core/storage/storage';
import { User } from '../core/interfaces/user';
import { Location } from '../core/interfaces/location';
import { FavoritesService } from './favorites.service';
import { TeamsService } from './teams.service';
import { Capacitor } from '@capacitor/core';
import * as appboy from '@braze/web-sdk';
import { TextManagementV2Service } from './text-management-v2.service';

declare let AppboyPlugin: any;

@Injectable({
  providedIn: 'root'
})
export class BrazeService {
  appboy: any;

  constructor(private storage: Storage,
    private favoriteService: FavoritesService,
    private teamsService: TeamsService,
    private textManagementV2Service: TextManagementV2Service) {
  }

  /* Start braze plugin service */
  public async register() {
    let textsV2 = await this.textManagementV2Service.getTexts();
    let assetDomain = textsV2?.ASSET_DOMAIN;

    if (Capacitor.getPlatform() === 'web') {
      const location: Location = this.storage.getLocation();
      appboy.getUser().setCountry(location.name);
      appboy.getUser().setPushNotificationSubscriptionType(appboy.User.NotificationSubscriptionTypes.SUBSCRIBED);

      const user = this.storage.getUser();
      if (user) {
        const id = user.id;
        const firstName = user.family_name ? user.family_name : '';
        const lastName = user.given_name ? user.given_name : '';
        const email = user.email ? user.email : '';
        const phoneNumber = user.phone_number ? user.phone_number : '';

        appboy.changeUser(id);
        appboy.getUser().setFirstName(firstName);
        appboy.getUser().setLastName(lastName);
        appboy.getUser().setEmail(email);
        appboy.getUser().setPhoneNumber(phoneNumber);
        const storedPreferences = await this.favoriteService.getEvents(null, assetDomain);
        if (storedPreferences.length > 0) {
          const preferences = storedPreferences.map(item => item.title);
          appboy.getUser().setCustomUserAttribute('Preferences', preferences);
        } else {
          appboy.getUser().setCustomUserAttribute('Preferences', []);
        }
        this.teamsService.refreshTeams();
        this.loadTeams();

        if (phoneNumber) {
          appboy.getUser().addAlias(phoneNumber, 'PhoneNumber');
        }
        if (email) {
          appboy.getUser().addAlias(email, 'Email');
        }
      }
      appboy.openSession();
    } else {
      const location: Location = this.storage.getLocation();
      AppboyPlugin.setCountry(location.name);
      AppboyPlugin.setPushNotificationSubscriptionType(AppboyPlugin.NotificationSubscriptionTypes.SUBSCRIBED);

      const user = this.storage.getUser();
      if (user) {
        const id = user.id;
        const firstName = user.family_name ? user.family_name : '';
        const lastName = user.given_name ? user.given_name : '';
        const email = user.email ? user.email : '';
        const phoneNumber = user.phone_number ? user.phone_number : '';

        await AppboyPlugin.changeUser(id);
        AppboyPlugin.setFirstName(firstName);
        AppboyPlugin.setLastName(lastName);
        AppboyPlugin.setEmail(email);
        AppboyPlugin.setPhoneNumber(phoneNumber);
        const storedPreferences = await this.favoriteService.getEvents(null, assetDomain);
        if (storedPreferences.length > 0) {
          const preferences = storedPreferences.map(item => item.title);
          AppboyPlugin.setCustomUserAttribute('Preferences', preferences);
        } else {
          AppboyPlugin.setCustomUserAttribute('Preferences', []);
        }
        this.teamsService.refreshTeams();
        this.loadTeams();

        if (phoneNumber) {
          AppboyPlugin.addAlias(phoneNumber, 'PhoneNumber');
        }
        if (email) {
          AppboyPlugin.addAlias(email, 'Email');
        }
      }
    }
  }

  public async disable() {
    if (Capacitor.getPlatform() === 'web') {
      appboy.getUser().setPushNotificationSubscriptionType(appboy.User.NotificationSubscriptionTypes.UNSUBSCRIBED);
    } else {
      await AppboyPlugin.setPushNotificationSubscriptionType(AppboyPlugin.NotificationSubscriptionTypes.UNSUBSCRIBED);
    }
  }

  public loadTeams(): void {
    this.teamsService.teamObservable.subscribe(async (status) => {
      if (status) {
        const storedTeams = await this.teamsService.getTeams();
        const activeTeams = storedTeams.filter(x => x.status === true);
        const selectedTeams = activeTeams.map(team => team.name);

        if (Capacitor.getPlatform() === 'web') {
          appboy.getUser().setCustomUserAttribute('Teams', selectedTeams);
        } else {
          AppboyPlugin.setCustomUserAttribute('Teams', selectedTeams);
        }
      }
    });
  }
}
