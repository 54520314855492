import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GET_LOCATION } from '../config/endpoints';
import {Storage} from '../core/storage/storage';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {

  constructor(
    private httpClient: HttpClient, private storage: Storage
  ) { }

  /* Get current location based on the user's ip */
  async getLocationData(): Promise<void> {
    let data: any;
   // const url = GET_LOCATION;

    try {
     // const response: any = await this.httpClient.get(url).toPromise();
      const response: any = await this.storage.getLocationUser();
      data = response ? (response.countryCode ? response.countryCode : null) : null;
    } catch (e) {}
    return data;
  }
}
