import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FAVORITES, GET_IS_FAVORITE} from '../config/endpoints';
import {COUNTRY_CODE_KEY, IS_ADAPTABLE, CARROUSEL_LIMIT} from '../config/constants';
import { Storage } from '../core/storage/storage';

@Injectable({
  providedIn: 'root'
})
export class FavoritesService {

  isAdaptable = IS_ADAPTABLE;
  carrouselLimit = 10;

  constructor(
    private httpClient: HttpClient,
    private storage: Storage
  ) {
  }

  /* Get all favorites */
  public async getEvents(lastID: string, assetDomain: any): Promise<any> {
    let events: any = [];
    let eventsAux = [];
    let eventsDataAux = {};
    let cont = 0;
    try {
      const user = this.storage.getUser() ? this.storage.getUser() : this.storage.getUserCarrierBilling();
      let dataResponse: any;
      if (user) {
        if (lastID !== null) {
          dataResponse = await this.httpClient.get(`${FAVORITES}/${user.id}/${COUNTRY_CODE_KEY}/${this.carrouselLimit}/${lastID}`).toPromise();
        } else {
          dataResponse = await this.httpClient.get(`${FAVORITES}/${user.id}/${COUNTRY_CODE_KEY}/${this.carrouselLimit}`).toPromise();
        }
        dataResponse.data.forEach((event) => {
          eventsDataAux = {
            contentful_id: event.contentful_id,
            title : event.title,
            image: this.getImage(event, assetDomain),
            createdAt: event.updatedAt,
            championship_id : event.contentful_data ? event.contentful_data.fields.league.sys.id : '',
            championship_name : event.league ? event.league : '',
            sport_name : event.sport ? event.sport : '',
            video_title: event.title,
            content_id: event.contentful_id,
            home_team_id: event.home_team_data ? event.home_team_data.contentful_id : '',
            home_team: event.home_team_data ? event.home_team_data.json.fields.name : '',
            away_team_id: event.away_team_data ? event.away_team_data.contentful_id : '',
            away_team: event.away_team_data ? event.away_team_data.json.fields.name : '',
            total_length: '',
            session_id: '',
            video_quality: '',
            content_category: 'my favorites',
            content_type: event.type ? event.type : '',
            schedule: '',
            referenceIdPaginator: event.id,
          }
          cont += 1;
          if (cont <= this.carrouselLimit) {
            eventsAux.push(eventsDataAux);
          }
        });
        events = dataResponse ? eventsAux : [];
      }
    } catch (error) {}
    return events;
  }

  /* Set an event as favorite */
  public async setFavorite(data: any): Promise<any> {
    let response = false;
    try {
      const requestResponse: any = await this.httpClient.post(`${FAVORITES}`, data).toPromise();
      if (requestResponse.status) {
        response = true;
      }
    } catch (error) {}
    return response;
  }

  /* Delete a favoite */
  public async deleteFavorite(data: any, id: any): Promise<any> {
    let response = false;
    try {
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body: data
      };
      const requestResponse: any = await this.httpClient.delete(`${FAVORITES}/${id}`, options).toPromise();
      response = true;
    } catch (error) {}
    return response;
  }

  /* Check if an event is favorite */
  public async isFavorite(uuid: any, id_contentful: any): Promise<any> {
    let response = false;
    try {
      const requestResponse: any = await this.httpClient.get(`${GET_IS_FAVORITE}/${uuid}/${id_contentful}/${COUNTRY_CODE_KEY}`).toPromise();
      if (requestResponse.data.contentful_id === id_contentful) {
        response = true;
      }
    } catch (error) {}
    return response;
  }

  /* Get image url from Amazon S3 or Contentful */
  private getImage(obj, assetDomain){
    let domain = assetDomain?.banners ? assetDomain.banners : assetDomain.default
    if(assetDomain?.status && assetDomain.status === true && obj?.verticalImageS3){
      return domain + obj?.verticalImageS3;
    }else{
      let img = obj.vertical_image ? obj.vertical_image : '';
      img = img.replace('https:', '')
      img = `https:${img}`
      return img
    }
  }
}
