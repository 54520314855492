<div class="recommended" [ngClass]="{'recommendedAdaptable':isAdaptable}">
  <h3>Recomendados</h3>
  <ion-grid>
    <ion-row>
      <ion-col >
        <ion-segment class="tv-row tv-control-none" scrollable>
          <div class="recommended__Item" [ngClass]="{'tv-control': !isAdaptable, 'recommended__ItemAdaptable': isAdaptable}" *ngFor="let item of data" value="item.title" (click)="viewDetail(item)" tabindex="12" [id]="'recommendedv2-' + item.contentful_id">
            <ion-img [src]="item.image ? item.image : loadingImg" alt="item.contentful_id"></ion-img>
            <img class="icoPlayInternal" src="assets/graphics/options/buttons/ico_play_internal.svg" alt="">
            <div class="recommended__Info" [ngClass]="{'recommended__InfoAdaptable': isAdaptable}">
              <p class="infoTitle">{{item.title}}</p>
            </div>
          </div>
        </ion-segment>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
