import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-my-cards',
  templateUrl: './my-cards.component.html',
  styleUrls: ['./my-cards.component.scss'],
})
export class MyCardsComponent implements OnInit {

  _docs = [
    {
      type: "VISA",
      accountNumber: "4111 1111 1111 1111",
      icon: "VISA.svg"
    }
  ];

  @Input()
  set cards(value: any[]) {
    this._docs = value;
  }

  @Output() newItemEvent = new EventEmitter<any>();

  get cards():any[]{
    return this._docs;
  }

  constructor() { }

  ngOnInit() {}

  isNotEmptyCards() {
    return (this._docs.length > 0);
  }

  deleteCard(card: any) {
    
  }

}
