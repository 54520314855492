import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { DELETE_USER } from '../config/endpoints';
import { Storage } from '../core/storage/storage';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class DeleteUserService {

  constructor(private httpClient: HttpClient,private storage: Storage,private authService:AuthService,) { }
    
  public async deleteUser(){
    const body = { "idToken": this.storage.getTokenTigoId(), "uuid": this.storage.getUser().id }
    try{
    const dataResponse : any = await this.httpClient.post(DELETE_USER, body).toPromise();
        return dataResponse && dataResponse?.DAR?.success && dataResponse?.Eventbroker?.success && dataResponse?.Segment?.success
   }catch(e){
        return false;
   }
  }
  
}
