import {Injectable} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {MessageDialogComponent} from '../shared/components/message-dialog/message-dialog.component';
import {MessageSubscriptionComponent} from '../shared/components/message-subscription/message-subscription.component';
import {MessageConfirmationComponent} from '../shared/components/message-confirmation/message-confirmation.component';
import {
  MessageDialogsubscriptionComponent
} from '../shared/components/message-dialogsubscription/message-dialogsubscription.component';
import {MessageDeleteComponent} from '../shared/components/message-delete/message-delete.component';
import {
  MessageConcurrentUserMasterComponent
} from '../shared/components/message-concurrent-user-master/message-concurrent-user-master.component';
import {
  MessageDialogSelectUserMasterComponent
} from '../shared/components/message-dialog-select-user-master/message-dialog-select-user-master.component';
import {
  DeleteDeviceType,
  MessageDeleteDeviceComponent
} from '../shared/components/message-delete-device/message-delete-device.component';
import {MessageToastComponent, MessageToastStatus} from '../shared/components/message-toast/message-toast.component';
import {
  MessageConcurrentUsermasterDeviceComponent
} from '../shared/components/message-concurrent-usermaster-device/message-concurrent-usermaster-device.component';
import {
  MessageConcurrentuserNomasterComponent
} from '../shared/components/message-concurrentuser-nomaster/message-concurrentuser-nomaster.component';
import {MessageAuth0ModalComponent} from '../shared/components/message-Auth0Modal/message-Auth0Modal.component';
import {IS_ADAPTABLE} from '../config/constants';
import {
  MessageMultiEntitlementComponent
} from '../shared/components/message-multi-entitlement/message-multi-entitlement.component';
import {MessageWebglComponent} from '../shared/components/message-webgl/message-webgl.component';
import {MessageVecimaComponent} from '../shared/components/message-vecima/message-vecima.component';
import {TermsConditionsComponent} from '../shared/components/terms-conditions/terms-conditions.component';
import {ToggleSessionTvComponent} from '../shared/components/toggle-session-tv/toggle-session-tv.component';

@Injectable({
  providedIn: 'root'
})
export class MessageDialogService {

  isadaptable = IS_ADAPTABLE;

  constructor(private dialog: ModalController) { }

  async messageDialogSuscroption(title: string, subtitle: string,img_src: string,route:string, buttonMessage:string, valueerrol?: boolean,) {
    const modal = await this.dialog.create({
      component: MessageDialogsubscriptionComponent,
      cssClass: 'my-custom-modal-css',
      componentProps: {
        data:{
          title,
          subtitle,
          img_src,
          route,
          valueerrol,
          buttonMessage
        }
      }
    });

    await modal.present();
  }

  async messageDialogSelectUserMaster(title: string, subtitle: string, buttonConfirm: string, buttonCancel: string, img_src: string,route_go:string,route_back:string) {
    const modal = await this.dialog.create({
     component: MessageDialogSelectUserMasterComponent,
     cssClass: 'modal-custom-device',
     componentProps: {
       data:{
          title,
          subtitle,
          buttonConfirm,
          buttonCancel,
          img_src,
          route_go,
          route_back
       }
     }
    });

    await modal.present();
  }

  async messageDeleteDevice(title: string, subtitle: string, buttonConfirm: string, buttonCancel: string, img_src: string, deleteUserType: DeleteDeviceType, id: string, route_back:string) {
    const modal = await this.dialog.create({
     component: MessageDeleteDeviceComponent,
     cssClass: 'modal-delete-custom-device',
     componentProps: {
       data:{
          title,
          subtitle,
          buttonConfirm,
          buttonCancel,
          img_src,
          deleteUserType,
          id,
          route_back
       }
     }
    });

    await modal.present();
    const response = modal.onDidDismiss();
    return response;
  }

  async messageToast(title: string, img_src: string, status: MessageToastStatus) {
    const modal = await this.dialog.create({
     component: MessageToastComponent,
     cssClass: 'toast-custom',
     componentProps: {
       data:{
          title,
          img_src,
          status
       }
     }
    });

    await modal.present();
    const response = modal.onDidDismiss();
    return response;
  }

  async messageDialog(title: string, subtitle: string,img_src: string,route:string) {
       const modal = await this.dialog.create({
        component: MessageDialogComponent,
        cssClass: 'my-custom-modal-css',
        componentProps: {
          data:{
             title,
             subtitle,
             img_src,
             route
          }
        }
       });

       await modal.present();
  }


  async messageDelete(title: string,buttonText:string,buttonDelete: string,errorMessage: string) {
    const modal = await this.dialog.create({
     component: MessageDeleteComponent,
     cssClass: 'my-custom-modal-css',
     componentProps: {
      data:{
         title,
         buttonText,
         buttonDelete,
         errorMessage
      }
    }
    });

    await modal.present();
}

  async messageSubscription(title: string, subtitle: string,img_src: string,route_go:string,route_back:string) {
    const modal = await this.dialog.create({
     component: MessageSubscriptionComponent,
     cssClass: 'my-custom-modal-css-bottom',
     componentProps: {
       data:{
          title,
          subtitle,
          img_src,
          route_go,
          route_back
       }
     }
    });

    await modal.present();
}

async messageConfirmation (title: string, subtitle: string,img_src: string,route_go:string,route_back:string): Promise<any> {
  const modal = await this.dialog.create({
   component: MessageConfirmationComponent,
   cssClass: 'my-custom-modal-css-bottom',
   componentProps: {
     data:{
        title,
        subtitle,
        img_src
     }
   }
  });

  await modal.present();
  let value = await modal.onDidDismiss();
  return value;
}

  async messageConcurrentUserMaster(objContent: any, isChromecast?: boolean): Promise<any> {
    if (isChromecast) {
      objContent.isChromecast = true;
    }
    const modal = await this.dialog.create({
      component: MessageConcurrentUserMasterComponent,
      cssClass: this.isadaptable ? 'limit-custom-device' : 'limit-custom-device-tv',
      componentProps: {
        data: {
          objContent
        }
      }
    });
    await modal.present();
    const response = modal.onDidDismiss();
    return response;
  }

  async messageConcurrentuserMasterDevice(objContent: any): Promise<any> {
    const modal = await this.dialog.create({
      component: MessageConcurrentUsermasterDeviceComponent,
      cssClass: this.isadaptable ? 'limit-custom-device-play' : 'limit-custom-device-play-tv',
      componentProps: {
        data: {
          objContent
        }
      }
    });
    await modal.present();
    const response = modal.onDidDismiss();
    return response;
  }

  async messageConcurrentUserNoMaster(objContent: any, isChromecast?: boolean) {
    if (isChromecast) {
      objContent.isChromecast = true;
    }
    const modal = await this.dialog.create({
      component: MessageConcurrentuserNomasterComponent,
      cssClass: 'limit-custom-device-noMaster',
      componentProps: {
        data: {
          objContent
        }
      }
    });
    await modal.present();
    const response = modal.onDidDismiss();
    return response;
  }

  async messageAuthAuth0(title: string, subtitle: string, buttonModal: string) {
    const modal = await this.dialog.create({
      component: MessageAuth0ModalComponent,
      cssClass: 'my-custom-modal-css',
      componentProps: {
        data: {
          title,
          subtitle,
          buttonModal
        }
      }
    });
    await modal.present();
  }

  async messageMultiEntitlement(objContent: any): Promise<any> {
    const modal = await this.dialog.create({
      component: MessageMultiEntitlementComponent,
      cssClass: this.isadaptable ? 'multi-entitlement-modal' : 'multi-entitlement-modal-tv',
      componentProps: {
        data: {
          objContent
        }
      }
    });
    await modal.present();
    const response = modal.onDidDismiss();
    return response;
  }

  async messageWebGL(objContent: any): Promise<any> {
    const modal = await this.dialog.create({
      component: MessageWebglComponent,
      cssClass: 'modal-webgl',
      componentProps: {
        data: {
          objContent
        }
      }
    });
    await modal.present();
    const response = modal.onDidDismiss();
    return response;
  }

  async messageVecima(title: string, subtitle: string, buttonModal: string): Promise<any> {
    const modal = await this.dialog.create({
      component: MessageVecimaComponent,
      cssClass: 'limit-custom-device-noMaster',
      componentProps: {
        data: {
          title,
          subtitle,
          buttonModal
        }
      }
    });
    await modal.present();
  }

  async messageTermsConditions(img: string) {
    const modal = await this.dialog.create({
      component: TermsConditionsComponent,
      cssClass: 'limit-custom-terms-Conditions',
      componentProps: {
        data: {
          img
        }
      }
    });
    await modal.present();
  }

  async messageLogoutSessionTv() {
    const modal = await this.dialog.create({
      component: ToggleSessionTvComponent,
      cssClass: 'limit-custom-device-noMaster'
    });
    await modal.present();
    return modal.onDidDismiss();
  }

}
