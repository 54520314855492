import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {SecurityHelper} from './security.helper';
import {Capacitor} from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class StorageHelper {
  key = `${environment.A1}${environment.O5}`;

  constructor(
    private security: SecurityHelper
  ) {
  }

  /* Set encrypted values in storage */
  public set(reference: string, value: string): boolean {
    let status = false;
    if (value) {
      const encryptedValue = this.security.encrypt(value);
      try {
        localStorage.setItem(reference, encryptedValue);
        status = true;
      } catch (e) {
        status = false;
      }
    }

    return status;
  }

  /* Get decrypted values from storage */
  public get(reference: string): string {
    let value = localStorage.getItem(reference);

    if (value) {
      value = this.security.decrypt(value);
    }

    return value;
  }

  /* Remove values in storage */
  public remove(reference: string) {
    localStorage.removeItem(reference);
  }
}
