/**
 * Constants for url of services to consume in the application in general.
 *
 * @remarks - These constants are made up of other constants of reusable generic configurations.
 **/

import {environment, MIDDLEWARE_HOST} from 'src/environments/environment';
import {COUNTRY_CODE_KEY} from './constants';

const {MIDDLEWARE_PATH} = environment;

export const API_AUTH = `${MIDDLEWARE_PATH}/oauth/token`;
export const USER_REGISTER = `${MIDDLEWARE_HOST}user/register`;
export const USER_LOGIN = `${MIDDLEWARE_HOST}user/login`;
export const GET_LOCATION = `${MIDDLEWARE_HOST}location/get-location`;
export const GET_ENTITLEMENT = `${MIDDLEWARE_HOST}entitlement/get-entitlement/${COUNTRY_CODE_KEY}`;
export const FAVORITES = `${MIDDLEWARE_HOST}user/favorites`;
export const GET_IS_FAVORITE = `${MIDDLEWARE_HOST}user/user-favorite`;
export const WIDEVINE_SERVER = 'https://multidrm.vsaas.verimatrixcloud.net/widevine';
export const FAIRPLAY_SERVER = 'https://multidrm.vsaas.verimatrixcloud.net/fairplay';
export const CONFIGURATIONS = `${MIDDLEWARE_HOST}contentful/config/get-config-variable`;
export const LOCATION = `${MIDDLEWARE_HOST}location/get-location`;
export const GET_TERMS = `${MIDDLEWARE_HOST}contentful/get-page/${COUNTRY_CODE_KEY}/tcPage`;
export const GET_POLITICS = `${MIDDLEWARE_HOST}contentful/get-page/${COUNTRY_CODE_KEY}/ppPage`;
export const GET_BANNERS = `${MIDDLEWARE_HOST}content/get-banners/${COUNTRY_CODE_KEY}`;
export const GET_LIVE_NOW = `${MIDDLEWARE_HOST}content/get-events/${COUNTRY_CODE_KEY}/live/games`;
export const GET_UPCOMING_EVENTS = `${MIDDLEWARE_HOST}content/get-events/${COUNTRY_CODE_KEY}/next/games`;
export const GET_REPETITIONS = `${MIDDLEWARE_HOST}content/get-events/${COUNTRY_CODE_KEY}/replay/games`;
export const GET_RECOMMENDED = `${MIDDLEWARE_HOST}content/get-recommended/${COUNTRY_CODE_KEY}`;
export const GET_NEWS = `${MIDDLEWARE_HOST}content/get-events/${COUNTRY_CODE_KEY}/live/news`;
export const GET_OTHER_CONTENT = `${MIDDLEWARE_HOST}content/get-other-content/${COUNTRY_CODE_KEY}`;
export const GET_EVENT_DETAIL = `${MIDDLEWARE_HOST}content/get-event/${COUNTRY_CODE_KEY}`;
export const GET_CLOUDSEARCH_RESULTS = `${MIDDLEWARE_HOST}cloud-search`;
export const FORGOT_PASSWORD = `${MIDDLEWARE_HOST}user/forgot-password`;
export const USER_DATA = `${MIDDLEWARE_HOST}user/dar-user-info`;
export const GET_TV_TOKEN = `${MIDDLEWARE_HOST}user/generate-token-tv`;
export const GET_USER_SESSIONS = `${MIDDLEWARE_HOST}device-control/get-sessions`;
export const INSERT_USER_SESSION = `${MIDDLEWARE_HOST}device-control/insert-device`;
export const UPDATE_USER_SESSION = `${MIDDLEWARE_HOST}device-control/update-activity`;
export const ENABLE_USER_SESSION = `${MIDDLEWARE_HOST}device-control/update-enable-view`;
export const DELETE_USER_SESSION = `${MIDDLEWARE_HOST}device-control/delete-session`;
export const ACTIVATE_TOKEN = `${MIDDLEWARE_HOST}user/actualizar-token-tv`;
//export const ACCESS_TOKEN = `${MIDDLEWARE_HOST}user/access-token/${COUNTRY_CODE_KEY}`;
export const REFRESH_TOKEN_OTT = `${MIDDLEWARE_HOST}user/refresh-access-token-tigosport/${COUNTRY_CODE_KEY}`
export const ACCESS_TOKEN = `${MIDDLEWARE_HOST}user/access-token-tigosport/${COUNTRY_CODE_KEY}`;
export const ACCESS_TOKEN_AUTH0 = `${MIDDLEWARE_HOST}user/access-token-tigosport-auth0/${COUNTRY_CODE_KEY}`;
export const REFRESH_TOKEN_OTT_AUTH0 = `${MIDDLEWARE_HOST}user/refresh-access-token-tigosport-auth0/${COUNTRY_CODE_KEY}`
export const GET_ENTRIES_FINDER = `${MIDDLEWARE_HOST}entries/get-entries/${COUNTRY_CODE_KEY}`;
export const DRM_TOKEN_V2 = `${MIDDLEWARE_HOST}security/jwt/${COUNTRY_CODE_KEY}`;
export const FAIRPLAY_CERTIFICATE = 'https://s3.amazonaws.com/ott-content/gdvcas-develop/assets/fps_certificate.der';
export const TEAMS = `${MIDDLEWARE_HOST}content/get-teams/${COUNTRY_CODE_KEY}/0/20`;
export const GET_CONTENT_CATEGORY = `${MIDDLEWARE_HOST}content/get-other-content-list/${COUNTRY_CODE_KEY}`;
export const DATA_FACTORY = `${MIDDLEWARE_HOST}data-factory`
export const FACTORY_PUBLISHER = 'https://estaticopy.tigocloud.net/datafactory/html/v3'
export const GET_FAVORITE_TEAM = `${MIDDLEWARE_HOST}user/team-favorite`;
export const GET_RECOMMENDED_PERSONALIZE = `${MIDDLEWARE_HOST}content/get-recommended-personalize/${COUNTRY_CODE_KEY}`;

export const TOKENIZATION = `${MIDDLEWARE_HOST}payments/card-tokenization`;
export const PAYMENT_CONFIGURATION = `${MIDDLEWARE_HOST}payments/payment-config/${COUNTRY_CODE_KEY}`;
export const STATE_PAYMENT = `${MIDDLEWARE_HOST}payments/state-payment`;

export const SUSCRIPTIONS = `${MIDDLEWARE_HOST}payments`;
export const GETSUSCRIPTIONS = `${MIDDLEWARE_HOST}payments/enrollments`;
export const DELETESUSCRIPTIONS = `${MIDDLEWARE_HOST}payments/enrollments`;

export const PURCHASE = `${MIDDLEWARE_HOST}payments/payment-enrollment/`;

export const TRANSACTION_DETAIL =`${MIDDLEWARE_HOST}payments/detail-transaction/${COUNTRY_CODE_KEY}`;
export const REFRESH_TOKEN = `${MIDDLEWARE_HOST}user/refresh-access-token/${COUNTRY_CODE_KEY}`;
export const GETTRANSACTIONINFO = `${MIDDLEWARE_HOST}user/suscription-info`;
export const VALIDATEPIN = `${MIDDLEWARE_HOST}entitlement/registercreditvalidatepin/${COUNTRY_CODE_KEY}`;

export const LOGS_DRM = `${MIDDLEWARE_HOST}drm`;
export const GET_ALLENTITLEMENT = `${MIDDLEWARE_HOST}entitlement/validate-entitlement/${COUNTRY_CODE_KEY}`;
export const PRUCHASE_SINGLE = `${MIDDLEWARE_HOST}payments/single-payment/`;

export const FAVORITE_TEAMS = `${MIDDLEWARE_HOST}user/team-favorites`;

//favorite-teams
export const GET_FAVORITE_TEAMS = `${MIDDLEWARE_HOST}content/get-teams/${COUNTRY_CODE_KEY}`;
export const GET_FAVORITE_NATION_TEAMS = `${MIDDLEWARE_HOST}content/get-teams-selection/${COUNTRY_CODE_KEY}`;
export const GET_LEAGUES_TO_FOLLOW = `${MIDDLEWARE_HOST}content/get-leagues/${COUNTRY_CODE_KEY}`;
export const ADD_FAVORITE_TEAM = `${MIDDLEWARE_HOST}user/team-favorites-football`;
export const ADD_FOLLOWED_LEAGUE = `${MIDDLEWARE_HOST}user/league-favorites-football`;
export const GET_MY_FAVORITE_TEAMS = `${MIDDLEWARE_HOST}user/team-favorites-football`;
export const GET_MY_FAVORITE_NATION_TEAMS = `${MIDDLEWARE_HOST}user/team-favorites-football-selection`;
export const GET_MY_FOLLOWED_LEAGUES = `${MIDDLEWARE_HOST}user/league-favorites-football`;
export const DELETE_MY_FAVORITE_NATION_TEAMS = `${MIDDLEWARE_HOST}user/team-favorites-football-item`;

//carrier billing
export const VALIDATE_PROVIDER = `${MIDDLEWARE_HOST}carrier-billing/validate-provider/${COUNTRY_CODE_KEY}`;
export const LOGIN_WEB_PROVIDER = `${MIDDLEWARE_HOST}carrier-billing/login-web`;
export const LOGIN_TV_PROVIDER = `${MIDDLEWARE_HOST}carrier-billing/login-tv`;
export const LOGIN_MOBILE_PROVIDER = `${MIDDLEWARE_HOST}carrier-billing/login-mobile`;

//export const CHANGE_CODE = `${MIDDLEWARE_HOST}carrier-billing/changeCode/${COUNTRY_CODE_KEY}`;
export const VALIDATE_SESSION = `${MIDDLEWARE_HOST}carrier-billing/valid-session/${COUNTRY_CODE_KEY}`;
export const VALIDATE_ENTITLEMENT = `${MIDDLEWARE_HOST}carrier-billing/entitlement/${COUNTRY_CODE_KEY}`;
export const REMOVE_SESSION = `${MIDDLEWARE_HOST}carrier-billing/logout/${COUNTRY_CODE_KEY}`;

//new version for carrier biling
export const CHANGE_CODE = `${MIDDLEWARE_HOST}carrier-billing/change-code-tigosport/${COUNTRY_CODE_KEY}`;
export const REFRESH_CODE =  `${MIDDLEWARE_HOST}carrier-billing/refresh-code-tigosport/${COUNTRY_CODE_KEY}`;

export const DEVICE_FINGER = `${MIDDLEWARE_HOST}payments/device-finger-print/${COUNTRY_CODE_KEY}`;
//cargo factura
export const VALIDATE_USER_CF =   `${MIDDLEWARE_HOST}invoice-charge/${COUNTRY_CODE_KEY}`;


//delete user
export const DELETE_USER =  `${MIDDLEWARE_HOST}user/delete-account/${COUNTRY_CODE_KEY}`;

export const NEW_LOGIN_TV = `${MIDDLEWARE_HOST}user/get-token-tv/${COUNTRY_CODE_KEY}`;
export const PURCHASE_PAYPAL = `${MIDDLEWARE_HOST}payments/paypal/${COUNTRY_CODE_KEY}`;
export const GET_ORDER_PAYPAL = `${MIDDLEWARE_HOST}payments/paypal`;
export const PLATFORM_LOCATION = `${MIDDLEWARE_HOST}location/get-platform-location`;

// authenticated HE
export const ACCESS_TOKEN_HE = `${MIDDLEWARE_HOST}user/access-token-tigosport-v2/${COUNTRY_CODE_KEY}`;
export const REFRESH_TOKEN_OTT_HE = `${MIDDLEWARE_HOST}user/refresh-access-token-tigosport-v2/${COUNTRY_CODE_KEY}`;
export const LOGOUT_SESSION_HE = `${MIDDLEWARE_HOST}user/logout-HE/${COUNTRY_CODE_KEY}`;
