import {Injectable} from '@angular/core';
import {DRM_TOKEN_V2, FAIRPLAY_CERTIFICATE, FAIRPLAY_SERVER} from 'src/app/config/endpoints';
import {HttpClient} from '@angular/common/http';
import {Platform} from '@ionic/angular';
import AvPlayerDrmIos, {AVPlayerServiceService} from './avplayer-service.service';
import {DeviceHelper} from '../core/helpers/device.helper';
import {GeolocationService} from './geolocation.service';
import {Storage} from '../core/storage/storage';
import {TextManagementService} from './text-management.service';
import {COUNTRY_CODE_KEY, IS_ADAPTABLE} from '../config/constants';

@Injectable({
  providedIn: 'root'
})

export class DrmIosEventService {

  url = '';
  isLoading = false;

  constructor(
      private httpClient: HttpClient,
      private platform: Platform,
      private avPlayerService: AVPlayerServiceService,
      private device: DeviceHelper,
      private geolocationService: GeolocationService,
      private storage: Storage,
      private deviceHelper: DeviceHelper,
      private textManagementService: TextManagementService
  ) { }

  async playDrmStreaming(gameData: any, streamingUrl: any, streamToken: any, cts: any, uuid: string, isVecima: boolean) {
    const texts = await this.textManagementService.getTexts();
    const drmEndpoints = texts.VIDEO_STREAMING;
    const streamingHLSURL = streamingUrl;
    const ttsToken = await gameData?.token;
    const eventType = await gameData?.content_type;
    let jwtToken = '';
    if (isVecima) {
      jwtToken = gameData?.jwtToken;
    } else {
      jwtToken = await this.getTokenPlayer('fairplay',gameData);
    }
    await this.avPlayerService.play(
      streamingHLSURL, streamToken, jwtToken,
      ttsToken, cts, drmEndpoints.fCert, drmEndpoints.fServer, texts.FINGER_PRINTING,
      eventType, uuid, isVecima
    );
  }

  async playStreaming(gameData: any, streamingUrl: any, cts: any, uuid: string, isVecima: boolean) {
    const texts = await this.textManagementService.getTexts();
    const streamingHLSURL = streamingUrl;
    const jwtToken = await gameData?.token;
    const eventType = await gameData?.content_type;
   await this.avPlayerService.playNoDRM(
      streamingHLSURL, jwtToken, cts,
      texts.FINGER_PRINTING, eventType, uuid, isVecima);
  }

  async getTokenPlayer(type: string, eventData: any): Promise<string> {
    // Time variables
    const tNow = Math.floor(Date.now() / 1000) - 5;
    const tEnd = Math.floor(Date.now() / 1000) + 30;
    const myuuid = await this.device.getUUID().then();
    const user = this.storage.getUser() ? this.storage.getUser() : this.storage.getUserCarrierBilling();
    const noCh = eventData.channelNumber;
    const wsl = eventData.securityLevel;
    const dhcpV = eventData.dhcpValue;
    const uuid = user.id;
    const isBrowser = (this.platform.is('mobileweb') || this.platform.is('desktop')) && IS_ADAPTABLE;

    const token: any = await this.getDrmToken(eventData);

    return token;
  }

  async getDrmToken(data: any): Promise<string> {
    let tokenDrm = '';
    const apiResponse: any = await this.httpClient.get(`${DRM_TOKEN_V2}/${data.contentful_id}/${data.eventType}`).toPromise();
    if (apiResponse) {
      tokenDrm = apiResponse.token;
    }
    return tokenDrm;
  }

  currentTime = async () => await this.avPlayerService.currentTime();
  duration = async () => await this.avPlayerService.duration();
}
