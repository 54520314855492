import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Auth0Helper {
  createRandomStringAuth0= () => {
    const charset =
      '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_~.';
    let random = '';
    const randomValues = Array.from(
      window.crypto.getRandomValues(new Uint8Array(43))
    );
    randomValues.forEach(v => (random += charset[v % charset.length]));
    return random;
  };
  sha256Auth0 = async (s: string) => {
    const digestOp: any = window.crypto.subtle.digest(
      { name: 'SHA-256' },
      new TextEncoder().encode(s)
    );

    return await digestOp;
  };
  urlEncodeB64Auth0 = (input: string) => {
    const b64Chars: { [index: string]: string } = { '+': '-', '/': '_', '=': '' };
    return input.replace(/[+/=]/g, (m: string) => b64Chars[m]);
  };
  bufferToBase64UrlEncodedAuth0 = (input: number[] | Uint8Array) => {
    const ie11SafeInput = new Uint8Array(input);
    return this.urlEncodeB64Auth0(
      window.btoa(String.fromCharCode(...Array.from(ie11SafeInput)))
    );
  };

  generateStateHE = (value: number) => {
    const array = new Uint8Array(value);
    window.crypto.getRandomValues(array);
    return Array.from(array, byte => ('0' + byte.toString(16)).slice(-2)).join('');
 };


  createRandomStringHE= () => {
    const charset =
      '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZACRY!rr:S]h0H/dVT,tpA_vCif@95u';
    let random = '';
    const randomValues = Array.from(
      window.crypto.getRandomValues(new Uint8Array(43))
    );
    randomValues.forEach(v => (random += charset[v % charset.length]));
    return random;
  };

}
