import { Injectable } from '@angular/core';
import { Storage } from '../core/storage/storage';
import { environment } from '../../environments/environment';
import { SegmentUtil } from '../core/utils/segment.util';
import { Capacitor } from '@capacitor/core';
import { User } from '../core/interfaces/user';
import { PPV_PG } from '../config/constants';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {

  constructor(private storage: Storage,
    private segmentUtil: SegmentUtil) {
  }

  public async getUrl(): Promise<string> {
    let url: string;
    const { PPV_URL } = environment;
    const deviceContext = await this.segmentUtil.getDeviceContext();
    const s_data = btoa(JSON.stringify(deviceContext).replace(/[\u{0080}-\u{10FFFF}]/gu,""));
    const location = this.storage.getLocation();
    const platform = (Capacitor.getPlatform() !== 'web') ? 'mobile' : 'web';
    url = `${PPV_URL}/?country=${environment.COUNTRY}&pg=${PPV_PG}&s_data=${s_data}&platform=${platform}`;
    const authToken = this.storage.getAuthToken();
    const user: User = this.storage.getUser();

    if (user) {
      const client = {
        id_token: authToken,
        access_token: this.storage.getAccessToken(),
        type: user.login_type
      };
      const clientID = btoa(JSON.stringify(client));
      url = `${url}&client_id=${clientID}`;
    }

    return url;
  }
}
