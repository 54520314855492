<ng-container>
  <markdown class="variable-binding" [data]="content"></markdown>

  <!--
  <div *ngFor="let itemCont of content">
    <ng-template
      [ngTemplateOutlet]="paragraph"
      [ngTemplateOutletContext]="{items: itemCont}"></ng-template>
    <ng-template
      [ngTemplateOutlet]="unorderedList"
      [ngTemplateOutletContext]="{items: itemCont}"></ng-template>
  </div>

  <ng-template #paragraph let-items='items'>
    <p class="paragraph" *ngIf="(items.nodeType=='paragraph' && items.content.length > 0)">
      <ng-container *ngFor="let itemP of items.content">
        <ng-template [ngTemplateOutlet]="text" [ngTemplateOutletContext]="{itemT: itemP}"></ng-template>
        <ng-template [ngTemplateOutlet]="hyperlink" [ngTemplateOutletContext]="{itemH: itemP}"></ng-template>
      </ng-container>
    </p>
  </ng-template>

  <ng-template #unorderedList let-items='items'>
    <ng-container *ngIf="items.nodeType=='unordered-list'">
      <ul *ngFor="let itemL of items.content">
        <ng-template
          [ngTemplateOutlet]="paragraph"
          [ngTemplateOutletContext]="{items: itemL}"></ng-template>
        <ng-template
          [ngTemplateOutlet]="listItem"
          [ngTemplateOutletContext]="{items: itemL}"></ng-template>
      </ul>
    </ng-container>
  </ng-template>

  <ng-template #text let-itemT='itemT'>
    <ng-container *ngIf="(itemT.value && itemT.nodeType=='text')">{{itemT.value}}</ng-container>
  </ng-template>

  <ng-template #hyperlink let-itemH='itemH'>
    <ng-container *ngIf="(itemH.content && itemH.nodeType=='hyperlink')">
      <ng-container *ngFor="let itemHLink of itemH.content">
        <ng-container *ngIf="(itemHLink.value)">
          <a href={{itemHLink.value}}>{{itemHLink.value}}</a>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-template>

  <ng-template #listItem let-items='items'>
    <li *ngIf="items">
      <ng-template
        *ngFor="let itemL of items.content"
        [ngTemplateOutlet]="paragraph"
        [ngTemplateOutletContext]="{items: itemL}"></ng-template>
    </li>
  </ng-template>
  -->
</ng-container>
