import {Component, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import {SegmentAnalytics} from '../../../../../core/providers/segment-analytics';
import {EVENT_TRACK_057, EVENT_TRACK_116} from '../../../../../config/analytics/events-track';
import {ActivatedRoute, Router} from '@angular/router';
import { IS_ADAPTABLE } from '../../../../../config/constants';
import { Storage } from 'src/app/core/storage/storage';
import {Platform} from '@ionic/angular';
import {TextManagementService} from '../../../../../services/text-management.service';
import { ChromecastService } from 'src/app/services/chromecast.service';
import {Broadcaster} from '@awesome-cordova-plugins/broadcaster/ngx';
import { Subscription }  from 'rxjs';
import {Capacitor} from '@capacitor/core';

@Component({
  selector: 'app-main-mobile-toolbar',
  templateUrl: './main-mobile-toolbar.component.html',
  styleUrls: ['./main-mobile-toolbar.component.scss'],
})
export class MainMobileToolbarComponent implements OnInit, OnDestroy {
  EVENT_TRACK_057 = EVENT_TRACK_057;
  datachromecast: any;
  off: boolean;
  appid: string;
  seechromecast: boolean = false;
  iconchromecaston = 'assets/graphics/chromecast/ico_castON.svg';
  iconchromecastoff = 'assets/graphics/chromecast/ico_castOFF.svg';
  isDeviceControl: Subscription;
  isadaptable = IS_ADAPTABLE;
  native = Capacitor.isNativePlatform();
  ios = Capacitor.getPlatform().includes('ios');
  @Output() isdevicecontrol = new EventEmitter<string>();
  @Output() clodemodaldevicecontrol = new EventEmitter<string>();

  constructor(
    private segmentAnalytics: SegmentAnalytics,
    private storage: Storage,
    private route: Router,
    private platform: Platform,
    private textManagementService: TextManagementService,
    private chromecastservice: ChromecastService,
    public activatedRoute: ActivatedRoute,
    private broadCasterNative: Broadcaster

  ) {
    this.off = true;
    this.seechromecast = false;
    this.ValiateAndroidChromecast();
  }

  ngOnDestroy(): void {
    if (this.datachromecast && this.platform.platforms().indexOf('android') >= 0) {
      this.datachromecast?.unsubscribe();
      this.isDeviceControl?.unsubscribe();
    }
  }

  ngOnInit() {}

  public async eventTrack(action: string, properrties: any): Promise<void> {
    await this.segmentAnalytics.eventTrack(action, properrties);
  }

  getRoute(): boolean {

    if (this.route.url.includes('ajustes')) {
      return true;
    }

    return false;
  }

  goToSettings(){
    this.eventTrack(EVENT_TRACK_057, { journey: 'settings' });
    this.validateReplaceUrl('/ajustes');
  }

  validateReplaceUrl(route){
    if(this.storage.getVideoActive() == '1'){
      this.route.navigate([route],{ replaceUrl: true });
      this.storage.setVideoActive('0');
    }
  }

  async ValiateAndroidChromecast() {
    const idContentful = this.activatedRoute.snapshot.params.id;
    const eventType = this.activatedRoute.snapshot.params.type;
    if ((idContentful && eventType && this.route.url.includes('detail/'))
      && this.platform.platforms().indexOf('android') >= 0 && this.isadaptable && Capacitor.isNativePlatform()) {
      const { CHROMECAST } = await this.textManagementService.getTexts();
      if (CHROMECAST?.enable) {
        this.appid = CHROMECAST?.appId;
        // is inizialite values
        await this.EventsChromeCast();
        this.ValidateIsdrmAndInitialized().then(() => {
          const datemedia = this.chromecastservice.getDataMediaChomecast() ? this.chromecastservice.getDataMediaChomecast() : this.storage.getChromecastBody();
          if (datemedia) {
            this.chromecastservice.RemoveStreamingChromecast();
            this.chromecastservice.StopAutomaticChromecast(datemedia);
          }
        });
      }
    }
  }

  async CaseChromecast() {
    this.validatedevicecontrol();
    this.eventTrack(EVENT_TRACK_116, { journey: 'user interface' }).then();
    this.isDeviceControl = this.chromecastservice.addItemDevice$.subscribe(async (valid: boolean) => {
       if (valid === true) {
      this.off = !this.off;
      const cmd = document.getElementById('iconcom') as HTMLImageElement;
      if (!this.off) {
        cmd.src = this.iconchromecaston;
      } else {
        cmd.src = this.iconchromecastoff;
      }
      this.clodemodaldevicecontrol.emit('true');
      const result = await this.chromecastservice.validateUrlStream(this.chromecastservice.getTransmitionChromecast());
      this.isDeviceControl?.unsubscribe();
      if (!result) {
        cmd.src = this.iconchromecastoff;
      }
    }
    });
  }


  async ValidateIsdrmAndInitialized() {
 /*   this.isdrm = this.chromecastservice.addItemIsDrm$.subscribe(isdrm => {
      /* if (!isdrm) {
          this.chromecastservice.Initializatedplugin(this.appid);
          this.seechromecast = true;
       } else {
         this.seechromecast = false;
       }
      this.seechromecast = true;
      this.chromecastservice.Initializatedplugin(this.appid);
    });*/
  this.seechromecast = true;
  await this.chromecastservice.Initializatedplugin(this.appid).then();
  }

   validatedevicecontrol() {
     this.isdevicecontrol.emit('true');
  }

  async EventsChromeCast() {
    this.datachromecast = this.broadCasterNative.addEventListener('eventRunChromecast').subscribe((event) => {
      const RESULT: any = this.chromecastservice.switchEventsForChromecast(event);
      const cmd = document.getElementById('iconcom') as HTMLImageElement;
      if (RESULT?.event === 'stop') {
        this.chromecastservice.RemoveDataMediaChromecast();
        this.chromecastservice.RemoveStreamingChromecast();
        this.isDeviceControl?.unsubscribe();
      }
      if (RESULT?.event === 'cancel') {
        this.isDeviceControl?.unsubscribe();
        const datamedia = this.chromecastservice.getDataMediaChomecast() ? this.chromecastservice.getDataMediaChomecast() : this.storage.getChromecastBody();
        if (datamedia) {
          RESULT.data.off = false;
          cmd.src = this.iconchromecaston;
        }
      }
      this.off = RESULT?.data.off;
      if (this.off && cmd) {
        cmd.src = this.iconchromecastoff;
      }
    });
  }

}
