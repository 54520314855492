import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import {SegmentAnalytics} from '../../../core/providers/segment-analytics';
import { BrowserService } from 'src/app/services/browser.service';
import {TextManagementService} from '../../../services/text-management.service';
import { TvLoginService } from 'src/app/services/tv-login.service';
import {Storage} from '../../../core/storage/storage';
import {EVENT_TRACK_146, EVENT_TRACK_148} from "../../../config/analytics/events-track";


@Component({
  selector: 'app-message-dialog-select-user-master',
  templateUrl: './message-dialog-select-user-master.component.html',
  styleUrls: ['./message-dialog-select-user-master.component.scss'],
})
export class MessageDialogSelectUserMasterComponent  implements OnInit {

  @Input() data:any;
  constructor(private modalCtrl:ModalController,private navController: NavController,
              private segmentAnalytics: SegmentAnalytics,) { }

  ngOnInit() {

  }
  async close(){
    this.eventTrack(EVENT_TRACK_148, {journey: 'Concurrent User Master', option_selected: 'later'});
    await this.modalCtrl.dismiss();
    this.eventTrack(EVENT_TRACK_146, {journey: 'Concurrent User Master', option_selected: 'later'});
    if(this.data.route_back)
      this.navController.navigateForward(this.data.route_back);
 }

 async goNow(){
   this.eventTrack(EVENT_TRACK_148, {journey: 'Concurrent User Master', option_selected: 'now'});
   await this.modalCtrl.dismiss();
   this.eventTrack(EVENT_TRACK_146, {journey: 'Concurrent User Master', option_selected: 'now'});
   if(this.data.route_go)
    this.navController.navigateForward(this.data.route_go);
 }

 eventTrack(event, obj){
    this.segmentAnalytics.eventTrack(event, obj).then();
 }

}
