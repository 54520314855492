import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ConfigurationsService} from './configurations.service';
import { Storage } from '../core/storage/storage';
import * as moment from 'moment';
import { NavController } from '@ionic/angular';
import { LocationService } from './location.service';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  constructor(private configurationService: ConfigurationsService,private storage: Storage, private navController:NavController,private locationService:LocationService) { }

  public async getMaintenanceRedirect() {
   try{
       let storedOptions = this.storage.getMaintenanceInfo();
       if (storedOptions) {
         const day = moment().diff(storedOptions.createdAt, 'minutes',true);
         if (day >= 1) {
           await this.saveMaintenance();
         }
         if(this.storage.getMaintenanceInfo()?.data?.active){
          this.navController.navigateRoot('/-/Mantenimiento');
         }
       } else {
         await this.saveMaintenance();
         if(this.storage.getMaintenanceInfo()?.data?.active){
          this.navController.navigateRoot('/-/Mantenimiento');
         }
         return this.storage.getMaintenanceInfo();
       }
   }catch(e){
     console.log(e);
   }
    
  }

  private async saveMaintenance(){
    await this.locationService.locate();
    try{
    const maintenanceOptions =  await this.configurationService.getConfigurationByVariable("ott_maintenance_redirect");
    if(maintenanceOptions){
    this.storage.setMaintenanceInfo(maintenanceOptions);
    }
   }catch(e){
    console.log(e);
   }
  }
}
