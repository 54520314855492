<div *ngIf="showFavorites" class="eventCardsVertical">
  <h3>Mis favoritos</h3>
  <ion-grid>
    <ion-row>
      <div *ngIf="isWeb" class="carrousel__arrow arrowLeft" tabindex="0" (click)="handleScrollSegment(-250)">
        <ion-img src="assets/graphics/options/buttons/ico_arrow.svg"></ion-img>
      </div>
      <ion-col>
        <ion-segment id="favorites__segment" class="tv-row tv-control-none" [ngClass]="{'segment-tv-vertical': !isAdaptable}" scrollable (scroll)="doSomethingOnScroll($event)">
          <div *ngIf="banneravailable && isAdaptable" id='div-gpt-ad-1661900751418-0' style='min-width: {{widthbanner}}px; min-height: {{heightbanner}}px; margin-right: {{marginbanner}}em;'>
          </div>
          <div class="eventCardsVertical__Item" [ngClass]="{'tv-control': !isAdaptable}" *ngFor="let item of favorites" value="item.title" (click)="viewDetail(item)" [id]="'favorites-' + item.contentful_id" tabindex="8">
            <ion-img class="itemImg" src={{item.image}} alt="1"></ion-img>
            <div class="icoFavorite">
            </div>
            <ion-img class="icoFavorite__img" src="assets/graphics/options/buttons/ico_favorite.svg"></ion-img>
            <img class="icoPlayInternal" src="assets/graphics/options/buttons/ico_play_internal.svg" alt="">
            <div class="eventCardsVertical__Info">
              <ion-label class="infoTitle">{{item.title}}</ion-label>
            </div>
          </div>
        </ion-segment>
      </ion-col>
      <div *ngIf="isWeb" class="carrousel__arrow arrowRight" tabindex="2" (click)="handleScrollSegment(250)">
        <ion-img src="assets/graphics/options/buttons/ico_arrow.svg"></ion-img>
      </div>
    </ion-row>
  </ion-grid>
</div>
