<ion-content>
  <div class="text-center">
    <div class="row">
      <div class="col-sm-1 col-md-2 col-icon">
        <img [src]="data.img_src" class="icon" alt="ico">
      </div>
      <div class="col-sm-8 col-md-8 col-title">
        <p class="title">{{data.title}}</p>
      </div>
      <div class="col-sm-1 col-md-2 col-close">
        <img src="assets/graphics/pictures/close-toast.svg" class="close" (click)="close()" alt="ico">
      </div>
    </div>  
  </div>
</ion-content>
<span [ngSwitch]="data.status">
  <div *ngSwitchCase="MessageToastStatus.successed" class="toast-success"></div>
  <div *ngSwitchCase="MessageToastStatus.failured" class="toast-failured"></div>
</span>