import { Component, OnInit } from '@angular/core';
import {Storage} from '../../../core/storage/storage';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-progress-load',
  templateUrl: './progress-load.component.html',
  styleUrls: ['./progress-load.component.scss'],
})
export class ProgressLoadComponent implements OnInit {
  imageSource;

  constructor(
    private storage: Storage,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    //this.imageSource = this.sanitizer.bypassSecurityTrustResourceUrl(this.storage.getImgCacheLoadign());
  }

}
