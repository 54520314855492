import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { IS_ADAPTABLE } from "src/app/config/constants";
import { Platform } from "@ionic/angular";
@Injectable({
    providedIn: "root"
})

export class CSSOverlayButtons {
    private renderer: Renderer2;
    constructor(
        rendererFactory: RendererFactory2,
        private platform: Platform,
        @Inject(DOCUMENT) private document
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    public register(): void {
        if (!IS_ADAPTABLE)
            this.registerCssOverlay();
    }

    private registerCssOverlay(): void {
        const head = this.document.getElementsByTagName('head')[0];
        const url = 'assets/css/videojs-overlay-buttons.css';
        const style = this.document.createElement('link');
        style.id = 'client-theme';
        style.rel = 'stylesheet';
        style.href = `${url}`;
        head.appendChild(style);
    }

}