import { Injectable } from '@angular/core';
import { BillingControlBase } from '../shared/components/card-form/base-model/billing-control-base';
import { BillingTextboxControl } from '../shared/components/card-form/base-model/billing-control-textbox';
import { BillingDropdownControl } from '../shared/components/card-form/base-model/billing-control-dropdown';
import { ConfigurationsService } from './configurations.service';

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  constructor(
    private configurationService: ConfigurationsService
  ) { }


  tempControls(){
    return (
      [
        {
          "code": "PY",
          "country": "Paraguay",
          "options": [

            {
              "key": "firstName",
              "max": 0,
              "min": 0,
              "type": "text",
              "email": false,
              "label": "Nombre",
              "order": 1,
              "title": "Datos de factura",
              "value": "",
              "pattern": "",
              "required": false,
              "maxLength": 0,
              "minLength": 0,
              "maxMessage": "",
              "minMessage": "",
              "controlType": "textbox",
              "placeholder": "",
              "emailMessage": "",
              "patternMessage": "",
              "requiredMessage": "*Ingrese el nombre de la persona que debe aparecer en la factura",
              "maxLengthMessage": "",
              "minLengthMessage": ""
            },
            {
              "key": "lastName",
              "max": 0,
              "min": 0,
              "type": "text",
              "email": false,
              "label": "Apellido",
              "order": 1,
              "value": "",
              "pattern": "",
              "required": false,
              "maxLength": 0,
              "minLength": 0,
              "maxMessage": "",
              "minMessage": "",
              "controlType": "textbox",
              "placeholder": "",
              "emailMessage": "",
              "patternMessage": "",
              "requiredMessage": "*Ingrese el apellido de la persona que debe aparecer en la factura",
              "maxLengthMessage": "",
              "minLengthMessage": ""
            },
            {
              "key": "documentType",
              "max": 0,
              "min": 0,
              "type": "text",
              "email": false,
              "label": "Tipo de documento",
              "value": "",
              "options": [
                {
                  "key": "RU",
                  "value": "RUC",
                  "optionsBilling": [
                    {
                      "key": "documentNumber",
                      "max": 0,
                      "min": 0,
                      "type": "text",
                      "email": false,
                      "label": "RUC (con dígito verificador)",
                      "order": 1,
                      "value": "",
                      "pattern": "",
                      "required": false,
                      "maxLength": 0,
                      "minLength": 0,
                      "maxMessage": "",
                      "minMessage": "",
                      "controlType": "textbox",
                      "placeholder": "",
                      "emailMessage": "",
                      "patternMessage": "",
                      "requiredMessage": "",
                      "maxLengthMessage": "",
                      "minLengthMessage": ""
                    }
                  ]
                },
                {
                  "key": "CI",
                  "value": "CI",
                  "optionsBilling": [
                    {
                      "key": "documentNumber",
                      "max": 0,
                      "min": 0,
                      "type": "text",
                      "email": false,
                      "label": "Número de CI",
                      "order": 1,
                      "value": "",
                      "pattern": "",
                      "required": false,
                      "maxLength": 0,
                      "minLength": 0,
                      "maxMessage": "",
                      "minMessage": "",
                      "controlType": "textbox",
                      "placeholder": "",
                      "emailMessage": "",
                      "patternMessage": "",
                      "requiredMessage": "",
                      "maxLengthMessage": "",
                      "minLengthMessage": ""
                    }
                  ]
                },
                {
                  "key": "PP",
                  "value": "Pasaporte",
                  "optionsBilling": [
                    {
                      "key": "documentNumber",
                      "max": 0,
                      "min": 0,
                      "type": "text",
                      "email": false,
                      "label": "Número de pasaporte",
                      "order": 1,
                      "value": "",
                      "pattern": "",
                      "required": false,
                      "maxLength": 0,
                      "minLength": 0,
                      "maxMessage": "",
                      "minMessage": "",
                      "controlType": "textbox",
                      "placeholder": "",
                      "emailMessage": "",
                      "patternMessage": "",
                      "requiredMessage": "",
                      "maxLengthMessage": "",
                      "minLengthMessage": ""
                    }
                  ]
                }
              ],
              "pattern": "",
              "required": false,
              "maxLength": 0,
              "minLength": 0,
              "maxMessage": "",
              "minMessage": "",
              "controlType": "dropdown",
              "placeholder": "",
              "emailMessage": "",
              "patternMessage": "",
              "requiredMessage": "",
              "maxLengthMessage": "",
              "minLengthMessage": ""
            }
          ]
        },
        {
          "code": "US",
          "country": "Estados Unidos",
          "options": [
            {
              "key": "state",
              "max": 0,
              "min": 0,
              "type": "text",
              "email": false,
              "label": "Estado",
              "value": "",
              "options": [
                {
                  "key": "AL",
                  "value": "Alabama"
                },
                {
                  "key": "AK",
                  "value": "Alaska"
                },
                {
                  "key": "AS",
                  "value": "American Samoa"
                },
                {
                  "key": "AZ",
                  "value": "Arizona"
                },
                {
                  "key": "AR",
                  "value": "Arkansas"
                },
                {
                  "key": "CA",
                  "value": "California"
                },
                {
                  "key": "CO",
                  "value": "Colorado"
                },
                {
                  "key": "CT",
                  "value": "Connecticut"
                },
                {
                  "key": "DE",
                  "value": "Delaware"
                },
                {
                  "key": "DC",
                  "value": "District Of Columbia"
                },
                {
                  "key": "FM",
                  "value": "Federated States Of Micronesia"
                },
                {
                  "key": "FL",
                  "value": "Florida"
                },
                {
                  "key": "GA",
                  "value": "Georgia"
                },
                {
                  "key": "GU",
                  "value": "Guam"
                },
                {
                  "key": "HI",
                  "value": "Hawaii"
                },
                {
                  "key": "ID",
                  "value": "Idaho"
                },
                {
                  "key": "IL",
                  "value": "Illinois"
                },
                {
                  "key": "IN",
                  "value": "Indiana"
                },
                {
                  "key": "IA",
                  "value": "Iowa"
                },
                {
                  "key": "KS",
                  "value": "Kansas"
                },
                {
                  "key": "KY",
                  "value": "Kentucky"
                },
                {
                  "key": "LA",
                  "value": "Louisiana"
                },
                {
                  "key": "ME",
                  "value": "Maine"
                },
                {
                  "key": "MH",
                  "value": "Marshall Islands"
                },
                {
                  "key": "MD",
                  "value": "Maryland"
                },
                {
                  "key": "MA",
                  "value": "Massachusetts"
                },
                {
                  "key": "MI",
                  "value": "Michigan"
                },
                {
                  "key": "MN",
                  "value": "Minnesota"
                },
                {
                  "key": "MS",
                  "value": "Mississippi"
                },
                {
                  "key": "MO",
                  "value": "Missouri"
                },
                {
                  "key": "MT",
                  "value": "Montana"
                },
                {
                  "key": "NE",
                  "value": "Nebraska"
                },
                {
                  "key": "NV",
                  "value": "Nevada"
                },
                {
                  "key": "NH",
                  "value": "New Hampshire"
                },
                {
                  "key": "NJ",
                  "value": "New Jersey"
                },
                {
                  "key": "NM",
                  "value": "New Mexico"
                },
                {
                  "key": "NY",
                  "value": "New York"
                },
                {
                  "key": "NC",
                  "value": "North Carolina"
                },
                {
                  "key": "ND",
                  "value": "North Dakota"
                },
                {
                  "key": "MP",
                  "value": "Northern Mariana Islands"
                },
                {
                  "key": "OH",
                  "value": "Ohio"
                },
                {
                  "key": "OK",
                  "value": "Oklahoma"
                },
                {
                  "key": "OR",
                  "value": "Oregon"
                },
                {
                  "key": "PW",
                  "value": "Palau"
                },
                {
                  "key": "PA",
                  "value": "Pennsylvania"
                },
                {
                  "key": "PR",
                  "value": "Puerto Rico"
                },
                {
                  "key": "RI",
                  "value": "Rhode Island"
                },
                {
                  "key": "SC",
                  "value": "South Carolina"
                },
                {
                  "key": "SD",
                  "value": "South Dakota"
                },
                {
                  "key": "TN",
                  "value": "Tennessee"
                },
                {
                  "key": "TX",
                  "value": "Texas"
                },
                {
                  "key": "UT",
                  "value": "Utah"
                },
                {
                  "key": "VT",
                  "value": "Vermont"
                },
                {
                  "key": "VI",
                  "value": "Virgin Islands"
                },
                {
                  "key": "VA",
                  "value": "Virginia"
                },
                {
                  "key": "WA",
                  "value": "Washington"
                },
                {
                  "key": "WV",
                  "value": "West Virginia"
                },
                {
                  "key": "WI",
                  "value": "Wisconsin"
                },
                {
                  "key": "WY",
                  "value": "Wyoming"
                }
              ],
              "pattern": "",
              "required": true,
              "maxLength": 0,
              "minLength": 0,
              "maxMessage": "",
              "minMessage": "",
              "controlType": "dropdown",
              "placeholder": "",
              "emailMessage": "",
              "patternMessage": "",
              "requiredMessage": "",
              "maxLengthMessage": "",
              "minLengthMessage": ""
            },
            {
              "key": "city",
              "max": 0,
              "min": 0,
              "type": "text",
              "email": false,
              "label": "Ciudad",
              "value": "",
              "options": [],
              "pattern": "",
              "required": true,
              "maxLength": 0,
              "minLength": 0,
              "maxMessage": "",
              "minMessage": "",
              "controlType": "textbox",
              "placeholder": "",
              "emailMessage": "",
              "patternMessage": "",
              "requiredMessage": "",
              "maxLengthMessage": "",
              "minLengthMessage": ""
            },
            {
              "key": "street",
              "max": 0,
              "min": 0,
              "type": "text",
              "email": false,
              "label": "Calle",
              "value": "",
              "options": [],
              "pattern": "",
              "required": true,
              "maxLength": 0,
              "minLength": 0,
              "maxMessage": "",
              "minMessage": "",
              "controlType": "textbox",
              "placeholder": "",
              "emailMessage": "",
              "patternMessage": "",
              "requiredMessage": "",
              "maxLengthMessage": "",
              "minLengthMessage": ""
            },
            {
              "key": "postalCode",
              "max": 0,
              "min": 0,
              "type": "text",
              "email": false,
              "label": "Código Postal",
              "value": "",
              "options": [],
              "pattern": "",
              "required": true,
              "maxLength": 0,
              "minLength": 0,
              "maxMessage": "",
              "minMessage": "",
              "controlType": "textbox",
              "placeholder": "",
              "emailMessage": "",
              "patternMessage": "",
              "requiredMessage": "",
              "maxLengthMessage": "",
              "minLengthMessage": ""
            }
          ]
        }
      ]
    );
  }

  async getFormControls(controlOption?: any): Promise<any> {
    //const controls = controlOption ? controlOption:this.tempControls();
    const controls = await  this.configurationService.getConfigurationByVariable("store_config_billing_convergent");
    const formatControls = [];
    if (controls) {
      for (const control of controls) {
        //let formControls: BillingControlBase<string>[] = this.buildFormControls(control.options);
        if(control?.options){
        let item_selected = control.options.find(i => i.key === 'documentType');
        if(item_selected){
          for(const billing of item_selected.options){
            billing.optionsBilling[0].isForBillling = true;
            control.options.push(billing.optionsBilling[0])
          }
     }
    }
        const formControls: BillingControlBase<string>[] = this.buildFormControls(controlOption? controlOption:control.options);
        formatControls.push({
          country: control.country ? control.country : '',
          code: control.code ? control.code : '',
          options: formControls
        });


      }
    }
    return formatControls;
  }

  buildFormControls(controls: any): any[] {
    const controlGroup = [];
    let generatedControl: any;
    for (const control of controls) {
      let validControl = true;
      switch (control.controlType) {
        case 'textbox':
          generatedControl = new BillingTextboxControl(control);
          break;
        case 'dropdown':
          generatedControl = new BillingDropdownControl(control);
          break;
        default:
          validControl = false;
      }
      if (validControl) {
        controlGroup.push(generatedControl);
      }
    }

    return controlGroup;
  }

}
