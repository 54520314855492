import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-custom-keyboard',
  templateUrl: './custom-keyboard.component.html',
  styleUrls: ['./custom-keyboard.component.scss'],
})
export class CustomKeyboardComponent implements OnInit {

  @Output() sendText = new EventEmitter<string>();
  @Input() set finderFlagParent(_finderFlag: Boolean) {
    
  };
  focusLine = 1;
  focusColumn = 0;
  textAcc = '';
  
  
  constructor() { }
  
  ngOnInit() {}
  
  /* Handler for keydown event */
  handleKeydown(event) {
    let key = String.fromCharCode(event);
    if (event === 8){
      this.textAcc = this.textAcc.substr(0, this.textAcc.length - 1);
    } else {
      this.textAcc = this.textAcc + key;
    }
    this.sendTextToParent();
  }

  /* Send user input to finder component */
  sendTextToParent() {
    this.sendText.emit(this.textAcc);
  }

}
