import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GET_NEWS } from '../config/endpoints';
import * as moment from "moment";
import { CARROUSEL_LIMIT, IS_ADAPTABLE } from '../config/constants';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  isAdaptable = IS_ADAPTABLE;
  carrouselLimit = 10;
  contPage = 1;
  constructor(
    private httpClient: HttpClient
  ) { }

  /* Get 'news' events from middleware */
  public async getEvents(page: number, assetDomain: any, queryParams?: any): Promise<any> {
    let events: any = [];
    let eventsAux = [];
    let eventsDataAux = {};
    let cont = 0;
    if(page === 1){
      this.contPage = 1;
    }
    try {
      const dataResponse : any = await this.httpClient.get(`${GET_NEWS}/${this.contPage++}/${this.carrouselLimit}`, {params: queryParams}).toPromise();
      dataResponse.forEach((event) => {
        eventsDataAux = {
          contentful_id: event.contentful_id,
          title : event.contentful_data.fields.name,
          image: this.getImage(event, assetDomain),//`https:${event.contentful_data.fields.horizontal_thumbnails.file.fields.file.url}`,
          createdAt: event.contentful_data.sys.createdAt,
          championship_id : event.contentful_data.fields.league.sys.id,
          championship_name : event.league,
          sport_name : event.sport ? event.sport : '',
          video_title: event.contentful_data.fields.name,
          content_id: event.contentful_id,
          home_team_id: event.home_team_data ? event.home_team_data.contentful_id : '',
          home_team: event.home_team_data ? event.home_team_data.json.fields.name : '',
          away_team_id: event.away_team_data ? event.away_team_data.contentful_id : '',
          away_team: event.away_team_data ? event.away_team_data.json.fields.name : '',
          total_length: '',
          session_id: '',
          video_quality: '',
          content_category: 'news',
          content_type: event.contentful_data.sys.contentType.sys.id,
          schedule: this.getFormattedSchedule(new Date(event.contentful_data.fields.startDate)),
          start_date: event.startDate ? event.startDate : '',
          end_date: event.endDate ? event.endDate : '',
        }
        cont += 1;
        if (cont <= this.carrouselLimit) {
          eventsAux.push(eventsDataAux);
        }
      });
      events = dataResponse ? eventsAux : [];
    } catch (error) {}
    return events;
  }

  /* Get formatted schedule */
  private getFormattedSchedule(startDate: Date) {
    let date = startDate.getDate();
    let month = startDate.getMonth();
    let year = startDate.getFullYear();
    let startHour = moment(startDate).format("HH:mm");
    let formattedDate = ""
    if (startDate){
      formattedDate = `${date}/${month + 1}/${year} ${startHour}`;
    }
    return formattedDate;
  }

  private getImage(obj, assetDomain){
    let domain = assetDomain?.highlights ? assetDomain.highlights : assetDomain.default

    if(assetDomain?.status && assetDomain.status === true && obj?.horizontalThumbnailsS3)
      return domain + obj.horizontalThumbnailsS3;
    else
      return `https:${obj.contentful_data.fields.horizontal_thumbnails.file.fields.file.url}`
  }
}
