import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EVENT_TRACK_005 } from '../../../config/analytics/events-track';
import {SegmentAnalytics} from '../../../core/providers/segment-analytics';
import {TigoIdService} from '../../../services/tigo-id.service';

@Component({
  selector: 'app-message-dialogsubscription.component',
  templateUrl: './message-dialogsubscription.component.html',
  styleUrls: ['./message-dialogsubscription.component.scss'],
})
export class MessageDialogsubscriptionComponent implements OnInit {
  eventtrack005 = EVENT_TRACK_005;

  @Input() data:any;
  constructor(private modalCtrl: ModalController, private segmentanalytics: SegmentAnalytics,private tigoID: TigoIdService,) {
  }

  ngOnInit(): void {

  }

  async close(){
    const datasegment = {
      flag: 'VOLVER A INICIO',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      link_url: `${window.location.origin}${window.location.pathname}`,
      journey: 'user interface'
    };
    await this.handleEvent(this.eventtrack005, datasegment);
    await this.modalCtrl.dismiss();
  }

  async goBack(){
    const settings: any = await this.tigoID.getTigoIDSettings();
    window.location.href = settings.redirect+'?togoHome=true';
  }

  async handleEvent(event, objegment) {
    await this.segmentanalytics.eventTrack(event, objegment);
  }

}
