import {Injectable} from '@angular/core';
import {Storage} from '../core/storage/storage';
import {DeviceHelper} from '../core/helpers/device.helper';
import {HttpClient} from '@angular/common/http';
import {ACTIVATE_TOKEN} from '../config/endpoints';
import { environment } from '../../environments/environment';
import {EVENT_TRACK_013} from '../config/analytics/events-track';
import {SegmentAnalytics} from '../core/providers/segment-analytics';

@Injectable({
  providedIn: 'root'
})
export class ActivateCodeService {

  constructor(
    private storage: Storage,
    private device: DeviceHelper,
    private httpClient: HttpClient,
    private segmentAnalytics: SegmentAnalytics
  ) {
  }

  /* Set TV code in storage */
  public setTVCode(code: string): void {
    this.storage.setTVAuthCode(code);
  }

  /* Get TV code from storage */
  public getTVCode(): string {
    return this.storage.getTVAuthCode();
  }

  /* Set TV auth state in storage */
  public setTVAuthState(state: boolean): void {
    this.storage.setTVAuthState(state);
  }

  /* Get TV auth state from storage */
  public getTVAuthState(): boolean {
    return this.storage.getTVAuthState();
  }

  /* Activate code for TV */
  public async activateCode(token: any, type? : string): Promise<void> {
    let body: any = [];
    let url = '';
    if (type) {
      body = {
        id: token.customer.id,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        suscriber_id: token.customer.subscriberId,
        token: token.token,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        token_tv: this.getTVCode()
      };
      url = `${ACTIVATE_TOKEN}/${type}`;
    } else {
      body = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        token_tv: this.getTVCode(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        access_token: token.access_token,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        refresh_token: token.refresh_token,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        expires_in: token.expires_in,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        id_token: token.id_token,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        option_login: this.storage.getOptionLogin()
      };
      url = `${ACTIVATE_TOKEN}`;
    }
    this.storage.setTVAuthState(false);
    try {
      await this.httpClient.post(url, body).toPromise();
    } catch (e) {
      const objError = {
        journey: 'login activate code',
        error_id: e?.status ? e?.status : '',
        error_message: e?.message ? e?.message : '',
        error_type: e?.name ? e?.name : ''
      };
      this.segmentAnalytics.eventTrack(EVENT_TRACK_013, objError).then();
    }
  }

     /* Activate code for TV */
     public async activateCodeV4(token: any, type? : string): Promise<void> {
      let body: any = [];
      let url = '';
      if (type) {
        body = {
          token: token,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          token_tv: this.getTVCode()
        };
        url = `${ACTIVATE_TOKEN}/${type}`;
      } else {
        body = {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          token_tv: this.getTVCode(),
          // eslint-disable-next-line @typescript-eslint/naming-convention
          access_token: token.AccessToken ? token.AccessToken : token.access_token,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          refresh_token: token.RefreshToken ? token.RefreshToken : token.refresh_token,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          expires_in: token.ExpiresIn ?token.ExpiresIn :token.expires_in ,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          id_token: token.IdToken ? token.IdToken: token.id_token,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          option_login: this.storage.getOptionLogin()
        };
        url = `${ACTIVATE_TOKEN}`;
      }
       this.storage.setTVAuthState(false);
      try {
        await this.httpClient.post(url, body).toPromise();
      } catch (e) {
        type = type ? type : 'TigoID';
        const objError = {
          journey: 'login activate code v4 ' + type,
          error_id: e?.status ? e?.status : '',
          error_message: e?.message ? e?.message : '',
          error_type: e?.name ? e?.name : ''
        };
        this.segmentAnalytics.eventTrack(EVENT_TRACK_013, objError).then();
      }
    }

    /* Activated code for V3 */
    public async activateCodeV3(auth: any, type? : string) {
      let body: any = [];
      let url = '';
      if (type) {
        body = {
          id: auth.customer.id,
          suscriber_id: auth.customer.subscriberId,
          token: auth.token,
          token_tv: this.getTVCode()
        };
        url = `${environment?.MIDDLEWARE_PATH}/api/v3/user/actualizar-token-tv/${type}`;
      } else {
        body = {
          token_tv: this.getTVCode(),
          access_token: auth.data.access_token,
          refresh_token: auth.data.refresh_token,
          expires_in: auth.data.expires_in,
          id_token: auth.data.id_token
        };
        url = `${environment?.MIDDLEWARE_PATH}/api/v3/user/actualizar-token-tv`;
      }
      this.storage.setTVAuthState(false);
      try {
        await this.httpClient.post(url, body).toPromise();
      } catch (e) {
        type = type ? type : 'TigoID';
        const objError = {
          journey: 'login activate Code V3' + type,
          error_id: e?.status ? e?.status : '',
          error_message: e?.message ? e?.message : '',
          error_type: e?.name ? e?.name : ''
        };
        this.segmentAnalytics.eventTrack(EVENT_TRACK_013, objError).then();
      }
    }
}
