import { Component, OnInit,Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl,AbstractControl } from '@angular/forms';
import { DeleteUserService } from 'src/app/services/delete-user.service';
import { ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import {NavController} from '@ionic/angular';
import { Router } from '@angular/router';
import {
  EVENT_TRACK_138,
  EVENT_TRACK_155,
  EVENT_TRACK_156,
  EVENT_TRACK_157
} from '../../../config/analytics/events-track';
import {SegmentAnalytics} from '../../../core/providers/segment-analytics';
import {Storage} from "../../../core/storage/storage";
@Component({
  selector: 'app-message-delete',
  templateUrl: './message-delete.component.html',
  styleUrls: ['./message-delete.component.scss'],
})
export class MessageDeleteComponent implements OnInit {
  form: UntypedFormGroup;
  isValid = false;
  showErrorMessage = false;
  @Input() data:any;
  constructor(private formBuilder: UntypedFormBuilder,private deleteUserService: DeleteUserService,   private router: Router,
    private modalCtrl: ModalController, private authService: AuthService, private loadingService: LoadingService,
              private segmentAnalytics: SegmentAnalytics, private storage: Storage) {

    this.form = this.formBuilder.group({
      deleteLabel:['', [Validators.required]]
      // registrationEmail: ['',Validators.required]

    });
  }

  ngOnInit() {
    const user = this.storage.getUser();
    this.trackSegment(EVENT_TRACK_155, {journey: 'delete_user', business_unit: user?.email ? 'home' : 'mobile'});
    this.validateAccountNumber();
  }

  ValidateLabel(control: AbstractControl): {[key: string]: any} | null  {
    if (control.value && control.value !== 'BORRAR') {
      return { 'deleteLabel': true };
    }
    return null;
  }

  async delete(){
    const user = this.storage.getUser();
    this.trackSegment(EVENT_TRACK_156, {journey: 'delete_user', business_unit: user?.email ? 'home' : 'mobile'});
    this.loadingService.setLoading(true,'delete user');
    const response = await this.deleteUserService.deleteUser();
    if(response){
     await this.authService.removeAuthentication();
     this.loadingService.setLoading(false,'delete user');
     this.modalCtrl.dismiss();
    this.trackSegment(EVENT_TRACK_157, {journey: 'delete_user', business_unit: user?.email ? 'home' : 'mobile'});
     this.router.navigate(['/ajustes'],{ replaceUrl: true });
     return;
    } else {
      this.trackSegment(EVENT_TRACK_138, {journey: 'ppv'});
    }
    this.loadingService.setLoading(false,'delete user');
    this.showErrorMessage = true;
  }

  public close(){
     this.modalCtrl.dismiss();
  }
  private validateAccountNumber(): void {

    this.form.controls.deleteLabel.valueChanges.subscribe(value => {
      if (value) {
             this.isValid = value === 'BORRAR'
      }
    });
  }

  trackSegment(event, body) {
    this.segmentAnalytics.eventTrack(event, body).then();
  }
}
