import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
})
export class MessageDialogComponent implements OnInit {
   @Input() data:any;
  constructor(private modalCtrl:ModalController) { }

  ngOnInit() {
   
  }

  async close(){
     await this.modalCtrl.dismiss();
  }

}
