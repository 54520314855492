import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-favorite-teams-banner',
  templateUrl: './favorite-teams-banner.component.html',
  styleUrls: ['./favorite-teams-banner.component.scss'],
})
export class FavoriteTeamsBannerComponent  implements OnInit {
  showData = false
  showArrows = false
  pageNumber = 2;
  @Input() data: any;
  @Input() numberOfFilters: any;
  @Output() getData = new EventEmitter<any>();
  favoriteData = [];

  constructor() { }
  async ngOnInit() {
    this.initComponent()
  }

  initComponent(){
    if (this.data.length > 0) {
      try {
        const data = this.data;
        this.data = (data.length > 0) ? data : [];
        this.showData = true
        this.showArrows = (data.length > 2);
      } catch (error) {
      }
    }
  }


  @HostListener('window:scroll', ['$event'])
  async doSomethingOnScroll(event) {
    const totalwidth = Math.round(event.target.offsetWidth + event.target.scrollLeft);
    if (totalwidth === event.target.scrollWidth) {
      //const newArr = await this.liveNowService.getEvents(this.pageNumber);
      const newArr = [];
      this.pageNumber++;
      this.data.push(...newArr);
    }
  }

  ngOnChanges(): void {
    this.initComponent();
  }

  handleScrollSegment(qty: number): void {
    let segmentAux = document.getElementById('followedItems__segment');
    segmentAux.scrollLeft = (segmentAux.scrollLeft + qty);
  }

  filter(item){
    if(item?.selected && item.selected){
      item.selected = false
      let tmp = []
      
      this.favoriteData.forEach(function (element) {
        if(element.contentfulId !== item?.contentfulId){
          tmp.push(element);
        }
      })
      this.favoriteData = tmp;
    }
      
    else{
      item.selected = true
      this.addFavoriteItem(item);
    }
    this.sendData()
  }

  sendData(){
    this.getData.emit(this.favoriteData);
  }

  addFavoriteItem(item){
    this.favoriteData.push(item);
    if(this.favoriteData.length > this.numberOfFilters){
      const tmp = this.favoriteData[0]
      this.favoriteData.shift();
      this.data.forEach(function (element) {

        if(element.contentfulId === tmp?.contentfulId){
          element.selected = false
        }
      })
    }
  }
}
