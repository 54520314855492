export class BillingControlBase<T> {
    value: T;
    key: string;
    label: string;
    required: boolean;
    requiredMessage: string;
    email: boolean;
    emailMessage: string;
    min: number;
    minMessage: string;
    max: number;
    maxMessage: string;
    pattern: string;
    patternMessage: string;
    minLength: number;
    minLengthMessage: string;
    maxLength: number;
    maxLengthMessage: string;
    order: number;
    controlType: string;
    type: string;
    options: { key: string, value: string }[];
    placeholder: string;
    title: string;
    isForBillling?: boolean;

    constructor(options: {
        value?: T,
        key?: string,
        label?: string,
        required?: boolean,
        requiredMessage?: string,
        email?: boolean,
        emailMessage?: string,
        min?: number,
        minMessage?: string,
        max?: number,
        maxMessage?: string,
        pattern?: string,
        patternMessage?: string,
        minLength?: number,
        minLengthMessage?: string,
        maxLength?: number,
        maxLengthMessage?: string,
        order?: number,
        controlType?: string,
        type?: string,
        placeholder?: string,
        title? :string
        isForBillling?: boolean;
    } = {}) {
        this.value = options.value;
        this.key = options.key || '';
        this.label = options.label || '';
        this.required = !!options.required;
        this.requiredMessage = options.requiredMessage || '';
        this.email = !!options.email;
        this.emailMessage = options.emailMessage || '';
        this.min = options.min;
        this.minMessage = options.minMessage || '';
        this.max = options.max;
        this.maxMessage = options.maxMessage || '';
        this.pattern = options.pattern || '';
        this.patternMessage = options.patternMessage || '';
        this.minLength = options.minLength;
        this.minLengthMessage = options.minLengthMessage || '';
        this.maxLength = options.maxLength;
        this.maxLengthMessage = options.maxLengthMessage || '';
        this.order = options.order === undefined ? 1 : options.order;
        this.controlType = options.controlType || '';
        this.type = options.type || '';
        this.placeholder = options.placeholder || '';
        this.title = options.title || '';
        this.isForBillling = options.isForBillling || false;
    }
}
