import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GET_FAVORITE_TEAM, FAVORITE_TEAMS, TEAMS } from '../config/endpoints';
import { Storage } from '../core/storage/storage';
import { BehaviorSubject } from 'rxjs';
import { COUNTRY_CODE_KEY } from '../config/constants';
import { environment } from 'src/environments/environment';
import { Location } from '../core/interfaces/location';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {
  teamSource = new BehaviorSubject(false);
  teamObservable = this.teamSource.asObservable();
  dataFavorites : any = [];

  constructor(
    private httpClient: HttpClient,
    private storage: Storage,
  ) { }

  public async getTeams(): Promise<any[]> {
    let data: any = [];

    try {
      const items = await this.httpClient.get(TEAMS).toPromise();
      this.dataFavorites = await this.getFavoriteTeams();
      data = await this.formatData(items);
    } catch (e) { }

    return data;
  }

  public refreshTeams(): void {
    this.teamSource.next(true);
  }

  private async formatData(items: any): Promise<any[]> {
    let data: any = [];

    if (items) {
      if (items.length > 0) {
        const storedTeams = this.storage.getTeams();
        let itemsFound = false;
        for (const item of items) {
          let status = false;
          if (storedTeams.length > 0) {
            status = storedTeams.includes(item.id);
            if (status || storedTeams[0] === 0) {
              itemsFound = true;
            }
          } else {
            itemsFound = true;
          }
          let isFavorite = false;
          isFavorite = this.dataFavorites.includes(item?.contentful_id);
          data.push({
            id: item?.id,
            contentful_id: item?.contentful_id,
            name: item?.json?.fields?.name,
            img: item?.json?.fields?.shieldImage?.file?.fields?.file?.url,
            favorite: isFavorite,
            status
          });
        }
        if (!itemsFound) {
          data = data.map(i => {
            i.status = false;
            return i;
          });
        }
      }
    }

    return data;
  }

  public async getIsFavoriteTeam(contentfulId: string): Promise<boolean> {
    let response = false;
    try {
      const user = this.storage.getUser();
      if (user) {
        const isFavoriteTeam : any = await this.httpClient.get(`${GET_FAVORITE_TEAM}/${user.id}/${contentfulId}/${COUNTRY_CODE_KEY}`).toPromise();
        if (isFavoriteTeam) {
          response = isFavoriteTeam?.status;
        }
      }
    } catch (error) {}
    return response;
  }

  public async setFavoriteTeam(contentfulId: string, name: string, image: string): Promise<boolean> {
    const location: Location = this.storage.getLocation();
    const user = this.storage.getUser();
    if (user) {
      let auxObject = {
        contentful_id : contentfulId,
        name,
        user_uuid : user.id,
        image,
        country : location.code
      }

      const response : any = await this.httpClient.post(FAVORITE_TEAMS, auxObject).toPromise();
      if (response) {
        return true;
      } else {
        return false;
      }
    }
  }

  public async deleteFavoriteTeam(contentfulId: string): Promise<boolean> {
    const location: Location = this.storage.getLocation();
    const user = this.storage.getUser();

    if (user) {
      let auxObject = {
        user_uuid : user.id,
        country : location.code
      }

      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body: auxObject
      };

      const response : any = await this.httpClient.delete(`${FAVORITE_TEAMS}/${contentfulId}`, options).toPromise();
      if (response.status) {
        return true;
      } else {
        return false;
      }
    }
  }

  public async getFavoriteTeams(): Promise<any[]> {
    let data : any = [];
    const location: Location = this.storage.getLocation();
    const user = this.storage.getUser();
    if (user) {
      try {
        const items : any = await this.httpClient.get(`${FAVORITE_TEAMS}/${user.id}/${location.code}/100`);
        if (items.data) {
          items?.data.forEach((item) => {
            data.push(item.contentful_id);
          })
        }
      } catch (error) {
        console.log(error);
      }
    }
    return data;
  }
}
