<div *ngIf="showRepetitions" class="eventCardsVertical">
  <h3>{{ title }}</h3>
  <ion-grid>
    <ion-row>
      <div *ngIf="isWeb" class="carrousel__arrow arrowLeft" tabindex="0" (click)="handleScrollSegment(-250)">
        <ion-img src="assets/graphics/options/buttons/ico_arrow.svg"></ion-img>
      </div>
      <ion-col>
        <ion-segment  id="repetitions__segment" class="tv-row tv-control-none" [ngClass]="{'segment-tv-vertical': !isAdaptable}" scrollable (scroll)="doSomethingOnScroll($event)">
          <div *ngIf="banneravailable && isAdaptable" id='div-gpt-ad-1661901060731-0' style='min-width: {{widthbanner}}px; min-height: {{heightbanner}}px; margin-right: {{marginbanner}}em;'>
          </div>
          <div class="eventCardsVertical__Item" [ngClass]="{'tv-control': !isAdaptable}" *ngFor="let item of repetitions" value="item.title" (click)="viewDetail(item)" [id]="'repetitions-' + item.contentful_id" tabindex="4">
            <ion-img [src]="item.image ? item.image : loadingImg" alt=""></ion-img>
            <img class="icoPlayInternal" src="assets/graphics/options/buttons/ico_play_internal.svg" alt="">
            <div class="multi_entitlement" *ngIf="isMultiEntitlement && item?.is_multi_entitlement_content">
              <p>
                {{multiEntitlement?.prefixMediumCard}}{{item?.multi_entitlement?.titleCard}}
              </p>
            </div>
            <div class="eventCardsVertical__Info">
              <ion-label class="infoTitle">{{item.title}}</ion-label>
            </div>
          </div>
        </ion-segment>
      </ion-col>
      <div *ngIf="isWeb" class="carrousel__arrow arrowRight" tabindex="2" (click)="handleScrollSegment(250)">
        <ion-img src="assets/graphics/options/buttons/ico_arrow.svg"></ion-img>
      </div>
    </ion-row>
  </ion-grid>
</div>
