 <div class="text-center">
    <div class="text-position">
      <img src="assets/graphics/options/tabs/Laptop.svg"  alt="ico">
      <img (click)="close()" src="assets/graphics/options/tabs/Close.svg" class="close tv-row-UserCr"  alt="ico">
    </div>
    <br>
    <p class="title">{{text?.title}}</p>
    <p class="subtitle">{{text?.subTitle}} <b class="title">{{text?.messageConfirm}}</b></p>
   <div class="container-formItems">
     <div class="Form__item" *ngFor="let ad of activeDevices" [ngSwitch]="ad.type">
       <div class="container">
         <div class="row">
           <div class="col-2 d-flex justify-content-center">
             <img *ngSwitchCase="'phone'" src="assets/graphics/options/buttons/ico_new_phone.svg" alt="ico">
             <img *ngSwitchCase="'tv'" src="assets/graphics/options/buttons/ico_new_tv.svg" alt="ico">
             <img *ngSwitchCase="'pc'" src="assets/graphics/options/buttons/ico_new_pc.svg" alt="ico">
           </div>
           <div class="col-10">
             <div class="message">{{ad?.browser}} - {{ad?.state}}, {{ad?.city}}</div>
             <br>
             <div class="messagePlay">{{text?.messageContentView}} {{ad?.contentView}}</div>
           </div>
         </div>
       </div>
     </div>
   </div>
    <div class="buttons justify-content-end">
      <button (click)="close()" class="cancel tv-row-UserCr">{{text?.buttonCancel}}</button>
      <button  (click)="play()" class="continue-button tv-row-UserCr">{{text?.buttonConfirm}}</button>
    </div>
  </div>


