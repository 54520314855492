
<div class="text-center">
  <div class="row ">
    <div class="col">
    </div>
  </div>
  <div class="text-position">
    <p class="title">{{data.title}}</p>
  </div>
  <div class="text-position m-3">
    <p class="sub-title m-3">{{data.subtitle}}</p>
  </div>
  <a class="style_btn" (click)="continue()"><label class="justify-content-end m-3">{{data.buttonModal}}</label></a>
</div>
