import {Component, Input, OnInit} from '@angular/core';
import {PoliticsTermsService} from '../../../services/politics-terms.service';
import {ModalController} from '@ionic/angular';
import {LoadingService} from '../../../services/loading.service';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss'],
})
export class TermsConditionsComponent  implements OnInit {

  @Input() data: any;
  title: string = '';
  content: any;

  constructor(private termsService: PoliticsTermsService, private modalCtrl: ModalController, private loadingService: LoadingService) { }

  ngOnInit() {
    this.loadingService.setLoading(true, 'loadTermsConditions');
    this.termsService.getTerms().subscribe(res => {
      if (!res?.error) {
        this.title = res?.data?.fields?.title;
        this.content = res?.data?.fields?.content;
      } else {
        this.content = 'No disponible';
      }
      this.loadingService.setLoading(false, 'loadTermsConditions');
    });
  }

  close() {
    this.modalCtrl.dismiss().then();
  }

}
