import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GET_TV_TOKEN,NEW_LOGIN_TV } from '../config/endpoints';
import { DeviceHelper } from 'src/app/core/helpers/device.helper';
import { VerifyTokenResponse } from '../core/interfaces/verify-token-response';
import { AuthService } from './auth.service';
import { LoadingService } from './loading.service';
import { DevicesService } from './devices.service';

@Injectable({
  providedIn: 'root'
})
export class TvLoginService {
  tokenResponse: VerifyTokenResponse;

  constructor(
    private httpClient: HttpClient,
    private deviceHelper: DeviceHelper,
    private authService: AuthService,
    private loadService: LoadingService,
    private deviceService: DevicesService
  ) {
    //deviceHelper.getUUID().then(res => this.deviceId = res);
  }

  /* Generate new TV Token */
  public async generateTvToken(): Promise<string> {
    let token = '';
    try {
      const generateTokenResponse: any = await this.httpClient
        .post(
          `${GET_TV_TOKEN}`,
          {'deviceId' : await this.deviceHelper.getUUID().then()}
        ).toPromise();
      token = generateTokenResponse.data.token.token;
    } catch (error) {}
    return token;
  }

  public async generateToken(tokenId: string, accessToken: string): Promise<string> {
    let token = '';
    try {
      const generateTokenResponse: any = await this.httpClient
        .post(
          `${GET_TV_TOKEN}`,
          {
            'deviceId' : await this.deviceHelper.getUUID().then(),
            'idToken' : tokenId,
            'accessToken' : accessToken,
          }
        ).toPromise();
      token = generateTokenResponse.data.token.token;
    } catch (error) {}
    return token;
  }

  public async getDeviceID() {
    return await this.deviceHelper.getUUID().then();
  }

  public async getTokenData(token: any, type: string, deviceID:string, isWebView?:boolean, route?:string): Promise<string> {
    try {
      const systemInfo = await this.deviceService.getOsInformation();
      this.loadService.setLoading(true,'tvtoken')
      const generateTokenResponse: any = await this.httpClient
        .post(
          `${NEW_LOGIN_TV}`,
          {
            'deviceId' : deviceID,
            'token' : token,
             'country': systemInfo.country,
             'systemInfo':{
               'operatingSystem':systemInfo.systemInfo.operatingSystem,
                'browser': systemInfo.systemInfo.browser
             }
          }, {
            headers: {
              'is-webview': isWebView ? 'true' : 'false'
            }
          }
        ).toPromise();
      let data = generateTokenResponse.data;
      let auxObject = {
        id_token : data.id_token_tigo_id,
        access_token : data.access_token_tigo_id,
      }
      this.loadService.setLoading(false,'tvtoken')
      await this.authService.setAuthentication(data, type,isWebView,route);
      return token;
    } catch (error) {this.loadService.setLoading(false,'tvtoken')}

  }

  /* Verify TV Token */
  public async verifyTvToken(token: string): Promise<VerifyTokenResponse> {
    let verifyTokenResponse = null;

    try {
      verifyTokenResponse = await this.httpClient
        .post(
          `${GET_TV_TOKEN}`,
          {
            'deviceId' : await this.deviceHelper.getUUID().then(),
            'token' : token
          }
        ).toPromise();
    } catch (error) {}

    return verifyTokenResponse;
  }

  public async verifyTvTokenV4(token: string): Promise<VerifyTokenResponse> {
    let verifyTokenResponse = null;

    try {
      const systemInfo = await this.deviceService.getOsInformation();
      verifyTokenResponse = await this.httpClient
        .post(
          `${NEW_LOGIN_TV}`,
          {
            'deviceId' : await this.deviceHelper.getUUID().then(),
            'token' : token,
            'country': systemInfo.country,
            'systemInfo':{
              'operatingSystem':systemInfo.systemInfo.operatingSystem,
               'browser': systemInfo.systemInfo.browser
            }
          }
        ).toPromise();
    } catch (error) {}

    return verifyTokenResponse;
  }

  public async validateTokeType(token){
    let tokenType = null;
    try {
      const response:any = await this.httpClient
        .post(
          `${GET_TV_TOKEN}`,
          {
            'token' : token
          }
        ).toPromise();
        if(response.code === 200){
           tokenType = response.login;
        }
    } catch (error) {
      return false;
    }
    console.log(tokenType);
    return tokenType;
  }

}
