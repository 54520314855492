<div class="text-center">
  <div (click)="close()" class="text-position">
    <img src="assets/graphics/options/tabs/Close.svg" class="close tv-row-UserCr"  alt="ico">
  </div>
  <br>
  <p class="title">{{data?.title}}</p>
  <p class="subtitle">{{data?.subtitle}}</p>
  <div class="buttons justify-content-end">
    <button (click)="close()" class="cancel tv-row-UserCr">{{data?.buttonModal}}</button>
  </div>
</div>


