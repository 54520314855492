import {Injectable} from '@angular/core';
import {filter, map, mergeMap} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {Title} from '@angular/platform-browser';
import {SegmentUtil} from '../utils/segment.util';
import {GlobalProperties} from '../interfaces/analytics/global-properties';
import {IdentifyProperties} from '../interfaces/analytics/identify-properties';
import {Mixpanel} from '@legalmentedev/capacitor-mixpanel';
import {Capacitor} from '@capacitor/core';
import videojs from 'video.js';
//import any = videojs.any;
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

@Injectable({
  providedIn: 'root'
})
export class SegmentAnalytics {
  previousPage: string = this.router.url;
  globalProperties: GlobalProperties;
  initMixPanel = false;

  constructor(
    private title: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private segmentUtil: SegmentUtil
  ) {
    if (!Capacitor.isNativePlatform() && !this.initMixPanel) {
      /*  Mixpanel.initialize({token: environment.MIXPANEL_TOKEN,serverURL: environment.MIXPANEL_PROXY,
      }).then(test => this.initMixPanel = true);*/
      this.initializedMixpanel();
      this.initializedFirebase();
    } else {
      this.initMixPanel = true;
    }
  }

  /* Initialize segment tracking */
  startTracking(): void {
    this.pageTracking();
  }

  /* Identify page for segment tracking */
  public identifyPages(): void {
    const {TITLE} = environment;
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter(route => route.outlet === 'primary'))
      .pipe(mergeMap(route => route.data))
      .subscribe(async (event) => {
        this.globalProperties = await this.segmentUtil.getGlobalProperties();
        let customTitle = TITLE;
        if (event.title) {
          customTitle = `${TITLE} | ${event.title}`;
          this.globalProperties.page_name = event.title;
          this.globalProperties.screen_name = event.title;
          this.globalProperties.prev_page = this.previousPage;
        }
        this.title.setTitle(customTitle);
        this.previousPage = this.router.url;
      });
  }

  /* Initialize page tracking */
  private pageTracking(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter(route => route.outlet === 'primary'))
      .pipe(mergeMap(route => route.data))
      .subscribe(event => {
        this.identifyCustomer();
        this.pageTrack(this.globalProperties);
      });
  }

  /**
   * https://segment.com/docs/libraries/analytics.js/#page
   *
   * analytics.page([category], [name], [properties], [options], [callback]);
   */
  /* Send page track to segment */
  private pageTrack(properties: any): void {
    try {
      //window.analytics.page(properties);
      if (properties) {
        Mixpanel.track({event: 'Loaded a Page', properties,}).then();
        properties.custom = true;
        this.setTrackFirebase('Loaded a Page', properties);
      }
    } catch (e) {
      if (!(e instanceof ReferenceError)) {

      }
    }
  }

  /**
   * https://segment.com/docs/libraries/analytics.js/#track
   *
   * analytics.track(event, [properties], [options], [callback]);
   */
  /* Send event track to segment */
  public async eventTrack(action: string, properties?: any): Promise<void> {
    try {
      const defaultProperties: GlobalProperties = await this.segmentUtil.getGlobalProperties();
      defaultProperties.page_name = this.globalProperties.page_name;
      defaultProperties.screen_name = this.globalProperties.screen_name;
      defaultProperties.prev_page = this.globalProperties.prev_page;
      properties = { ...defaultProperties, ...properties};

      Mixpanel.track({event: action, properties,}).then();
      properties.custom = true;
      this.setTrackFirebase(action, properties);
      //window.analytics.track(action, properties);

    } catch (e) {
      if (!(e instanceof ReferenceError)) {

      }
    }
  }

  /**
   * https://segment.com/docs/libraries/analytics.js/#identify
   *
   * analytics.identify([userId], [traits], [options], [callback]);
   */
  /* Set customer values for tracking */
  public identifyCustomer(): void {
    const properties: IdentifyProperties = this.segmentUtil.getIdentifyProperties();

    try {
      if (properties?.country) {
        if (properties?.userId) {
          this.setIdUserIdentify(properties?.userId);
          this.setUserPropertiesFirebase(properties);
          /*Mix panel*/
          Mixpanel.identify({distinctId: properties.userId}).then();
          Mixpanel.setProfile({properties}).then();
          //window.analytics.identify(properties.userId, properties);
        } else {
          // Identify only when user is logged
          //window.analytics.identify('', properties);
        }
      }
    } catch (e) {
      if (!(e instanceof ReferenceError)) {

      }
    }
  }


  /**
   * SDK MixPanel
   *
   *
   */
  private initializedMixpanel() {
    Mixpanel.initialize({token: environment.MIXPANEL_TOKEN,serverURL: environment.MIXPANEL_PROXY,
    }).then(() => {}).catch((e) => {});
  }

  /**
   * Firebase Analytics
   *
   *
   */
  private initializedFirebase() {
    try {
      const firebaseConfig = {
        apiKey: environment?.FIREBASE_KY,
        projectId: environment?.FIREBASE_PDI,
        appId: environment?.FIREBASE_APP,
        measurementId: environment?.FIREBASE_MDI,
        authDomain: '',
        databaseURL: '',
        storageBucket: '',
        messagingSenderId: ''
      };
      FirebaseAnalytics.initializeFirebase(firebaseConfig).then(() => {
        this.initMixPanel = true;
      });
    } catch (e) {}
  }


  /**
   * Firebase Tracking Events
   *
   *
   */
  private setTrackFirebase(eventName: string, params: any = {}) {
    try {
      const eventNoSpaces = eventName.replace(/ /g, '_');
      FirebaseAnalytics.logEvent({name: eventNoSpaces, params}).then();
    } catch (e) {}
  }

  /**
   * Firebase set userProperites
   *
   *
   */
  private setUserPropertiesFirebase(objUser: any = {}) {
    try {
      FirebaseAnalytics.setUserProperty(objUser).then();
    } catch (e) {}
  }

  /**
   * Firebase set identify
   *
   *
   */
  private setIdUserIdentify(id: string) {
    try {
      FirebaseAnalytics.setUserId({userId: id}).then();
    } catch (e) {}
  }



}
