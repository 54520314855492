import { Component, OnInit } from '@angular/core';
import { BrazeService } from '../../../services/braze.service';
import * as appboy from '@braze/web-sdk';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-request-permission',
  templateUrl: './request-permission.component.html',
  styleUrls: ['./request-permission.component.scss'],
})
export class RequestPermissionComponent implements OnInit {

  constructor(
    private brazeService: BrazeService,
    public dialogRef: MatDialogRef<RequestPermissionComponent>) { }

  ngOnInit() {}

  async accept(): Promise<void> {
    //appboy.display.automaticallyShowNewInAppMessages();
    appboy.automaticallyShowInAppMessages();
    //appboy.registerAppboyPushMessages();
    await this.brazeService.register();
    this.dialogRef.close();
  }
}
