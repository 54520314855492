import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-message-Auth0Modal.component',
  templateUrl: './message-Auth0Modal.component.html',
  styleUrls: ['./message-Auth0Modal.component.scss'],
})
export class MessageAuth0ModalComponent implements OnInit {
  @Input() data: any;
  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {
  }


  continue() {
    this.modalCtrl.dismiss().then();
   // this.modalCtrl.dismiss({isOk: true}).then();
  }
}
