<ion-content>
  <div class="container pt-4 main-form-container">
    <div class="row ion-align-items-center ion-justify-content-center pt-lg-0 pb-0 h-100">
      <div class="col-10 col-lg-4 p-0">
        <ion-card id="custom-card">
          <ion-card-header class="ion-text-center">
            <img [src]="image">
          </ion-card-header>
          <ion-card-content class="ion-text-center">
            <div class="mb-3">
              {{ message }}
            </div>
            <ng-container *ngIf="state == true">
              <ng-container *ngIf="confirm?.enabled == true">
                <div>
                  <ion-button id="confirm-button-app-update" shape="round"
                              (click)="openBrowser(confirm?.url)">{{ confirm?.text }}</ion-button>
                </div>
              </ng-container>
              <ng-container *ngIf="alternateButton?.enabled == true">
                <div>
                  <ion-button id="confirm-button-app-update" shape="round"
                              (click)="openBrowser(alternateButton?.url)">{{ alternateButton?.text }}</ion-button>
                </div>
              </ng-container>
              <ng-container *ngIf="cancel?.enabled == true">
                <div>
                  <ion-button id="cancel-button-app-update" shape="round"
                              (click)="closeModal()">{{ cancel?.text }}</ion-button>
                </div>
              </ng-container>
            </ng-container>
          </ion-card-content>
        </ion-card>
      </div>
    </div>
  </div>
</ion-content>
