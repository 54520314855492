import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PAYMENT_CONFIGURATION } from '../config/endpoints';
@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private httpClient: HttpClient) { }

  async getDefaultConfiguration(): Promise<any> {
    let configuration: any;
    try {
      const response: any = await this.httpClient
        .get(PAYMENT_CONFIGURATION)
        .toPromise();
      configuration = response ? response.data : null;
    } catch (e) {
      console.error(e);
    }

    return configuration;
  }
}
