<div class="text-center">
  <div class="text-position">
    <img (click)="close()"  src="assets/graphics/options/tabs/Close.svg" class="close"  alt="ico">
  </div>
  <br>
  <p class="title">{{text?.title}}</p>
  <div class="container-formItems">
    <div [ngClass]="{'disableOptionDevice': ad?.contentView?.includes('(Chromecast)')}"  class="Form__item" *ngFor="let ad of activeDevices; let i = index" [ngSwitch]="ad.type" id="device{{i}}">
      <div class="container">
        <div class="row">
          <div class="containerImages col-1 d-flex justify-content-center p-2">
              <mat-radio-button (click)="selectDevice(i, ad.deviceId)"   color="accent"></mat-radio-button>
          </div>
          <div class="col-2 d-flex justify-content-center">
            <img *ngSwitchCase="'phone'" src="assets/graphics/options/buttons/ico_new_phone.svg" alt="ico">
            <img *ngSwitchCase="'tv'" src="assets/graphics/options/buttons/ico_new_tv.svg" alt="ico">
            <img *ngSwitchCase="'pc'" src="assets/graphics/options/buttons/ico_new_pc.svg" alt="ico">
          </div>
          <div [ngClass]="{'optionSeeDeviceChromecast': ad?.contentView?.includes('(Chromecast)')}" class="containerTexts col-9">
            <div class="message">{{ad?.browser}} - {{ad?.state}}, {{ad?.city}}</div>
            <br>
            <div class="messagePlay">{{text?.messageContentView}} {{ad?.contentView}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="buttons justify-content-end">
    <button (click)="close()"  class="cancel">{{text?.buttonCancel}}</button>
    <button (click)="play()" class="continue-button">{{text?.buttonConfirm}}</button>
  </div>
</div>
