import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {PLATFORM_LOCATION} from './app/config/endpoints';
import {DevinsService} from "./app/services/devins.service";

if (environment.production) {
  enableProdMode();
}
let configService;
platformBrowserDynamic().bootstrapModule(AppModule)
  .then(ref => {
    configService = ref.injector.get(DevinsService);
  })
  .catch(err => console.log(err));

window['checkingSite'] = async function() {
  await configService.inSpec();
};
