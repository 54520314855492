<form [formGroup]="form">
  <div formGroupName="billingForm">
      <div class="row justify-content-center">
          <h2 class="custom-title">
              {{control.title}}
            </h2>

            <mat-form-field appearance="outline" class="form-field">
                <mat-label style="font-size: 13px" [attr.for]="control.key">{{control.label}}</mat-label>
                <div [ngSwitch]="control.controlType">
                    <input matInput *ngSwitchCase="'textbox'" [placeholder]="control.placeholder" [id]="control.key"
                        [type]="control.type" [name]="control.key" [formControlName]="control.key">
                    <ng-container *ngSwitchCase="'dropdown'" >
                    <mat-select [placeholder]="control.placeholder"
                        [formControlName]="control.key" (selectionChange)="selectChange($event.value)">
                        <mat-option *ngFor="let opt of control.options" [value]="opt.key">
                            {{ opt.value }}
                        </mat-option>
                    </mat-select>
                    </ng-container>
                </div>
            </mat-form-field>
          <!-- <div  *ngFor="let control of controls"> -->
            <ng-container *ngIf="isSelect">
            <app-billing-form-control [control]="controlsBill" [form]="form"></app-billing-form-control>
           </ng-container>
          <!-- </div> -->
        </div>
    </div>
  </form>
