<div class="text-center">
  <div class="text-position">
    <img src="assets/graphics/options/tabs/Laptop.svg"  alt="ico">
    <img (click)="close()" src="assets/graphics/options/tabs/Close.svg" class="close tv-row-UserCr"  alt="ico">
  </div>
  <br>
  <p class="title">{{text?.tittle}}</p>
  <p class="subtitle">{{text?.subtitle}}<b class="title">{{text?.numberDevice}}</b>{{text?.complementSubtitle}}</p>
  <p class="subtitle">{{text?.paragraph}}<b class="title">{{text?.messageConfirm}}</b></p>
  <div class="buttons justify-content-end">
    <button (click)="close()" class="cancel tv-row-UserCr">{{text?.buttonCancel}}</button>
    <button (click)="PlayNow()"  class="continue-button tv-row-UserCr">{{text?.buttonConfirm}}</button>
  </div>
</div>


