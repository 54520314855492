import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { IS_ADAPTABLE } from 'src/app/config/constants';
import { TextManagementV2Service } from 'src/app/services/text-management-v2.service';
import { TextManagementService } from 'src/app/services/text-management.service';

@Component({
  selector: 'app-message-multi-entitlement',
  templateUrl: './message-multi-entitlement.component.html',
  styleUrls: ['./message-multi-entitlement.component.scss'],
})
export class MessageMultiEntitlementComponent  implements OnInit {
  @Input() data: any;
  item: any;
  activeDevices: any = [];
  text;
  turnofsucription: any = {};
  deviceColor = '';
  deviceId = '';
  messageChromecast;
  isAdaptable = IS_ADAPTABLE;

  constructor(
    private modalCtrl: ModalController,
    private textManagementService: TextManagementService,
    private textManagementV2Service: TextManagementV2Service,
    private navController: NavController
  ) { }

  ngOnInit() {
    this.getText().then();
  }

  async getText() {
    const text: any = await this.textManagementV2Service.getTexts();
    this.text = text?.MULTI_ENTITLEMENT;
    this.turnofsucription = await this.textManagementService.getTurnoPayment();
  }

  goToSubscription() {
    this.close();
    if (this.turnofsucription.pvv === 'true') {
      this.navController.navigateForward('/-/PasarelaAcceso');
    } else if(this.turnofsucription.suscription === 'true') {
      this.navController.navigateForward('/-/no-suscripcion');
    }
  }

  async close() {
    await this.modalCtrl.dismiss({cancel: true});
  }
}
