import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EVENT_TRACK_163 } from 'src/app/config/analytics/events-track';
import { SegmentAnalytics } from 'src/app/core/providers/segment-analytics';

@Component({
  selector: 'app-message-webgl',
  templateUrl: './message-webgl.component.html',
  styleUrls: ['./message-webgl.component.scss'],
})
export class MessageWebglComponent  implements OnInit {
  @Input() data:any;
  constructor(private modalCtrl:ModalController,
              private segmentAnalytics: SegmentAnalytics) { }

  ngOnInit() {
    this.segmentAnalytics.eventTrack(EVENT_TRACK_163, 
      {
        message: this.data?.objContent?.message, event_type: 'error', journey: 'user interface'
      }).then();
  }

  async close(){
    await this.modalCtrl.dismiss();
  }
}
