/**
 * Constants for settings used for Segment.
 *
 * @remarks - These constants are only used to identify the properties to be used for tracking.
 **/

export const AUTHENTICATION_METHOD = 'tigoId';
export const MOBILE_BUSINESS_CATEGORY = 'Mobile';
export const MOBILE_AUTH_TYPE = 'phone';
export const MOBILE_TYPE_LOGIN = 'login_phone';
export const MOBILE_ENTITLEMENT_CATEGORY = 'movil';
export const HOME_BUSINESS_CATEGORY = 'Home';
export const HOME_AUTH_TYPE = 'email';
export const HOME_TYPE_LOGIN = 'login_home';
export const HOME_ENTITLEMENT_CATEGORY = 'home';
export const OTHER_BUSINESS_CATEGORY = 'Sports';
export const OTHER_AUTH_TYPE = 'email';
export const OTHER_TYPE_LOGIN = 'login_other';
export const OTHER_ENTITLEMENT_CATEGORY = 'other';
