
<div class="text-center">
  <div class="row ">
    <div class="col">
      <img [src]="data.img" class="close tv-row-UserCr" (click)="close()"  alt="ico">
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="terms">
        <h1>{{title}}</h1>
        <app-read-document-json [content]="content"></app-read-document-json>
      </div>
    </div>
  </div>

</div>
