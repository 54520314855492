
<div class="text-center">
  <div class="row ">
    <div class="col">
      <img [src]="data.img_src"  width="50px" height="50px" alt="ico">
    </div>
  </div>

  <div class="text-position">
    <label class="title">{{data.title}}</label> <br>
    <label class="sub-title m-3">{{data.subtitle}}</label>
  </div>

  <a *ngIf="data.valueerrol !== undefined && data.route !== '' && data.route !== 'OTT'" [routerLink]="[data.route]" [queryParams]="{val: data.valueerrol}" (click)="close()" class="style_btn"><label class="m-3">{{data.buttonMessage}}</label></a>
  <a *ngIf="data.valueerrol === undefined && data.route === ''" (click)="close()" class="style_btn"><label class="m-3">{{data.buttonMessage}}</label></a>
  <a *ngIf="data.valueerrol === undefined && data.route !== '' && data.route !== 'OTT'" [routerLink]="[data.route]" (click)="close()" class="style_btn"><label class="m-3">{{data.buttonMessage}}</label></a>
  <a *ngIf="data.valueerrol === undefined && data.route === 'OTT'" (click)="goBack()" class="style_btn"><label class="m-3">{{data.buttonMessage}}</label></a>

</div>
