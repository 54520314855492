import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { ConfigurationsService } from './configurations.service';
import { IS_ADAPTABLE } from '../config/constants';

@Injectable({
  providedIn: 'root'
})
export class AdsService {

  constructor(private configurationsService: ConfigurationsService) { }

  async getSettings(): Promise<any> {
    const settings = {
      status: false,
      locale: '',
      url: ''
    };
    const data = await this.configurationsService.getConfigurationByVariable('ott_google_ad_manager');

    if (data) {
      settings.locale = data?.locale;
      settings.url = data?.url;

      if (Capacitor.getPlatform() === 'ios') {
        settings.status = data?.ios;
      } else if (Capacitor.getPlatform() === 'android') {
        if (IS_ADAPTABLE) {
          settings.status = data?.android;
        } else {
          settings.status = data?.tv;
        }
      } else if (Capacitor.getPlatform() === 'web') {
        settings.status = data?.web;
      }
    }

    if (settings.status === undefined) {
      settings.status = false;
    }

    return settings;
  }
}
