import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {GET_POLITICS, GET_TERMS} from '../config/endpoints';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PoliticsTermsService {

  constructor(
    private httpClient: HttpClient
  ) { }

  /* Get App Terms from middleware */
  getTerms(): Observable<ResponseData> {
    const response = { error: true, msg: 'Error', data: null };
    return this.httpClient.get<ResponseData>(GET_TERMS)
      .pipe(
        map((data) => {
          response.msg = null;
          response.error = null;
          response.data = data ?? {} as TermsData;
          return response;
        }),
        catchError( e => {
          return of({ error: true, msg: e.error.msg, data: null });
        })
      );
  }

  /* Get App Politics from middleware */
  getPolitics(): Observable<ResponseData> {
    const response = { error: true, msg: 'Error', data: null };
    return this.httpClient.get<ResponseData>(GET_POLITICS)
      .pipe(
        map((data) => {
          response.msg = null;
          response.error = null;
          response.data = data ?? {} as TermsData;
          return response;
        }),
        catchError( e => {
          return of({ error: true, msg: e.error.msg, data: null });
        })
      );
  }
}

interface ResponseData {
  error: boolean;
  msg: string;
  data: TermsData;
}

export interface ContentData {
  data: any[];
  content: [
    {
      data: any[];
      marks: any[];
      value: string;
      nodeType: string;
    }
  ];
  nodeType: string;
}


interface TermsData {
  sys: {
    id: string;
    type: string;
    space: any;
    revision: number;
    createdAt: string;
    updatedAt: string;
    contentType: any;
    environment: any;
  };
  fields: {
    body: {
      data: any[];
      content: ContentData[];
      nodeType: string;
    };
    title: string;
    idPage: string;
    content: string;
  };
}
