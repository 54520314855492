import { Injectable } from '@angular/core';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { AppUpdate } from '../core/interfaces/app-update';
import { Storage } from '../core/storage/storage';
import { Capacitor } from '@capacitor/core';
import { ConfigurationsService } from './configurations.service';
import { AppPlatform } from '../core/interfaces/app-platform';
import { ModalController, NavController } from '@ionic/angular';
import * as semver from 'semver';
import { IS_ADAPTABLE } from '../config/constants';
import {LocationService} from './location.service';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  private variableName = 'app_landing_ott';

  constructor(
    private appVersion: AppVersion,
    private storage: Storage,
    private configurationsService: ConfigurationsService,
    private modalController: ModalController,
    private navController: NavController,
    private locationService: LocationService) {
  }

  /* Check for app updates */
  public async verifyAppUpdate() {
    return new Promise(async (resolve) => {
      const isValidPlatform = this.validPlatform()
      let status = false;
      if (isValidPlatform) {
        const appPlatform: AppPlatform = await this.getLandingData();
        if(appPlatform){
        const isUpdateVersion = await this.isUpdateVersion(appPlatform.version);
        if (isUpdateVersion) {
          this.storage.removeAppVersionCounter();
        } else {
          const counter = this.storage.getAppVersionCounter();
          if (counter < appPlatform.views && appPlatform.state === true) {
            status = true;
            this.storage.incrementAppVersionCounter();
            // this.navController.navigateRoot('/-/actualizar');
          }
        }
      }
      }
      return resolve(status)
    })
  }

  /* Check if current platform is valid */
  private validPlatform(): boolean {
    let status = false;

    if (Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios') {
      status = true;
    }

    return status;
  }

  /* Check if current app version is outdated */
  private async isUpdateVersion(publishedVersion: string): Promise<boolean> {
    let status = false;
    const currentVersion = await this.appVersion.getVersionNumber();
    if (semver.valid(currentVersion) && semver.valid(publishedVersion)) {
      status = semver.gte(currentVersion, publishedVersion);
    }

    return status;
  }

  public async getLandingData(): Promise<AppPlatform> {
    try{
    let appPlatform: AppPlatform;
    await this.locationService.locate();
    const appUpdate: AppUpdate = await this.configurationsService.getConfigurationByVariable(this.variableName);
    if (Capacitor.getPlatform() === 'android' && !IS_ADAPTABLE) {
      appPlatform = appUpdate.android_tv;
    } else {
      if (Capacitor.getPlatform() === 'android') {
        appPlatform = appUpdate.android;
      } else if (Capacitor.getPlatform() === 'ios') {
        appPlatform = appUpdate.ios;
      }
    }
    return appPlatform
  }catch(e){

    console.log(e);
    return null;
  }


  }
}
