import { Injectable } from '@angular/core';
import { AuthOption } from '../core/interfaces/auth-option';
import { TigoIdService } from './tigo-id.service';
import { InAppBrowser, InAppBrowserObject } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Capacitor } from '@capacitor/core';
import { BROWSER } from '../config/constants';
import { NavController } from '@ionic/angular';
import {Subject, Observable, Subscription} from 'rxjs';
import { PlayerService } from './player.service';
import { PurchaseService } from './purchase.service';
import { SegmentAnalytics } from '../core/providers/segment-analytics';
import {EVENT_TRACK_002, EVENT_TRACK_024} from '../config/analytics/events-track';
import { Storage } from '../core/storage/storage';
import { Router } from '@angular/router';
import { EntitlementService } from './entitlement.service';
import { CarrierBillingService } from './carrier-billing.service';
import { AuthService } from './auth.service';
import {TextManagementService} from './text-management.service';
import  { AuthService  as AuthServiceAuth0  } from '@auth0/auth0-angular';
import { Browser } from '@capacitor/browser';
import {Auth0Helper} from '../core/helpers/auth0.helper';

@Injectable({
  providedIn: 'root'
})
export class BrowserService {
  isValidateEntitlement = false;
  turnofsucription: any = {};
  islogin: boolean = false;
  constructor(
    private tigoID: TigoIdService,
    private iab: InAppBrowser,
    private navController: NavController,
    private playerService: PlayerService,
    private purchaseService: PurchaseService,
    private segmentAnalytics: SegmentAnalytics,
    private storage: Storage,
    private router: Router,
    private entitlementService: EntitlementService,
    private carrierBillingService: CarrierBillingService,
    private authService: AuthService,
    private textManagementService: TextManagementService,
    public auth: AuthServiceAuth0,
    private auth0Helper: Auth0Helper,
  ) {
  }

  /* Open TigoId in browser for authentication */
  public async openTigoID(option: AuthOption, loginType = 'cognito') {
    const isAuth0 = Boolean(option?.webviewUrlAuth0);
    const isHEPhone = Boolean(option?.webviewUrlHE);
    //const url = isAuth0 ? option.webviewUrlAuth0 : await this.tigoID.getUrlTigoID(option,loginType);
    const url = isAuth0 ? option.webviewUrlAuth0 : isHEPhone ? option.webviewUrlHE : await this.tigoID.getUrlTigoID(option,loginType);
    this.storage.setOptionLogin(option);
    const storageC = this.storage;
    const auht0HelperC = this.auth0Helper;
    // isAuth0 && Capacitor.getPlatform() !== 'webx'
    if (Capacitor.getPlatform() !== 'webx' && option?.toOpenBrowserView) {
      const urlSource = url;
      if (this.storage.getIsModalAuth0()) {
        this.storage.removeModalAuth0();
      }
      this.auth
        .loginWithRedirect({
          openUrl: async (url) => {
            //url = option.webviewUrlAuth0;
            url = urlSource;
            url = url.replace('domainAuth0123', option.domain);
            url = url.replace('clientIdAuth0123', option.clientId);
            url = url.replace(
              'redirectUriAuth0123',
              Capacitor.getPlatform() !== 'web' ? option.redirectUrimovil : option.redirectUriweb
            );
            const params = new URL(String(url));

            const verifier = auht0HelperC.createRandomStringAuth0();
            const challengeBuffer = await auht0HelperC.sha256Auth0(verifier);
            const codeChallenge = auht0HelperC.bufferToBase64UrlEncodedAuth0(challengeBuffer);
            params.searchParams.set('code_challenge', codeChallenge);
            params.searchParams.delete('nonce');

            params.searchParams.delete('state');

            url = params.toString();
            option.codeVerifier = verifier;
            storageC.setOptionLogin(option);
            await Browser.open({url, windowName: '_self'});
          }
        }).subscribe();
    } else if (url) {
      let urlSource: string;
      if (isHEPhone) {
        const { urlHE, verifier} = await this.tigoID.generateUrlAuthorizeHE(url);
        urlSource = urlHE;
        option.codeVerifier = verifier;
        this.storage.setOptionLogin(option);
      } else {
        urlSource = url;
      }
      this.storage.setLoginType(loginType);
      const browser: InAppBrowserObject = this.iab.create(
        urlSource,
        this.getTarget(),
        this.getBrowserOptions()
      );

      if (Capacitor.getPlatform() !== 'web') {
        browser.on('loadstop').subscribe(async (event) => {
          const extractedUrl = new URL(event.url);
          const { searchParams } = extractedUrl;
          const code = searchParams.get('code');
          if (code) {
            if (!this.islogin) {
              this.islogin = true;
              this.turnofsucription = await this.textManagementService.getTurnoPayment();
              if (this.storage.getUserCarrierBilling()) {
                this.storage.removeDevicesLimit();
                await this.authService.removeAuthentication();
              }
              let tvAuthValidation = this.storage.getTVAuthState();
              this.authService.setAuthType(option.type);
              this.tigoID.generateTokenTigoID(code, option.type).then(async (res: any) => {
                if (res) {
                  //await this.validEntitlement();
                  await this.segmentAnalytics.eventTrack(EVENT_TRACK_002, {journey: 'user interface'});
                  const previousRoute = this.storage.getPreviousRoute();
                  this.isValidateEntitlement = this.storage.getUser()
                    ? this.storage.getUser().entitlement
                    : this.storage.getUserCarrierBilling().entitlement;
                  if (tvAuthValidation && !this.storage.getTVAuthState()) {
                    tvAuthValidation = false;
                    this.navController.navigateForward('/-/success-activate');
                    this.segmentAnalytics.eventTrack(EVENT_TRACK_024, {journey: 'registration'});
                  } else if (!this.isValidateEntitlement) {
                    if (this.turnofsucription.pvv === 'true') {
                      this.navController.navigateForward('/-/PasarelaAcceso');
                      this.segmentAnalytics.eventTrack(EVENT_TRACK_024, {journey: 'registration'});
                    } else if (this.turnofsucription.suscription === 'true') {
                      this.navController.navigateForward('/-/no-suscripcion');
                      this.segmentAnalytics.eventTrack(EVENT_TRACK_024, {journey: 'registration'});
                    } else {
                      this.navController.navigateRoot('/inicio');
                      this.segmentAnalytics.eventTrack(EVENT_TRACK_024, {journey: 'registration'});
                    }
                  } else if (previousRoute) {
                    this.storage.removePreviousRoute();
                    this.navController.navigateForward(previousRoute);
                  } else {
                    this.navController.navigateRoot('/inicio');
                    this.segmentAnalytics.eventTrack(EVENT_TRACK_024, {journey: 'registration'});
                  }
                }
                this.islogin = false;
                browser.close();
              });
            }
          }

          if (extractedUrl.href.includes('togoLoginHE')) {
            browser.close();
            this.router.navigate(['/-/iniciar-sesion']).then();
          }
        });
      }
    }
  }

  private async validEntitlement(){
    this.isValidateEntitlement = await this.entitlementService.getEntitlement();
  }

  /* Open External Links in browser */
  public async openToolbox(url: string): Promise<void> {
    if (url) {
      const browser: InAppBrowserObject = this.iab.create(
        url,
        this.getTarget(),
        this.getBrowserOptions());

        if (Capacitor.getPlatform() !== 'web') {
          this.turnofsucription = await this.textManagementService.getTurnoPayment();
          browser.on('loadstop').subscribe(async (event) => {
            const extractedUrl = new URL(event.url);
            const { searchParams } = extractedUrl;
            const toolbox_user_token = searchParams.get('code');
            if (toolbox_user_token && !this.storage.getAccessToken()) {
              let tvAuthValidation = this.storage.getTVAuthState();
              this.isValidateEntitlement = await this.carrierBillingService.validateSession(toolbox_user_token);
              const previousRoute = this.storage.getPreviousRoute();
              if(tvAuthValidation && !this.storage.getTVAuthState()){
                tvAuthValidation=false;
                this.navController.navigateForward('/-/success-activate');
                this.segmentAnalytics.eventTrack(EVENT_TRACK_024, {journey: 'registration'});
              }
              else if(this.isValidateEntitlement === false){
                if (this.turnofsucription.pvv === 'true') {
                  this.navController.navigateForward('/-/PasarelaAcceso');
                  this.segmentAnalytics.eventTrack(EVENT_TRACK_024, {journey: 'registration'});
                } else if (this.turnofsucription.suscription === 'true') {
                  this.navController.navigateForward('/-/no-suscripcion');
                  this.segmentAnalytics.eventTrack(EVENT_TRACK_024, {journey: 'registration'});
                } else {
                  this.navController.navigateRoot('/inicio');
                  this.segmentAnalytics.eventTrack(EVENT_TRACK_024, {journey: 'registration'});
                }
              }
              else if (previousRoute) {
                this.storage.removePreviousRoute();
                await this.router.navigateByUrl(previousRoute);
              } else {
                await this.navController.navigateRoot('/inicio');
                this.segmentAnalytics.eventTrack(EVENT_TRACK_024, {journey: 'registration'});
              }

              browser.close();
            }
          });
        }
    }

  }

  public async openExternalLink(url: string): Promise<void> {
    const settings: any = await this.tigoID.getTigoIDSettings();
    if (url) {
      const browser: InAppBrowserObject = this.iab.create(
        url,
        this.getTarget(),
        this.getBrowserOptions());
      if (Capacitor.getPlatform() !== 'web') {
        browser.on('loadstop').subscribe(async (event) => {
          const currentUrl = new URL(event.url);

          if(currentUrl.href === settings.redirect || currentUrl.href.includes('togoHome')) {
            if(currentUrl.href.includes('togoHome')){
            this.authService.refreshAuthentication();
            }
            browser.close();
           // this.navController.navigateRoot('/inicio');
            this.router.navigate(['/inicio'],{ replaceUrl: true });
          }
        });
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public async openExternalLinkPaymentAccess(url: string): Promise<void> {
    const settings: any = await this.tigoID.getTigoIDSettings();
    if (url) {
      const browser: InAppBrowserObject = this.iab.create(
        url,
        this.getTarget(),
        this.getBrowserOptions());
      if (Capacitor.getPlatform() !== 'web') {
        browser.on('loadstop').subscribe(async (event) => {
          const currentUrl = new URL(event.url);
          if (currentUrl.href.includes('togoHome')) {
            this.authService.refreshAuthentication();
            browser.close();
            this.navController.navigateRoot('/-/inicio');
          } else {
            if(currentUrl.href === settings.redirect) {
              browser.close();
              this.navController.navigateRoot('/-/PasarelaAcceso');
            }
          }
          /*if(currentUrl.href === settings.redirect) {
            browser.close();
            this.navController.navigateRoot('/-/PasarelaAcceso');
          }*/
        });
      }
    }
  }




  public getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }
  /* Open External Links in browser */
  public async openWebViewPayment(url: string): Promise<void> {
    const settings: any = await this.tigoID.getTigoIDSettings();
    if (url) {
      const browser: InAppBrowserObject = this.iab.create(
        url,
        this.getTarget(),
        this.getBrowserOptions());

        if (Capacitor.getPlatform() !== 'web') {
          browser.on('loadstop').subscribe(async (event) => {
            const currentUrl = new URL(event.url);

            if(currentUrl.href === settings.redirect) {
              browser.close();
              this.navController.navigateRoot('/inicio');
            }
          });

        }
    }
  }

  public async openPPV(transmission: any): Promise<Observable<any>> {
    const subject = new Subject<any>();
    const url = await this.purchaseService.getUrl();
    const settings: any = await this.tigoID.getTigoIDSettings();

    if (url) {
      const browser: InAppBrowserObject = this.iab.create(
        url,
        this.getTarget(),
        this.getBrowserOptions());

      if (Capacitor.getPlatform() !== 'web') {
        browser.on('loadstop').subscribe(async (event) => {
          const currentUrl = new URL(event.url);

          if (currentUrl.href === settings.redirect) {
            subject.next(transmission);
            subject.complete();
            browser.close();
          }
        });

        browser.on('exit').subscribe(async () => {
          const previousPage = this.playerService.getPreviousUrl();
          if (!previousPage) {
            await this.segmentAnalytics.eventTrack(EVENT_TRACK_002, {jouerney: 'user interface'});
          }
        });
      }
    }

    return subject.asObservable();
  }

  /* Get browser options based on user platform */
  private getBrowserOptions(): any {
    let options;

    if (Capacitor.getPlatform() === 'android') {
      options = BROWSER.ANDROID_OPTIONS;
    } else if (Capacitor.getPlatform() === 'ios') {
      options = BROWSER.iOS_OPTIONS;
    }

    return options;
  }

  /* Define user platform */
  private getTarget(): any {
    let target;

    if (Capacitor.getPlatform() === 'android') {
      target = BROWSER.ANDROID_TARGET.target;
    } else if (Capacitor.getPlatform() === 'ios') {
      target = BROWSER.iOS_TARGET.target;
    } else if (Capacitor.getPlatform() === 'web') {
      target = BROWSER.WEB_TARGET.target;
    }

    return target;
  }
}
