<div class="text-center">
  <div class="text-position">
    <img src="assets/graphics/tv/ico_tv_closeSession.svg"  alt="ico">
    <img (click)="close()" src="assets/graphics/options/tabs/Close.svg" class="close tv-row-UserCr"  alt="ico">
  </div>
  <br>
  <p class="title">{{text?.tittle}}</p>
  <p class="subtitle">{{text?.subtitle}}</p>
  <p class="subtitle">{{text?.paragraph}} <b class="buttonConfirm">{{text?.buttonConfirm}}</b></p>
  <div class="buttons justify-content-end">
    <button (click)="close()" class="cancel tv-row-UserCr">{{text?.buttonCancel}}</button>
    <button (click)="closeSession()"  class="continue-button tv-row-UserCr">{{text?.buttonConfirm}}</button>
  </div>
</div>


