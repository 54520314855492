<div class="text-center">
  <div class="text-position">
    <img [src]="data.img_src" class="icon" alt="ico">
    <img src="assets/graphics/options/tabs/Close.svg" class="close" (click)="close()" alt="ico">
  </div>
  <br>
  <div class="text-position">
    <p class="title">{{data.title}}</p>
    <p class="subtitle">{{data.subtitle}}</p>
  </div>
  <div class="row">
    <div class="col">
      <button class="continue-button" (click)="goNow()">{{data.buttonConfirm}}</button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <a (click)="close()" class="style_btn"><label class="m-3">{{data.buttonCancel}}</label></a>
    </div>
  </div>
</div>