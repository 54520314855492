import {Component, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {NavController, Platform} from '@ionic/angular';
import {Storage} from 'src/app/core/storage/storage';
import {FavoritesService} from 'src/app/services/favorites.service';
import {SegmentAnalytics} from 'src/app/core/providers/segment-analytics';
import {EVENT_TRACK_072} from 'src/app/config/analytics/events-track';
import {IS_ADAPTABLE} from 'src/app/config/constants';
import { VideoStreamingService } from 'src/app/services/video-streaming.service';
import { EventDetailService } from 'src/app/services/event-detail.service';
import { ENTITLEMENT } from 'src/app/config/settings';
import { DevicesService } from 'src/app/services/devices.service';
import { AuthService } from 'src/app/services/auth.service';
import {Capacitor} from '@capacitor/core';
import { TextManagementV2Service } from 'src/app/services/text-management-v2.service';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss'],
})
export class FavoritesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data: any;
  favorites = [];
  showFavorites = false;
  isAdaptable = IS_ADAPTABLE;
  isWeb = Capacitor.getPlatform() === 'web';
  @Input() banneradvertising: any;
  banneravailable: boolean = true;
  widthbanner=0;
  heightbanner=0;
  marginbanner=0;
  slot: any;
  iddiv = 'div-gpt-ad-1661900751418-0';
  isemptybanner: boolean = true;
  textsV2: any;
  assetDomain: any;
  constructor(
    private favoritesService: FavoritesService,
    private storage: Storage,
    private platform: Platform,
    private navController: NavController,
    private segmentAnalytics: SegmentAnalytics,
    private videoStreamingService: VideoStreamingService,
    private eventDetailService: EventDetailService,
    private devicesService: DevicesService,
    private textManagementV2Service: TextManagementV2Service
  ) {
  }

  async ngOnInit() {
    this.textsV2 = await this.textManagementV2Service.getTexts();
    this.assetDomain = this.textsV2?.ASSET_DOMAIN;
    
    if (this.data.length > 0) {
      try {
        const data = this.data;
        this.favorites = (data.length > 0) ? data : [];
        this.showFavorites = (data.length);
      } catch (error) {
      }
    }
  }

  @HostListener('window:scroll', ['$event'])
  async doSomethingOnScroll(event) {
    const totalwidth = Math.round(event.target.offsetWidth + event.target.scrollLeft);
    if (totalwidth === event.target.scrollWidth) {
      if (this.favorites.length > 0) {
        const lastObj = this.favorites[this.favorites.length-1];
        
        this.textsV2 = await this.textManagementV2Service.getTexts();
        this.assetDomain = this.textsV2?.ASSET_DOMAIN;
    
        const newArr = await this.favoritesService.getEvents(lastObj.referenceIdPaginator, this.assetDomain);
        this.favorites.push(...newArr);
      }
    }
  }

  ngOnChanges(change: SimpleChanges): void {
    this.data = [];
    const results = change.data;
    const banner = change.banneradvertising;

    if (results) {
      this.favorites = results.currentValue;
      this.showFavorites = (results.currentValue.length > 0);
    }
    if (this.favorites.length > 0 && this.showFavorites) {
      if (banner && this.isAdaptable) {
        this.loadScript(banner.currentValue);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.isAdaptable) {
      this.Removeslot();
    }
  }

  /* Redirect the user to detail page */
  async viewDetail(event): Promise<void> {
    const authenticated = this.storage.getAccessToken();
    let route: string;
    const segmentData = {
      championship_id: event.championship_id,
      championship_name: event.championship_id,
      airdate: event.createdAt,
      sport_name: event.sport_name,
      video_title: event.video_title,
      journey: 'player',
      content_id: event.contentful_id,
      home_team_id: event.home_team_id,
      home_team: event.home_team,
      away_team_id: event.away_team_id,
      away_team: event.away_team,
      total_length: event.total_length,
      video_position: 0,
      session_id: event.session_id,
      video_quality: event.video_quality,
      content_category: event.content_category,
      content_type: event.content_type,
    };
    await this.segmentAnalytics.eventTrack(EVENT_TRACK_072, segmentData);
    this.videoStreamingService.setEventCategory(event.content_category);
    let eventType = '';
    if (event.content_type.toLowerCase() === 'game') {
      eventType = 'game';
    } else {
      eventType = 'highlights';
    }
    //let streamingType = await this.eventDetailService.getStreamType(event.contentful_id, eventType);
    route = `/detail/${event.contentful_id}/${eventType}`;
    this.storage.setPreviousRoute(route);
  /*   if (!authenticated && streamingType != ENTITLEMENT.FREE_TYPE) {
      if (this.isAdaptable) {
        route = '/-/iniciar-sesion';
      } else {
        route = '/-/iniciar-sesion-tv';
      }
    } */
    this.navController.navigateForward(route);
  }

  async handleScrollSegment(qty: number): Promise<void> {
    let segmentAux = document.getElementById('favorites__segment');
    segmentAux.scrollLeft = (segmentAux.scrollLeft + qty);
  }

  async loadScript(banners) {
    const platform = Capacitor.getPlatform();
    if (banners[platform]) {
      let bannervalidate: any = [];
      if (this.platform.is('mobileweb') || this.platform.is('mobile')) {
        bannervalidate = banners.android;
      } else {
        bannervalidate = banners[platform];
      }
      const databanner: any = bannervalidate.length > 0 ? bannervalidate.find(E => E.type === 'Favoritos') : null;
      if (databanner && databanner?.enable) {
        let slottarget;
        this.banneravailable = databanner?.enable;
        try {

          const googletag: any = window['googletag'] || {};
          if (window['googletag'] && googletag.apiReady) {
            googletag.cmd = googletag.cmd || [];
            // this.slot = googletag.pubads().getSlots();
            const divbanner = document.getElementById(this.iddiv);
            if (!this.slot && divbanner) {
              let that = this;
              googletag.cmd.push(() => {
                slottarget =  googletag.defineSlot(databanner.banner, [[databanner.width, databanner.height]], this.iddiv)
                  .addService(googletag.pubads());
                googletag.pubads().enableSingleRequest();
                googletag.pubads().collapseEmptyDivs(true, true);
                googletag.enableServices();
              });
              googletag.display(this.iddiv);

              // event is fired where code is injected into a slot
              googletag.pubads().addEventListener('slotRenderEnded',
                function(event) {
                  const slot = event.slot;
                  if (slot === slottarget) {
                    that.slot = slottarget;
                    that.isemptybanner = event.isEmpty;
                    if (event.isEmpty) {
                      that.marginbanner = 0;
                      that.banneravailable = false;
                    }
                  }
                }
              );
              this.widthbanner = databanner.width;
              this.heightbanner = databanner.height;
              this.marginbanner = 1;
            } else {
              if (this.slot) {
                if (!this.isemptybanner) {
                  this.marginbanner = 1;
                  this.banneravailable = true;
                } else {
                  this.marginbanner = 0;
                  this.banneravailable = false;
                }
              } else {
                this.marginbanner = 0;
                this.banneravailable = false;
              }
            }
          }
        } catch (e) {
          console.log(e);
          this.banneravailable = false;
          this.marginbanner = 0;
        }
      } else {
        this.banneravailable = false;
      }
    }
  }


// RemoveSlot

  Removeslot() {
    const googletag: any = window['googletag'] || {};
    if (window['googletag'] && googletag.apiReady) {
      googletag.cmd = googletag.cmd || [];
      if (this.slot) {
        googletag.destroySlots([this.slot]);
      }
    }
  }

}
