import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { IS_ADAPTABLE } from 'src/app/config/constants';
import { SearchResult } from 'src/app/core/interfaces/search-result';
import { Storage } from 'src/app/core/storage/storage';
import { SearchService } from 'src/app/services/search.service';
import { DevicesService } from 'src/app/services/devices.service';
import { AuthService } from 'src/app/services/auth.service';
import {TextManagementService} from '../../../services/text-management.service';
import { TextManagementV2Service } from 'src/app/services/text-management-v2.service';


@Component({
  selector: 'app-finder-web',
  templateUrl: './finder-web.component.html',
  styleUrls: ['./finder-web.component.scss'],
})
export class FinderWebComponent implements OnInit {

  @Input() textInFinder: string;
  showTextInFinder: string;

  data: SearchResult[] = [];
  isAdaptable = IS_ADAPTABLE;
  text: any = {};
  textsV2: any;
  assetDomain: any;
  constructor(
    private navController: NavController,
    private storage: Storage,
    private searchService: SearchService,
    private devicesService: DevicesService,
    private textmanagementservice: TextManagementService,
    private textManagementV2Service: TextManagementV2Service
  ) { }

  async ngOnInit() {
    this.textsV2 = await this.textManagementV2Service.getTexts();
    this.assetDomain = this.textsV2?.ASSET_DOMAIN;
    const { FINDER_WEB } = await this.textmanagementservice.getTexts();
    this.text = FINDER_WEB;
  }

  async ngOnChanges() {
    await this.getItems();
  }

  /* Get items based on user input */
  async getItems() {
    this.textsV2 = await this.textManagementV2Service.getTexts();
    this.assetDomain = this.textsV2?.ASSET_DOMAIN;
    const results : SearchResult[] = await this.searchService.getResults(this.assetDomain, this.textInFinder) ;
    this.showTextInFinder = this.textInFinder.replace(/<[^>]+>/g, '');
    this.data = results;
  }

  /* Redirect user to detail page */
  async viewDetail(content_id: String, content_type: String){
    let authenticated = this.storage.getAccessToken();
    let route = '';
    let eventType = '';
    if (content_type === "gameSchedules"){
      eventType = "game"
    } else {
      eventType = "highlights"
    }
    route = `/detail/${content_id}/${eventType}`;
    this.storage.setPreviousRoute(route);
    if (!authenticated){
      if (this.isAdaptable){
        route = '/-/iniciar-sesion';
      } else {
        route = '/-/iniciar-sesion-tv';
      }
    }
    this.navController.navigateRoot(route);
  }

}
