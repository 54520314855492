<div class="banner tv-row" [ngClass]="{'bannerAdaptable': isAdaptable}">
  <div *ngIf="!isAdaptable" class="tv__arrow arrowLeft tv-control component" tabindex="1" (click)="handleSlidePrev()" id="previousSlide">
    <ion-img src="assets/graphics/options/buttons/ico_arrow.svg"></ion-img>
  </div>
  <swiper *ngIf="banners.length > 0"
          (slideChange)="slidePromotion($event)"
          id="custom-slide"
          #swiper
          onfocus="this.blur()"
          [slidesPerView]="1"
          [loop]="isAdaptable"
          [speed]="500"
          [autoplay]="{ delay: 10000, disableOnInteraction: isAdaptable,pauseOnMouseEnter: !isAdaptable } "
          [pagination]="{ clickable: isAdaptable }"
  >
    <ng-template swiperSlide *ngFor="let banner of banners; index as i;" class="tv-control-none">
      <div class="banner__Info" [ngClass]="{'banner__InfoAdaptable': isAdaptable, 'banner__InfoTv': !isAdaptable}">
        <h2 class="banner__InfoTitle" [ngClass]="{'banner__InfoTitleAdaptable': isAdaptable}">{{banner.title}}</h2>
        <h6 class="banner__subtitle" [ngClass]="{'banner__subtitleAdaptable': isAdaptable}">{{banner.subtitle}}</h6>
        <ion-label class="banner__InfoDisabled d-none d-lg-flex">{{banner.textDisabled}}</ion-label>
        <ion-label class="banner__InfoDescription" [ngClass]="{'banner__InfoDescriptionAdaptable': isAdaptable}">{{banner.description}}</ion-label>
        <button type="button" class="banner__InfoButton" id="banner-{{i}}" [ngClass]="{'banner__InfoButtonAdaptable': isAdaptable, 'tv-control': !isAdaptable, 'component' : (i === this.swiperRef?.swiperRef.activeIndex)}" tabindex="2" (click)="viewDetail(banner)">
          <ion-img src="assets/graphics/options/buttons/ico_play.svg" alt=""></ion-img>
          VER</button>
      </div>
      <div class="banner__Img" [ngClass]="{'banner__ImgAdaptable': isAdaptable}">
        <img [src]="banner?.imageUrl">
      </div>
    </ng-template>
  </swiper>
  <div *ngIf="!isAdaptable" class="tv__arrow arrowRight tv-control component" tabindex="3" (click)="handleSlideNext()" id="nextSlide">
    <ion-img src="assets/graphics/options/buttons/ico_arrow.svg"></ion-img>
  </div>
</div>
<!--<app-league-icons *ngIf="isAdaptable"></app-league-icons>--->
