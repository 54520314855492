<div *ngIf="showData" class="followdItemCardsHorizontal">
  <ion-grid>
    <ion-row>
      <div *ngIf="showArrows" class="carrousel__arrow arrowLeft" tabindex="0" (click)="handleScrollSegment(-250)">
        <ion-img src="assets/graphics/options/buttons/ico_arrow.svg"></ion-img>
      </div>
      <ion-col>
        <ion-segment id="followedItems__segment" class="tv-row tv-control-none" scrollable (scroll)="doSomethingOnScroll($event)">
          <div class="followdItemCardsHorizontal__Item" *ngFor="let item of data" (click)="filter(item)" tabindex="5">
            <div class="gridCell">
              <button class="buttonsFollow " >
                <div class="imgSelectedFollow" [ngClass]="{'selected__item' : item?.selected}">
                  <ion-img class="selectedFollowLogo" [src]="'https://' + item?.logo" ></ion-img>
                </div>
              </button>
              <footer class="label" [ngClass]="{'selected__label' : item?.selected}">{{item?.name}}</footer>
            </div>
          </div>
        </ion-segment>
      </ion-col>
      <div *ngIf="showArrows" class="carrousel__arrow arrowRight" tabindex="2" (click)="handleScrollSegment(250)">
        <ion-img src="assets/graphics/options/buttons/ico_arrow.svg"></ion-img>
      </div>
    </ion-row>
  </ion-grid>
</div>
