
<div class="card-cont">
<div class="container">
  <form class="custom-card-content"  [formGroup]="form">
    <div class="col-12">
      <div class="row light-color-text mb-2">
       <p *ngIf="messagecard.avialablecards"> {{messagecard.avialablecards}}:
        <ng-container *ngFor="let card of creditCards">

            <img  class="img-position" [src]="card.icon" width="30px" height="19px">

          </ng-container>
        </p>

    </div>
<div class="row">
  <mat-form-field appearance="outline" class="form-field">
      <mat-label style="font-size: 13px">Número de la Tarjeta </mat-label>
      <input maxlength="19" matInput [cleave]="{ creditCard: true }" placeholder="0000 0000 0000 0000"
          formControlName="accountNumber" type="tel" >
      <img matSuffix  [src]="cardIcon" width="35px" height="24px">
  </mat-form-field>
</div>

<div class="row row-cols-2 mb-3">
  <div class="col">
      <mat-form-field appearance="outline" class="form-field fit-field">
          <mat-label style="font-size: 13px">Vencimiento </mat-label>
          <input matInput [cleave]="{ date: true, datePattern: ['m', 'y'] }" placeholder="MM/AA"
          formControlName="cardExpiration" type="tel">
          <img matSuffix src="../../../../assets/graphics/payment/cc-date.svg" width="35px" height="24px">
          <mat-hint class="hint-color">Búscala en el reverso</mat-hint>
      </mat-form-field>
  </div>
  <div class="col" >
      <mat-form-field appearance="outline" class="form-field fit-field">
          <mat-label>CVV/CVC *</mat-label>
          <input matInput  [cleave]="{ numericOnly: true, blocks: [block] }" placeholder="000"
          formControlName="cvv" type="tel">
          <img matSuffix src="../../../../assets/graphics/payment/cc-back.svg" width="35px" height="24px">
          <mat-hint class="hint-color">Búscala al reverso</mat-hint>
      </mat-form-field>
  </div>
</div>
<div class="row">

  <mat-form-field appearance="outline" class="form-field">
      <mat-label style="font-size: 13px">Nombre del titular </mat-label>
      <input matInput placeholder="Como aparece en la tarjeta" formControlName="customerName">
  </mat-form-field>


</div>
<ng-container *ngIf="isTigoMobile">
<div class="row">
  <mat-form-field appearance="outline" class="form-field">
      <mat-label>Correo Electrónico *</mat-label>
      <input matInput placeholder="Cuenta@email.com"
      formControlName="registrationEmail"
          >
  </mat-form-field>
</div>
</ng-container>
<div class="content-position" *ngIf="turnofsucription.pvv === 'false'">
<div class="row light-color-text">
  <div class="col-2">
    <div class="porfile-container"><img matSuffix src="../../../../assets/graphics/pictures/Candado.png"></div>
  </div>
  <div class="col-10">
    <div class="text-position">
    <label class="style-text">  Los datos de tu tarjeta se almacenarán de manera segura
      </label>
    </div>
    </div>

</div>
</div>
<div class="row">
  <ng-container *ngIf="first_selected">
      <mat-form-field appearance="outline" class="form-field">
          <mat-label>Pais</mat-label>
          <mat-select [(value)]="first_selected" (selectionChange)="selectChange($event.value)" >
              <mat-option *ngFor="let format of format_billing" [value]="format.country" [id]="format.code">
                  {{format.country}}
              </mat-option>
          </mat-select>
      </mat-form-field>
  </ng-container>
</div>

    <div *ngIf="turnofsucription.pvv === 'true' && idoffer && infocard?.enablesavedcard">
      <div class="row">
        <mat-checkbox [id]="'custom0'" (change)="selectedOption(0, options[0])" type="checkbox">{{infocard?.messagesavedcard}}</mat-checkbox>
      </div>
    </div>

      <div class="row">
        <mat-checkbox [id]="'custom1'"  (change)="selectedOption(1, options[1])">{{infocard?.messageinvoice}}</mat-checkbox>
      </div>

    <ng-container class="p-2 row">
      <div *ngFor="let control of controls">
        <ng-container *ngIf="!control.isForBillling && options[1]">
          <app-billing-form-control [control]="control" [form]="form"></app-billing-form-control>
        </ng-container>
      </div>
    </ng-container>

      <div class="col-12" *ngIf="infocard?.onTermsConditions">
        <div class="text-position-terms">
          <label class="style-terms-conditions" (click)="openTermsConditions()">{{infocard?.titleTermsConditions}}</label>
        </div>
      </div>


<div class="container-position">
<div class="row">
  <div class="col-12 d-flex justify-content-end">
  <div class="col-md-3 d-flex justify-content-end">
    <a (click)="close()" class="style_btn"><label lass="style_btn">Cancelar</label></a>
  </div>
  <div class="col-md-3 d-flex justify-content-end">
  <button class="continue-button" [disabled]="!form.valid" [class.continue-button-active]="form.valid" [class.continue-button-disable]="!form.valid" (click)="subscribe()">Continuar</button>
  </div>
</div>
  </div>
</div>
</div>
</form>
</div></div>

