
  <div class="results">
    <!-- <div class='dfMc-Module' data-event='{"channel": "deportes.futbol.copalpf.578376.mam"}' data-module='{"name": "itemMaM"}' data-settings='{}'></div> -->
    <iframe #appIframeResizer
            class="thread-content"
            [src]="urlResults"
            title="Thread Content"
            scrolling="no" frameborder="0"
            [id]="id_match"
    ></iframe>
  </div>

