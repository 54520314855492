<div *ngIf="showUpcoming" class="eventCardsHorizontal">
  <h3>{{ title }}</h3>
  <ion-grid>
    <ion-row>
      <div *ngIf="showArrows" class="carrousel__arrow arrowLeft" tabindex="0" (click)="handleScrollSegment(-250)">
        <ion-img src="assets/graphics/options/buttons/ico_arrow.svg"></ion-img>
      </div>
      <ion-col>
        <ion-segment id="upcoming__segment" class="tv-row tv-control-none" [ngClass]="{'segment-tv-horizontal': !isAdaptable}" scrollable (scroll)="doSomethingOnScroll($event)">
          <div class="eventCardsHorizontal__Item" [ngClass]="{'tv-control': !isAdaptable}" *ngFor="let item of events" value="item.title" (click)="viewDetail(item)" [id]="'upcoming-events-' + item.contentful_id" tabindex="3">
            <ion-img [src]="item.image ? item.image : loadingImg" alt={{item.contentful_id}}></ion-img>
            <img class="icoPlayInternal" src="assets/graphics/options/buttons/ico_play_internal.svg" alt="">
            <div class="eventCardsHorizontal__Info">
              <ion-label class="infoTitle">{{item.title}}</ion-label>
              <ion-label class="infoDisabled">{{item.schedule}}</ion-label>
            </div>
          </div>
        </ion-segment>
      </ion-col>
      <div *ngIf="showArrows" class="carrousel__arrow arrowRight" tabindex="2" (click)="handleScrollSegment(250)">
        <ion-img src="assets/graphics/options/buttons/ico_arrow.svg"></ion-img>
      </div>
    </ion-row>
  </ion-grid>
</div>
